<template>
  <GameSlider
    sliderClass="live-games"
    collection="featurelivegames"
    providerName="Live Games"
  />
</template>

<script>
import GameSlider from '@/components/GameSlider.vue'

export default {
  name: 'LiveGamesSlider',
  components: {
    GameSlider
  }
}
</script>

<style scoped>
@import '../../styles/gameCard.css';
</style>