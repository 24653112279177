<template>
  <div class="login-credentials">
    <div class="credentials-element">
      <div class="element-title">EMAIL</div>
      <div class="element-input">
        <input v-model="loginEmail" type="text" placeholder="ENTER EMAIL"/>
      </div>
    </div>
    <div class="credentials-element">
      <div class="element-title">PASSWORD</div>
      <div class="element-input">
        <input v-model="loginPassword" type="password" placeholder="ENTER PASSWORD"/>
        <button v-if="tab === 'login'" v-on:click="$parent.modalSetTab('forgot')" class="button-forgot">
          <div class="button-inner">
            <span>FORGOT PASSWORD</span>
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
              <!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
              <path
                  d="M224 64c44.2 0 80 35.8 80 80v48H144V144c0-44.2 35.8-80 80-80zM80 144v48H64c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V256c0-35.3-28.7-64-64-64H368V144C368 64.5 303.5 0 224 0S80 64.5 80 144zM256 320v64c0 17.7-14.3 32-32 32s-32-14.3-32-32V320c0-17.7 14.3-32 32-32s32 14.3 32 32z"/>
            </svg>
          </div>
        </button>
      </div>
    </div>
    <div v-if="tab === 'register'" class="credentials-element">
      <div class="element-title">PASSWORD CONFIRM</div>
      <div class="element-input">
        <input v-model="loginPasswordConfirm" type="password" placeholder="ENTER PASSWORD AGAIN"/>
      </div>
    </div>
    <div class="credentials-info">
      <span>By accessing Candy Casino, I attest that I am at least 18 years old and have read and agree with the
        {{ " " }}
      </span>
      <button v-on:click="loginTermsButton()" class="button-terms">Terms of Service</button>
      <span>.</span>
    </div>
    <div class="credentials-buttons">
      <button v-on:click="$parent.modalSetTab(tab === 'login' ? 'register' : 'login')" class="button-toggle"
              v-bind:class="{ 'button-active': tab === 'register' }">
        <div class="button-inner">REGISTER</div>
      </button>
      <button v-on:click="loginActionButton()" class="button-action" v-bind:disabled="authSendLoginLoading === true">
        <div class="button-inner">{{ tab.toUpperCase() }}</div>
      </button>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default {
  name: 'LoginCredentials',
  props: ['tab'],
  data() {
    return {
      loginEmail: null,
      loginPassword: null,
      loginPasswordConfirm: null
    };
  },
  methods: {
    ...mapActions([
      'modalsSetShow',
      'modalsSetData',
      'notificationShow',
      'authSendCredentialsLogin',
      'authSendCredentialsRegister'
    ]),
    loginActionButton() {
      if (this.loginEmail === null || this.loginEmail.trim() === '') {
        this.notificationShow({type: 'error', message: 'Your entered email is invalid.'});
        return;
      }

      if (this.loginPassword === null || this.loginPassword.trim() === '') {
        this.notificationShow({type: 'error', message: 'Your entered password is invalid.'});
        return;
      }

      if (this.tab === 'register') {
        if (this.loginPassword !== this.loginPasswordConfirm) {
          this.notificationShow({type: 'error', message: 'Your entered passwords does not match.'});
          return;
        }

        this.modalsSetData({
          typeCaptcha: 'credentialsRegister',
          data: {email: this.loginEmail, password: this.loginPassword}
        });
      } else {
        this.modalsSetData({
          typeCaptcha: 'credentialsLogin',
          data: {email: this.loginEmail, password: this.loginPassword}
        });
      }

      setTimeout(() => {
        this.modalsSetShow('Captcha');
      }, 200);
    },
    loginTermsButton() {
      this.modalsSetShow(null);
      setTimeout(() => {
        this.modalsSetShow('Terms');
      }, 300);
    }
  },
  computed: {
    ...mapGetters([
      'authSendLoginLoading'
    ])
  }
};
</script>

<style scoped>
.login-credentials {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-credentials .credentials-element {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
}

.login-credentials .credentials-element:first-child {
  margin-top: 0;
}

.login-credentials .element-title {
  font-size: 14px;
  font-weight: 800;
  color: #bbbfd0;
}

.login-credentials .element-input {
  width: 100%;
  height: 78px;
  position: relative;
  margin-top: 20px;
  padding: 1px;
}

.login-credentials .element-input input {
  width: 100%;
  height: 100%;
  padding: 0 177px 0 25px;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  background: var(--background-primary);
  border-radius: 0.5rem;
}

.login-credentials .element-input input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.login-credentials button.button-forgot {
  height: 46px;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translate(0, -50%);
  transition: all .2s;
}

.login-credentials button.button-forgot:hover {
  transition-duration: .2s;
  opacity: .8;
}

.login-credentials button.button-forgot:active {
  transition-duration: .2s;
  scale: .9;
}

.login-credentials button.button-forgot .button-inner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 15px;
  background-color: var(--background-secondary);
  border-radius: 0.5rem;
}

.login-credentials button.button-forgot .button-inner span {
  font-size: 13px;
  font-weight: 800;
  color: rgba(255, 255, 255, 0.5);
}

.login-credentials button.button-forgot .button-inner svg {
  width: 17px;
  height: 17px;
  display: none;
  fill: rgba(255, 255, 255, 0.5);
}

.login-credentials .credentials-info {
  width: 100%;
  margin-top: 35px;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
  font-family: Montserrat, sans-serif;
  font-weight: 600;
}

.login-credentials .credentials-info button.button-terms {
  font-size: 14px;
  color: var(--highlight-pink);
  font-family: Montserrat, sans-serif;
  font-weight: 700;
}

.login-credentials .credentials-buttons {
  display: flex;
  align-items: center;
  margin-top: 35px;
}

.login-credentials button.button-toggle {
  width: 200px;
  height: 54px;
  position: relative;
  margin-right: 20px;
  padding: 1px;
  background-color: var(--background-primary);
  border-radius: 0.5rem;
  transition: filter 0.3s ease;
  z-index: 1;
  transition: all .2s;
}

.button-toggle:hover {
  transition-duration: .1s;
  transform: translateY(-1px);
  opacity: 0.8;
}

.button-toggle:active {
  transition-duration: .2s;
  transform: translateY(2px);
}

.login-credentials button.button-toggle.button-active {
  background-color: var(--highlight-pink);
}

.login-credentials button.button-action {
  width: 180px;
  height: 54px;
  position: relative;
  transition: all .2s;
}

.button-action:hover {
  transition-duration: .1s;
  transform: translateY(-1px);
  opacity: 0.8;
}

.button-action:active {
  transition-duration: .2s;
  transform: translateY(2px);
}

.login-credentials button.button-toggle .button-inner,
.login-credentials button.button-action .button-inner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  border-radius: 0.5rem;
  font-weight: 800;
}

.login-credentials button.button-toggle .button-inner {
  color: white;
}

.login-credentials button.button-toggle.button-active .button-inner {
}

.login-credentials button.button-action .button-inner {
  color: #ffffff;
  background-color: var(--highlight-pink);
}

@media only screen and (max-width: 430px) {

  .login-credentials .element-input input {
    padding: 0 62px 0 25px;
  }


  .login-credentials button.button-forgot .button-inner span {
    display: none;
  }

  .login-credentials button.button-forgot .button-inner svg {
    display: block;
  }

  .login-credentials .credentials-buttons {
    width: 100%;
    flex-direction: column;
  }

  .login-credentials button.button-toggle,
  .login-credentials button.button-action {
    width: 100%;
    transition: all;
    transition-duration: 0.2s;
  }

  .button-action:hover {
    opacity: 0.8;
  }

  .button-action:focus {
    scale: 0.95;
  }

  .login-credentials button.button-toggle {
    margin-bottom: 10px;
    margin-right: 0;
  }

}
</style>