<template>
  <button v-on:click="modalsSetShow('Login')" class="auth-button">
    <div class="button-inner">
      Join Now!
    </div>
  </button>
</template>

<script>
import {mapActions} from 'vuex';
import IconRoblox from '@/components/icons/IconRoblox';

export default {
  name: 'AuthButton',
  components: {
    IconRoblox
  },
  methods: {
    ...mapActions(['modalsSetShow'])
  }
};
</script>

<style scoped>
button.auth-button {
  height: 50px;
  transition: ease-in-out 0.15s;
}

.auth-button:hover {
  transform: translateY(-1px);
}

.auth-button:hover .button-inner {
  color: var(--highlight-pink);
  background-color: white;
}

.auth-button:active {
  transition-duration: .2s;
  transform: translateY(2px);
}

button.auth-button .button-inner {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.9rem;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 0.5rem;
  color: white;
  background-color: var(--highlight-pink);
  transition: ease-in-out 0.15s;
}

button.auth-button .button-inner svg {
  margin-right: 11px;
  fill: #ffffff;
}
</style>
