<template>
  <div class="game-container">
    <div class="game-frame">
      <div class="game-header">
        <div class="game-title">{{ gameName }}</div>
        <button @click="closeGame" class="close-button">Close</button>
      </div>
      <div class="game-iframe-container">
        <iframe v-if="gameUrl" :src="gameUrl" frameborder="0" allowfullscreen></iframe>
      </div>
    </div>
    <!-- Add random games section here if needed -->
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'GamePlay',
  data() {
    return {
      gameUrl: null,
      gameName: this.$route.query.name || '',
      gameProvider: this.$route.query.provider || '',
      gameImage: this.$route.query.image || ''
    }
  },
  computed: {
    ...mapState({
      authUser: state => state.auth.authUser,
      currentUser: state => state.auth.authUser.user,
    }),
    isAuthenticated() {
      return !!this.currentUser;
    },
  },
  mounted() {
    this.initializeGame()
  },
  methods: {
    ...mapActions('slotegrator', ['initGame', 'initDemoGame']),
    async initializeGame() {
      const gameUuid = this.$route.params.uuid;
      try {
        let initData;
        if (this.isAuthenticated) {
          const user = this.currentUser;
          const sessionId = Date.now().toString() + user._id;
          const gameData = {
            game_uuid: gameUuid,
            player_id: user._id,
            player_name: user.username,
            currency: user.currency || 'EUR',
            session_id: sessionId,
            return_url: `${window.location.origin}/games`,
            language: 'en'
          };
          initData = await this.initGame(gameData);
        } else {
          const demoData = {
            game_uuid: gameUuid,
            return_url: `${window.location.origin}/games`,
            language: 'en'
          };
          initData = await this.initDemoGame(demoData);
        }
        
        if (initData && initData.url) {
          this.gameUrl = initData.url;
        } else {
          throw new Error('Game URL not received from the server');
        }
      } catch (error) {
        console.error('Error initializing game:', error);
        this.$store.dispatch('notificationShow', error.message || 'Failed to start the game. Please try again.');
      }
    },
    closeGame() {
      this.$router.push('/games');
    }
  }
}
</script>

<style scoped>
/* Add your styles here */
</style>