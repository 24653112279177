var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-fair"},[_vm._m(0),_c('div',{staticClass:"fair-info"},[_vm._v(" The game results have been generated before you even place your bet, and most importantly, we can prove it. Before each game, we actually give you the game result in a hashed format. Before you start the game, we give you the hash of the server seed and the public seed which the round result is based on. After the game is finished, we publish the server seed, which you can then compare to the hash to make sure that they match. With this system we can prove that the results were fair and pre-generated. ")]),_c('div',{staticClass:"fair-nav"},[_c('button',{class:{ 'button-active': _vm.modalGame === 'Blackjack' },on:{"click":function($event){return _vm.modalSetGame('Blackjack')}}},[_vm._m(1)]),_c('button',{class:{ 'button-active': _vm.modalGame === 'Roll' },on:{"click":function($event){return _vm.modalSetGame('Roll')}}},[_vm._m(2)]),_c('button',{class:{ 'button-active': _vm.modalGame === 'Crash' },on:{"click":function($event){return _vm.modalSetGame('Crash')}}},[_vm._m(3)]),_c('button',{class:{ 'button-active': _vm.modalGame === 'Duels' },on:{"click":function($event){return _vm.modalSetGame('Duels')}}},[_vm._m(4)]),_c('button',{class:{ 'button-active': _vm.modalGame === 'Mines' },on:{"click":function($event){return _vm.modalSetGame('Mines')}}},[_vm._m(5)]),_c('button',{class:{ 'button-active': _vm.modalGame === 'Towers' },on:{"click":function($event){return _vm.modalSetGame('Towers')}}},[_vm._m(6)]),_c('button',{class:{ 'button-active': _vm.modalGame === 'Battles' },on:{"click":function($event){return _vm.modalSetGame('Battles')}}},[_vm._m(7)]),_c('button',{class:{ 'button-active': _vm.modalGame === 'Unbox' },on:{"click":function($event){return _vm.modalSetGame('Unbox')}}},[_vm._m(8)])]),_c('div',{staticClass:"fair-content"},[_c('Fair' + _vm.modalGame,{tag:"component"})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fair-header"},[_c('div',{staticClass:"header-icon"},[_c('img',{attrs:{"src":require("@/assets/img/fair.png"),"alt":"fair"}})]),_c('span',{staticClass:"gradient-green"},[_vm._v("PROVABLY FAIR")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button-inner"},[_c('span',[_vm._v("BLACKJACK")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button-inner"},[_c('span',[_vm._v("ROLL")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button-inner"},[_c('span',[_vm._v("CRASH")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button-inner"},[_c('span',[_vm._v("DICE DUELS")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button-inner"},[_c('span',[_vm._v("MINES")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button-inner"},[_c('span',[_vm._v("TOWERS")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button-inner"},[_c('span',[_vm._v("BATTLES")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button-inner"},[_c('span',[_vm._v("UNBOX")])])
}]

export { render, staticRenderFns }