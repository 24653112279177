<template>
  <button class="cashier-element" v-bind:class="[ 'element-' + method ]"> <!--v-bind:disabled="enabled !== true"-->
    <div class="element-inner">
      <div class="inner-background">
        <img src="@/assets/img/knight.png"/>
      </div>
      <div class="inner-image">
        <img v-bind:src="require('@/assets/img/cashier/' + method + '.png')"/>
      </div>
      <!--<div class="inner-text">
          <div class="text-method">{{ modalGetMethod }}</div>
          <div class="text-type">{{ modalGetType }}</div>
      </div>-->
    </div>
  </button>
</template>

<script>
export default {
  name: 'CashierElement',
  props: ['type', 'method', 'enabled'],
  computed: {
    isDarkened() {
      return this.selectedCurrency && this.selectedCurrency !== this.method;
    },
    modalGetMethod() {
      let method = this.method.charAt(0).toUpperCase() + this.method.substring(1);

      if (this.method === 'credit') {
        method = 'Credit Card';
      } else if (this.method === 'csgo') {
        method = 'CS:GO';
      } else if (this.method === 'dota') {
        method = 'Dota 2';
      } else if (this.method === 'gift') {
        method = 'Gift Cards';
      } else if (this.method === 'btc') {
        method = 'Bitcoin';
      } else if (this.method === 'eth') {
        method = 'Ethereum';
      } else if (this.method === 'ltc') {
        method = 'Litecoin';
      } else if (['usdterc20', 'usdc', 'g2a'].includes(this.method) === true) {
        method = this.method.toUpperCase();
      }

      return method;
    },
    modalGetType() {
      let type = this.type.charAt(0).toUpperCase() + this.type.substring(1);

      if (this.type === 'gift') {
        type = 'Gift Cards';
      }

      return type;
    }
  }
};
</script>

<style scoped>
.darkened {
  opacity: 0.5;
}

.cashier-element {
  aspect-ratio: 1/1;
  padding: 1px;
  border-radius: 16px;
  transition: all .2s;
}

.cashier-element:hover {
  transition-duration: .2s;
  opacity: .8;
  transform: translateY(-2px);
}

.cashier-element:active {
  transition-duration: .2s;
  transform: translateY(2px);
}

.cashier-element:disabled {
  mix-blend-mode: luminosity;
  opacity: 0.35;
}

.cashier-element:nth-child(5) {
  margin-right: 0;
}

.cashier-element.element-robux {
  background: linear-gradient(90deg, #1ede53 0%, rgba(30, 222, 83, 0.3) 100%);
}

.cashier-element.element-limiteds {
  background: linear-gradient(90deg, #5742d4 0%, rgba(87, 66, 212, 0.3) 100%);
}

.cashier-element.element-csgo {
  background: linear-gradient(90deg, #e19736 0%, rgba(225, 151, 54, 0.3) 100%);
}

.cashier-element.element-dota {
  background: linear-gradient(90deg, #8e1212 0%, rgba(142, 18, 18, 0.3) 100%);
}

.cashier-element.element-credit {
  background: linear-gradient(90deg, #148eff 0%, rgba(20, 142, 255, 0.3) 100%);
}

.cashier-element.element-btc {
  background: linear-gradient(90deg, #f7931a 0%, rgba(247, 147, 26, 0.3) 100%);
}

.cashier-element.element-eth {
  background: linear-gradient(90deg, #627eea 0%, rgba(98, 126, 234, 0.3) 100%);
}

.cashier-element.element-ltc {
  background: linear-gradient(90deg, #345d9d 0%, rgba(52, 93, 157, 0.3) 100%);
}

.cashier-element.element-usdterc20 {
  background: linear-gradient(90deg, #27a17c 0%, rgba(39, 161, 124, 0.3) 100%);
}

.cashier-element.element-usdc {
  background: linear-gradient(90deg, #2775ca 0%, rgba(39, 117, 202, 0.3) 100%);
}

.cashier-element.element-sol {
  background: linear-gradient(90deg, #c18fff 0%, rgb(95, 42, 165) 100%);
}

.cashier-element.element-trx {
  background: linear-gradient(90deg, #ff5358 0%, rgb(151, 38, 40) 100%);
}

.cashier-element.element-shib {
  background: linear-gradient(90deg, #ff7353 0%, rgb(151, 59, 38) 100%);
}

.cashier-element.element-xrp {
  background: linear-gradient(90deg, #737373 0%, rgb(69, 69, 69) 100%);
}

.cashier-element.element-doge {
  background: linear-gradient(90deg, #ffce53 0%, rgb(151, 98, 38) 100%);
}

.cashier-element.element-bch {
  background: linear-gradient(90deg, #8dc351 0%, rgba(141, 195, 81, 0.3) 100%);
}

.cashier-element.element-xmr {
  background: linear-gradient(90deg, #ff6600 0%, rgba(255, 102, 0, 0.3) 100%);
}

.cashier-element.element-dash {
  background: linear-gradient(90deg, #008de4 0%, rgba(0, 141, 228, 0.3) 100%);
}

.cashier-element.element-vet {
  background: linear-gradient(90deg, #5571ff 0%, rgba(87, 66, 212, 0.3) 100%);
}

.cashier-element.element-uni {
  background: linear-gradient(90deg, #ff6ab2 0%, rgba(255, 0, 122, 0.3) 100%);
}

.cashier-element.element-ada {
  background: linear-gradient(90deg, #008de4 0%, rgba(0, 141, 228, 0.3) 100%);
}

.cashier-element.element-dgb {
  background: linear-gradient(90deg, #004080 0%, rgba(0, 64, 128, 0.3) 100%);
}

.cashier-element .element-inner {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 8px;
  border-radius: 15px;
  overflow: hidden;
  z-index: 1;
}

.cashier-element .element-inner:before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.cashier-element.element-robux .element-inner {
  background: radial-gradient(650% 160% at 100% 25%, rgba(37, 20, 0, 0.27) 0%, rgba(0, 0, 0, 0) 100%),
  linear-gradient(0deg, rgba(30, 222, 83, 0.15) 0%, rgba(30, 222, 83, 0.15) 100%),
  linear-gradient(90deg, rgba(30, 222, 83, 0.62) -30%, rgba(0, 0, 0, 0) 180%),
  linear-gradient(265deg, #001626 0%, rgba(12, 52, 82, 0) 150%),
  repeating-linear-gradient(-55deg, transparent, transparent 5px, rgba(2, 21, 36, 0.12) 5px, rgba(2, 21, 36, 0.12) 10px);
}

.cashier-element.element-limiteds .element-inner {
  background: radial-gradient(650% 160% at 100% 25%, rgba(37, 20, 0, 0.27) 0%, rgba(0, 0, 0, 0) 100%),
  linear-gradient(0deg, rgba(62, 100, 200, 0.15) 0%, rgba(62, 100, 200, 0.15) 100%),
  linear-gradient(90deg, rgba(76, 62, 156, 0.62) -30%, rgba(0, 0, 0, 0) 180%),
  linear-gradient(265deg, #001626 0%, rgba(12, 52, 82, 0) 150%),
  repeating-linear-gradient(-55deg, transparent, transparent 5px, rgba(2, 21, 36, 0.12) 5px, rgba(2, 21, 36, 0.12) 10px);
}

.cashier-element.element-csgo .element-inner {
  background: radial-gradient(650% 160% at 100% 25%, rgba(37, 20, 0, 0.27) 0%, rgba(0, 0, 0, 0) 100%),
  linear-gradient(0deg, rgba(225, 151, 54, 0.15) 0%, rgba(225, 151, 54, 0.15) 100%),
  linear-gradient(90deg, rgba(225, 151, 54, 0.62) -30%, rgba(0, 0, 0, 0) 180%),
  linear-gradient(265deg, #001626 0%, rgba(12, 52, 82, 0) 150%),
  repeating-linear-gradient(-55deg, transparent, transparent 5px, rgba(2, 21, 36, 0.12) 5px, rgba(2, 21, 36, 0.12) 10px);
}

.cashier-element.element-dota .element-inner {
  background: radial-gradient(650% 160% at 100% 25%, rgba(37, 20, 0, 0.27) 0%, rgba(0, 0, 0, 0) 100%),
  linear-gradient(0deg, rgba(142, 18, 18, 0.15) 0%, rgba(142, 18, 18, 0.15) 100%),
  linear-gradient(90deg, rgba(142, 18, 18, 0.62) -30%, rgba(0, 0, 0, 0) 180%),
  linear-gradient(265deg, #001626 0%, rgba(12, 52, 82, 0) 150%),
  repeating-linear-gradient(-55deg, transparent, transparent 5px, rgba(2, 21, 36, 0.12) 5px, rgba(2, 21, 36, 0.12) 10px);
}

.cashier-element.element-credit .element-inner {
  background: radial-gradient(650% 160% at 100% 25%, rgba(37, 20, 0, 0.27) 0%, rgba(0, 0, 0, 0) 100%),
  linear-gradient(0deg, rgba(20, 142, 255, 0.15) 0%, rgba(20, 142, 255, 0.15) 100%),
  linear-gradient(90deg, rgba(20, 142, 255, 0.62) -30%, rgba(0, 0, 0, 0) 180%),
  linear-gradient(265deg, #001626 0%, rgba(12, 52, 82, 0) 150%),
  repeating-linear-gradient(-55deg, transparent, transparent 5px, rgba(2, 21, 36, 0.12) 5px, rgba(2, 21, 36, 0.12) 10px);
}

.cashier-element.element-btc .element-inner {
  background: radial-gradient(650% 160% at 100% 25%, rgba(37, 20, 0, 0.27) 0%, rgba(0, 0, 0, 0) 100%),
  linear-gradient(0deg, rgba(247, 147, 26, 0.15) 0%, rgba(247, 147, 26, 0.15) 100%),
  linear-gradient(90deg, rgba(247, 147, 26, 0.62) -30%, rgba(0, 0, 0, 0) 180%),
  linear-gradient(265deg, #001626 0%, rgba(12, 52, 82, 0) 150%),
  repeating-linear-gradient(-55deg, transparent, transparent 5px, rgba(2, 21, 36, 0.12) 5px, rgba(2, 21, 36, 0.12) 10px);
}

.cashier-element.element-eth .element-inner {
  background: radial-gradient(650% 160% at 100% 25%, rgba(37, 20, 0, 0.27) 0%, rgba(0, 0, 0, 0) 100%),
  linear-gradient(0deg, rgba(98, 126, 234, 0.15) 0%, rgba(98, 126, 234, 0.15) 100%),
  linear-gradient(90deg, rgba(98, 126, 234, 0.62) -30%, rgba(0, 0, 0, 0) 180%),
  linear-gradient(265deg, #001626 0%, rgba(12, 52, 82, 0) 150%),
  repeating-linear-gradient(-55deg, transparent, transparent 5px, rgba(2, 21, 36, 0.12) 5px, rgba(2, 21, 36, 0.12) 10px);
}

.cashier-element.element-ltc .element-inner {
  background: radial-gradient(650% 160% at 100% 25%, rgba(37, 20, 0, 0.27) 0%, rgba(0, 0, 0, 0) 100%),
  linear-gradient(0deg, rgba(52, 93, 157, 0.15) 0%, rgba(52, 93, 157, 0.15) 100%),
  linear-gradient(90deg, rgba(52, 93, 157, 0.62) -30%, rgba(0, 0, 0, 0) 180%),
  linear-gradient(265deg, #001626 0%, rgba(12, 52, 82, 0) 150%),
  repeating-linear-gradient(-55deg, transparent, transparent 5px, rgba(2, 21, 36, 0.12) 5px, rgba(2, 21, 36, 0.12) 10px);
}

.cashier-element.element-usdterc20 .element-inner {
  background: radial-gradient(650% 160% at 100% 25%, rgba(37, 20, 0, 0.27) 0%, rgba(0, 0, 0, 0) 100%),
  linear-gradient(0deg, rgba(39, 161, 124, 0.15) 0%, rgba(39, 161, 124, 0.15) 100%),
  linear-gradient(90deg, rgba(39, 161, 124, 0.62) -30%, rgba(0, 0, 0, 0) 180%),
  linear-gradient(265deg, #001626 0%, rgba(12, 52, 82, 0) 150%),
  repeating-linear-gradient(-55deg, transparent, transparent 5px, rgba(2, 21, 36, 0.12) 5px, rgba(2, 21, 36, 0.12) 10px);
}

.cashier-element.element-usdc .element-inner {
  background: radial-gradient(650% 160% at 100% 25%, rgba(37, 20, 0, 0.27) 0%, rgba(0, 0, 0, 0) 100%),
  linear-gradient(0deg, rgba(39, 117, 202, 0.15) 0%, rgba(39, 117, 202, 0.15) 100%),
  linear-gradient(90deg, rgba(39, 117, 202, 0.62) -30%, rgba(0, 0, 0, 0) 180%),
  linear-gradient(265deg, #001626 0%, rgba(12, 52, 82, 0) 150%),
  repeating-linear-gradient(-55deg, transparent, transparent 5px, rgba(2, 21, 36, 0.12) 5px, rgba(2, 21, 36, 0.12) 10px);
}

.cashier-element.element-sol .element-inner {
  background: radial-gradient(650% 160% at 100% 25%, rgba(15, 0, 37, 0.27) 0%, rgba(0, 0, 0, 0) 100%),
  linear-gradient(0deg, rgba(118, 39, 202, 0.15) 0%, rgba(104, 39, 202, 0.15) 100%),
  linear-gradient(90deg, rgb(153, 69, 255) -30%, rgba(0, 0, 0, 0) 180%),
  linear-gradient(265deg, #14061e 0%, rgba(46, 12, 82, 0) 150%),
  repeating-linear-gradient(-55deg, transparent, transparent 5px, rgba(22, 2, 36, 0.12) 5px, rgba(24, 2, 36, 0.12) 10px);
}

.cashier-element.element-trx .element-inner {
  background: radial-gradient(650% 160% at 100% 25%, rgba(37, 0, 0, 0.27) 0%, rgba(0, 0, 0, 0) 100%),
  linear-gradient(0deg, rgba(202, 39, 39, 0.15) 0%, rgba(202, 39, 39, 0.15) 100%),
  linear-gradient(90deg, rgb(255, 6, 10) -30%, rgba(0, 0, 0, 0) 180%),
  linear-gradient(265deg, #190000 0%, rgba(82, 12, 12, 0) 150%),
  repeating-linear-gradient(-55deg, transparent, transparent 5px, rgba(36, 2, 2, 0.12) 5px, rgba(36, 2, 2, 0.12) 10px);
}

.cashier-element.element-shib .element-inner {
  background: radial-gradient(650% 160% at 100% 25%, rgba(37, 0, 0, 0.27) 0%, rgba(0, 0, 0, 0) 100%),
  linear-gradient(0deg, rgba(202, 101, 39, 0.15) 0%, rgba(202, 77, 39, 0.15) 100%),
  linear-gradient(90deg, rgb(255, 81, 6) -30%, rgba(0, 0, 0, 0) 180%),
  linear-gradient(265deg, #190800 0%, rgba(82, 12, 12, 0) 150%),
  repeating-linear-gradient(-55deg, transparent, transparent 5px, rgba(36, 2, 2, 0.12) 5px, rgba(36, 2, 2, 0.12) 10px);
}

.cashier-element.element-xrp .element-inner {
  background: radial-gradient(650% 160% at 100% 25%, rgba(37, 37, 37, 0.27) 0%, rgba(0, 0, 0, 0) 100%),
  linear-gradient(0deg, rgba(188, 188, 188, 0.15) 0%, rgba(184, 184, 184, 0.15) 100%),
  linear-gradient(90deg, rgb(21, 21, 21) -30%, rgba(0, 0, 0, 0) 180%),
  linear-gradient(265deg, #191919 0%, rgba(66, 66, 66, 0) 150%),
  repeating-linear-gradient(-55deg, transparent, transparent 5px, rgba(25, 25, 25, 0.12) 5px, rgba(32, 32, 32, 0.12) 10px);
}

.cashier-element.element-doge .element-inner {
  background: radial-gradient(650% 160% at 100% 25%, rgba(37, 21, 0, 0.27) 0%, rgba(0, 0, 0, 0) 100%),
  linear-gradient(0deg, rgba(202, 150, 39, 0.15) 0%, rgba(202, 131, 39, 0.15) 100%),
  linear-gradient(90deg, rgb(255, 197, 6) -30%, rgba(0, 0, 0, 0) 180%),
  linear-gradient(265deg, #190e00 0%, rgba(82, 47, 12, 0) 150%),
  repeating-linear-gradient(-55deg, transparent, transparent 5px, rgba(36, 21, 2, 0.12) 5px, rgba(36, 2, 2, 0.12) 10px);
}

.cashier-element.element-bch .element-inner {
  background: radial-gradient(650% 160% at 100% 25%, rgba(37, 55, 0, 0.27) 0%, rgba(0, 0, 0, 0) 100%),
  linear-gradient(0deg, rgba(102, 195, 81, 0.15) 0%, rgba(110, 195, 81, 0.15) 100%),
  linear-gradient(90deg, rgba(38, 156, 30, 0.62) -30%, rgba(0, 0, 0, 0) 180%),
  linear-gradient(265deg, #001626 0%, rgba(12, 52, 82, 0) 150%),
  repeating-linear-gradient(-55deg, transparent, transparent 5px, rgba(2, 21, 36, 0.12) 5px, rgba(2, 21, 36, 0.12) 10px);
}

.cashier-element.element-xmr .element-inner {
  background: radial-gradient(650% 160% at 100% 25%, rgba(102, 51, 0, 0.27) 0%, rgba(0, 0, 0, 0) 100%),
  linear-gradient(0deg, rgba(255, 102, 0, 0.15) 0%, rgba(255, 102, 0, 0.15) 100%),
  linear-gradient(90deg, rgba(255, 102, 0, 0.62) -30%, rgba(0, 0, 0, 0) 180%),
  linear-gradient(265deg, #331a00 0%, rgba(102, 51, 0, 0) 150%),
  repeating-linear-gradient(-55deg, transparent, transparent 5px, rgba(51, 25, 0, 0.12) 5px, rgba(51, 25, 0, 0.12) 10px);
}

.cashier-element.element-dash .element-inner {
  background: radial-gradient(650% 160% at 100% 25%, rgba(0, 51, 102, 0.27) 0%, rgba(0, 0, 0, 0) 100%),
  linear-gradient(0deg, rgba(0, 141, 228, 0.15) 0%, rgba(0, 141, 228, 0.15) 100%),
  linear-gradient(90deg, rgba(0, 141, 228, 0.62) -30%, rgba(0, 0, 0, 0) 180%),
  linear-gradient(265deg, #001626 0%, rgba(12, 52, 82, 0) 150%),
  repeating-linear-gradient(-55deg, transparent, transparent 5px, rgba(2, 21, 36, 0.12) 5px, rgba(2, 21, 36, 0.12) 10px);
}

.cashier-element.element-vet .element-inner {
  background: radial-gradient(650% 160% at 100% 25%, rgba(37, 20, 0, 0.27) 0%, rgba(0, 0, 0, 0) 100%),
  linear-gradient(0deg, rgba(62, 100, 200, 0.15) 0%, rgba(62, 100, 200, 0.15) 100%),
  linear-gradient(90deg, rgba(76, 62, 156, 0.62) -30%, rgba(0, 0, 0, 0) 180%),
  linear-gradient(265deg, #001626 0%, rgba(12, 52, 82, 0) 150%),
  repeating-linear-gradient(-55deg, transparent, transparent 5px, rgba(2, 21, 36, 0.12) 5px, rgba(2, 21, 36, 0.12) 10px);
}

.cashier-element.element-uni .element-inner {
  background: radial-gradient(650% 160% at 100% 25%, rgba(102, 0, 51, 0.27) 0%, rgba(0, 0, 0, 0) 100%),
  linear-gradient(0deg, rgba(255, 0, 122, 0.15) 0%, rgba(255, 0, 122, 0.15) 100%),
  linear-gradient(90deg, rgba(255, 0, 122, 0.62) -30%, rgba(0, 0, 0, 0) 180%),
  linear-gradient(265deg, #33001a 0%, rgba(102, 0, 51, 0) 150%),
  repeating-linear-gradient(-55deg, transparent, transparent 5px, rgba(51, 0, 25, 0.12) 5px, rgba(51, 0, 25, 0.12) 10px);
}

.cashier-element.element-ada .element-inner {
  background: radial-gradient(650% 160% at 100% 25%, rgba(0, 20, 37, 0.27) 0%, rgba(0, 0, 0, 0) 100%),
  linear-gradient(0deg, rgba(0, 51, 173, 0.15) 0%, rgba(0, 51, 173, 0.15) 100%),
  linear-gradient(90deg, rgba(0, 51, 173, 0.62) -30%, rgba(0, 0, 0, 0) 180%),
  linear-gradient(265deg, #001626 0%, rgba(12, 52, 82, 0) 150%),
  repeating-linear-gradient(-55deg, transparent, transparent 5px, rgba(2, 21, 36, 0.12) 5px, rgba(2, 21, 36, 0.12) 10px);
}

.cashier-element.element-dgb .element-inner {
  background: radial-gradient(650% 160% at 100% 25%, rgba(0, 20, 37, 0.27) 0%, rgba(0, 0, 0, 0) 100%),
  linear-gradient(0deg, rgba(0, 64, 128, 0.15) 0%, rgba(0, 64, 128, 0.15) 100%),
  linear-gradient(90deg, rgba(0, 64, 128, 0.62) -30%, rgba(0, 0, 0, 0) 180%),
  linear-gradient(265deg, #001626 0%, rgba(12, 52, 82, 0) 150%),
  repeating-linear-gradient(-55deg, transparent, transparent 5px, rgba(2, 21, 36, 0.12) 5px, rgba(2, 21, 36, 0.12) 10px);
}

.cashier-element .inner-background {
  position: absolute;
  top: -12px;
  right: -33px;
  transform: scaleX(-1);
  z-index: -1;
}

.cashier-element .inner-background img {
  width: 140px;
  opacity: 0.04;
}

.cashier-element .inner-image {
  width: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
}

img {
  width: 45px;
}

.cashier-element .inner-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.cashier-element .text-method {
  font-size: 14px;
  font-weight: 800;
}

.cashier-element .text-type {
  font-size: 12px;
  font-weight: 700;
  color: #ffffff;
}

@media only screen and (max-width: 840px) {

  .cashier-element:nth-child(4),
  .cashier-element:nth-child(7) {
    margin-right: 0;
  }

}

@media only screen and (max-width: 625px) {

  .cashier-element:nth-child(3),
  .cashier-element:nth-child(5) {
    margin-right: 0;
  }

}
</style>
