<template>
    <div class="duels-controls">
        <div class="controls-title">
            <span>COINFLIP</span>
        </div>
        <div class="controls-actions">
            <div class="actions-amount">
                <input v-model="duelsAmount" v-on:input="duelsValidateInput" type="text" placeholder="BET AMOUNT" />
                <img src="@/assets/img/icons/coin.svg" alt="icon" />
                <div class="amount-buttons">
                    <button v-on:click="duelsSetAmount('clear')">
                        <div class="button-inner">CLEAR</div>
                    </button>
                    <button v-on:click="duelsSetAmount('2x')">
                        <div class="button-inner">2x</div>
                    </button>
                    <button v-on:click="duelsSetAmount('max')" class="button-max">
                        <div class="button-inner">MAX</div>
                    </button>
                </div>
            </div>
            <!--<DuelsFilterCount />-->
            <button v-on:click="duelsCreateButton" class="button-create">
                <div class="button-inner">CREATE</div>
            </button>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DuelsFilterCount from '@/components/coinflip/DuelsFilterCount';

export default {
    name: 'DuelsControls',
    components: {
        DuelsFilterCount
    },
    data() {
        return {
            duelsAmount: null
        }
    },
    methods: {
        ...mapActions([
            'notificationShow',
            'duelsSendCreateSocket'
        ]),
        duelsValidateInput() {
            this.duelsAmount = this.duelsAmount.replace(',', '.').replace(/[^\d.]/g, '');
            this.duelsAmount = this.duelsAmount.indexOf('.') >= 0 ? this.duelsAmount.substr(0, this.duelsAmount.indexOf('.')) + '.' + this.duelsAmount.substr((this.duelsAmount.indexOf('.') + 1), 2).replace('.', '') : this.duelsAmount;
        },
        duelsSetAmount(action) {
            let amount = Math.floor(this.duelsAmount * 100);

            if (action === 'clear') {
                amount = 0;
            } else if (action === '2x') {
                amount = Math.floor(amount * 2);
            } else if (action === 'max') {
                amount = Math.floor(this.authUser.user.balance / 10);
            }

            this.duelsAmount = parseFloat(amount / 100).toFixed(2);
        },
        duelsCreateButton() {
            if (this.socketSendLoading !== null) { return; }

            if (this.authUser.user === null) {
                this.notificationShow({ type: 'error', message: 'Please sign in to perform this action.' });
                return;
            }

            const amount = Math.floor(this.duelsAmount * 1000);

            if (amount === null || isNaN(amount) === true || amount <= 0) {
                this.notificationShow({ type: 'error', message: 'Your entered bet amount is invalid.' });
                return;
            }

            const data = { amount: amount, playerCount: this.duelsFilterCount };
            this.duelsSendCreateSocket(data);
        }
    },
    computed: {
        ...mapGetters([
            'socketSendLoading',
            'authUser',
            'duelsFilterCount'
        ])
    }
}
</script>

<style scoped>
.duels-controls {
    width: 90%;
    height: 76px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    background-color: var(--background-secondary);
}

.duels-controls .controls-title {
    height: 50px;
    display: flex;
    align-items: center;
}

.duels-controls .controls-title svg {
    margin-right: 12px;
}

.duels-controls .controls-title span {
    font-size: 28px;
    font-weight: 900;
    background: var(--highlight-pink);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.duels-controls .controls-actions {
    display: flex;
    align-items: center;
}

.duels-controls .actions-amount {
    width: 350px;
    height: 50px;
    position: relative;
    margin-right: 25px;
}

.duels-controls .actions-amount input {
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    position: absolute;
    top: 1px;
    left: 1px;
    padding: 0 175px 0 43px;
    font-size: 12px;
    font-weight: 600;
    color: #ffffff;
    background-color: var(--background-primary);
    border-radius: 10px;
}

.duels-controls .actions-amount input::placeholder {
    color: var(--highlight-white);
}

.duels-controls .actions-amount img {
    width: 24px;
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translate(0, -50%);
}

.duels-controls .amount-buttons {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translate(0, -50%);
}

.duels-controls .amount-buttons button {
    min-width: 45px;
    height: 31px;
    margin-right: 8px;
    transition: all .2s;
}

.amount-buttons button:hover {
    transition-duration: .2s;
    opacity: .8;
}

.amount-buttons button:active {
    transition-duration: .2s;
    scale: .9;
}

.duels-controls .amount-buttons button.button-max {
    filter: var(--highlight-pink);
}

.duels-controls .amount-buttons button:last-of-type {
    margin-right: 0;
}

.duels-controls .amount-buttons button .button-inner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 8px;
    font-size: 12px;
    font-weight: 700;
    color: var(--highlight-white);
    background-color: var(--background-secondary);
    border-radius: 6px;
}

.duels-controls .amount-buttons button.button-max .button-inner {
    color: #ffffff;
    background: var(--highlight-pink);
}

.duels-controls .controls-actions button.button-create {
    width: 130px;
    height: 50px;
    transition: all .2s;
}

.button-create:hover {
    transition-duration: .2s;
    opacity: .8;
}

.button-create:active {
    transition-duration: .2s;
    scale: .9;
}

.duels-controls .controls-actions button.button-create .button-inner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 800;
    color: #ffffff;
    background: var(--highlight-pink);
    border-radius: 8px;
}

@media only screen and (max-width: 1180px) {

    .duels-controls {
        width: 100%;
        padding: 12px 10px;
    }

}

@media only screen and (max-width: 950px) {

    .duels-controls {
        height: auto;
        flex-direction: column;
        align-items: flex-start;
    }

    .duels-controls .controls-actions {
        width: 100%;
        justify-content: space-between;
        margin-top: 10px;
    }

    .duels-controls .actions-amount {
        width: calc(100% - 237px);
    }

}

@media only screen and (max-width: 650px) {

    .duels-controls .controls-actions {
        flex-wrap: wrap;
    }

    .duels-controls .actions-amount {
        width: 100%;
        margin: 0;
    }

    .duels-controls .controls-actions button.button-create {
        width: 100%;
        margin-top: 10px;
    }

}

@media only screen and (max-width: 500px) {

    .duels-controls .actions-amount input {
        padding: 0 70px 0 43px;
    }

    .duels-controls .amount-buttons button {
        display: none;
        margin-right: 0;
    }

    .duels-controls .amount-buttons button:first-child {
        display: block;
    }

}
</style>
