import Vue from 'vue';
import VueMeta from 'vue-meta';
import axios from 'axios';

import App from './App.vue';
import router from './router';
import store from './store';

import '@/assets/css/global.css';

import Hotjar from '@hotjar/browser';

Vue.use(VueMeta);

Vue.config.productionTip = false;

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

const token = localStorage.getItem('token');
if (token !== undefined && token !== null) {
    axios.defaults.headers.common['x-auth-token'] = token;
}

// Initialize Hotjar with your Site ID and version from environment variables
const siteId = process.env.VUE_APP_HOTJAR_ID;
const hotjarVersion = process.env.VUE_APP_HOTJAR_VERSION;

Hotjar.init(siteId, hotjarVersion);

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
