<template>
  <div class="towers">
    <div class="towers-container">
      <div class="container-game" v-bind:class="['game-' + towersRisk]">
        <div class="static-row bottom">
          <img src="@/assets/img/towers/tower-bottom.png" />
          <img src="@/assets/img/towers/tower-bottom.png" />
          <img src="@/assets/img/towers/tower-bottom.png" />
        </div>
        <TowersRow v-for="row in 8" v-bind:key="row" v-bind:row="row - 1"/>
        <div class="static-row top">
          <img src="@/assets/img/towers/tower-top.png" />
          <img src="@/assets/img/towers/tower-top.png" />
          <img src="@/assets/img/towers/tower-top.png" />
        </div>
      </div>
      <TowersControls/>
    </div>

    <!--<Bets />-->
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import Bets from '@/components/bets/Bets';
import TowersRow from '@/components/towers/TowersRow';
import TowersControls from '@/components/towers/TowersControls';

export default {
  name: 'Towers',
  metaInfo: {
    title: 'Towers - Candy Casino'
  },
  components: {
    Bets,
    TowersRow,
    TowersControls
  },
  methods: {
    ...mapActions([
      'socketConnectTowers',
      'socketDisconnectTowers'
    ])
  },
  computed: {
    ...mapGetters([
      'towersRisk'
    ])
  },
  created() {
    this.socketConnectTowers();
  },
  beforeRouteLeave(to, from, next) {
    this.socketDisconnectTowers();
    next();
  }
};
</script>

<style scoped>
.towers {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 45px 10px;
  background-image: url("../assets/img/towers/towers-background.png");
  background-position: center;
  background-size: cover;
}

.towers .towers-container {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-around;
  padding: 20px 0;
  border-radius: 25px;
}

.towers .container-game {
  width: min(100%, 550px);
  display: flex;
  flex-direction: column-reverse;
  gap: 8px;
}

.container-game .static-row{
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 min(2vw, 20px);
}

.container-game .static-row img{
  width: min(30vw, 150px);
}

@media only screen and (min-width: 1150px) {
  .towers .towers-container {
    flex-direction: row-reverse;
  }
}

@media only screen and (max-width: 950px) {

}

@media only screen and (max-width: 550px) {

}
</style>