<template>
  <div class="games-container">
    <div v-if="!currentGame">
      <GameCategories :categories="categories" :activeCategory="activeCategory" @category-change="setActiveCategory" />
      
      <div class="search-and-filters">
        <div class="search-bar">
          <img class="search-icon" alt="Search" src="@/assets/icons/search.svg" />
          <input 
            type="text" 
            placeholder="Search your game" 
            v-model="localSearchQuery"
            @input="handleSearchInput"
          />
        </div>
        <div class="provider-dropdown" ref="providerDropdown">
          <div class="provider-selected" @click="toggleProviderDropdown">
            <span>{{ selectedProvider || 'All Providers' }}</span>
            <img class="right-arrow" alt="Right arrow" src="@/assets/icons/right-arrow_12857695 1.svg" />
          </div>
          <div v-if="showProviderDropdown" class="provider-list">
            <div 
              class="provider-item" 
              :class="{ 'selected': selectedProvider === '' }"
              @click="setProvider('')"
            >
              All Providers
            </div>
            <div 
              v-for="provider in providers" 
              :key="provider" 
              class="provider-item"
              :class="{ 'selected': selectedProvider === provider }"
              @click="setProvider(provider)"
            >
              {{ provider }}
            </div>
          </div>
        </div>
      </div>

      <div v-if="isLoading && !games.length" class="loading">Loading games... Please wait.</div>
      <div v-else-if="hasError" class="error">
        Error: {{ errorMessage }}
        <button @click="retryFetchGames">Retry</button>
      </div>
      <div v-else-if="!games || games.length === 0" class="no-games">
        No games available. Please try again later.
        <button @click="retryFetchGames">Retry</button>
      </div>
      <div v-else class="game-grid">
        <div v-for="game in games" :key="game.uuid" class="game-card" @click="playGame(game)">
          <div class="game-image" :style="getGameImageStyle(game)">
            <div class="game-overlay" :style="getOverlayStyle(game)"></div>
            <div class="game-info">
              <h3 class="game-name">{{ game.name.toUpperCase() }}</h3>
              <span class="provider-name">{{ game.provider }}</span>
            </div>
          </div>
        </div>
      </div>

      <div v-if="hasMoreGames" class="load-more">
        <button @click="loadMoreGames" :disabled="isLoading">
          {{ isLoading ? 'Loading...' : 'Load More' }}
        </button>
      </div>
    </div>

    <div v-if="currentGame" class="game-frame">
      <div class="game-header">
        <div class="game-title">{{ currentGame.name }}</div>
        <button @click="closeGame" class="close-button">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
      </div>
      <div class="game-iframe-container">
        <iframe v-if="currentGame.url" :src="currentGame.url" frameborder="0" allowfullscreen></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import GameCategories from '@/components/slots/GameCategories.vue';
import _ from 'lodash';
import '@/styles/gameCard.css'; // Import the gameCard.css styles

export default {
  name: 'Games',
  components: {
    GameCategories,
  },
  data() {
    return {
      currentGame: null,
      sessionId: null,
      showProviderDropdown: false,
      isMobile: false,
      localSearchQuery: '',
    };
  },
  computed: {
    ...mapState('slotegrator', ['games', 'loading', 'error', 'activeCategory', 'selectedProvider', 'categories', 'providers', 'hasMoreGames']),
    ...mapGetters('slotegrator', ['isLoading', 'hasError', 'errorMessage']),
    ...mapState({
      authUser: state => state.auth.authUser,
      currentUser: state => state.auth.authUser.user,
      storeSearchQuery: state => state.slotegrator.searchQuery,
    }),
    isAuthenticated() {
      return !!this.currentUser;
    },
  },
  methods: {
    ...mapActions('slotegrator', [
      'fetchGames', 
      'initGame', 
      'initDemoGame', 
      'setActiveCategory', 
      'setSelectedProvider', 
      'setSearchQuery'
    ]),
    
    async fetchGamesData(loadMore = false) {
      await this.fetchGames({ 
        loadMore,
        category: this.activeCategory !== 'All Games' ? this.activeCategory : undefined,
        provider: this.selectedProvider, // Always include the selected provider
        search: this.localSearchQuery
      });
    },
    
    loadMoreGames() {
      this.fetchGamesData(true);
    },

    setCategory(categoryName) {
      this.setActiveCategory(categoryName);
      this.setSelectedProvider(''); // Reset to "All Providers"
      this.$store.commit('slotegrator/RESET_GAMES');
      this.fetchGamesData();
    },
    
    setProvider(provider) {
      this.setSelectedProvider(provider);
      this.$store.commit('slotegrator/RESET_GAMES');
      this.fetchGamesData();
    },
    
    handleSearchInput: _.debounce(function() {
      this.setSearchQuery(this.localSearchQuery);
      this.$store.commit('slotegrator/RESET_GAMES');
      this.fetchGamesData();
    }, 300),
    
    getOverlayColor(game) {
      return game.overlayColor || 'rgba(0,0,0,0.5)';
    },

    retryFetchGames() {
      this.fetchGamesData();
    },
    
    toggleProviderDropdown() {
      this.showProviderDropdown = !this.showProviderDropdown;
    },
    
    closeProviderDropdown(event) {
      if (!this.$refs.providerDropdown.contains(event.target)) {
        this.showProviderDropdown = false;
      }
    },
    
    checkMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
    
    async playGame(game) {
      console.log('playGame method called with game:', game);
      
      try {
        if (!this.isAuthenticated) {
          console.log('User not authenticated, redirecting to demo game');
          return this.playDemoGame(game);
        }

        const user = this.currentUser;
        if (!user) {
          throw new Error('User data not available');
        }

        this.sessionId = Date.now().toString() + user._id;
        
        const gameData = {
          game_uuid: game.uuid,
          player_id: user._id,
          player_name: user.username,
          currency: user.currency || 'EUR',
          session_id: this.sessionId,
          return_url: `${window.location.origin}/games?action=close`,
          language: 'en'
        };

        console.log('Game data being sent to initGame:', gameData);
        const initData = await this.initGame(gameData);
        console.log('Game initialized with data:', initData);
        
        if (initData && initData.url) {
          this.currentGame = { 
            url: initData.url,
            name: game.name
          };
          // Prevent default navigation
          event.preventDefault();
          // Scroll to the top of the page
          window.scrollTo(0, 0);
        } else {
          throw new Error('Game URL not received from the server');
        }
      } catch (error) {
        console.error('Error playing game:', error);
        this.$store.dispatch('notificationShow', error.message || 'Failed to start the game. Please try again.');
      }

      if (this.isMobile) {
        // Request fullscreen on mobile devices
        const elem = document.documentElement;
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) { // Firefox
          elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) { // Chrome, Safari and Opera
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { // IE/Edge
          elem.msRequestFullscreen();
        }
      }
    },
    
    async playDemoGame(game) {
      console.log('playDemoGame method called with game:', game);
      try {
        const demoData = {
          game_uuid: game.uuid,
          return_url: `${window.location.origin}/games?action=close`,
          language: 'en'
        };

        console.log('Demo data being sent to initDemoGame:', demoData);
        const initData = await this.initDemoGame(demoData);
        console.log('Demo game initialized with data:', initData);

        if (initData && initData.url) {
          this.currentGame = { 
            url: initData.url,
            name: game.name
          };
          // Prevent default navigation
          event.preventDefault();
          // Scroll to the top of the page
          window.scrollTo(0, 0);
        } else {
          throw new Error('Demo game URL not received from the server');
        }
      } catch (error) {
        console.error('Error playing demo game:', error);
        this.$store.dispatch('notificationShow', error.message || 'Failed to start the demo game. Please try again.');
      }
    },
    
    closeGame() {
      this.currentGame = null;
      // Exit fullscreen
      if (document.fullscreenElement || 
          document.webkitFullscreenElement || 
          document.mozFullScreenElement ||
          document.msFullscreenElement) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) { // Firefox
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) { // Chrome, Safari and Opera
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { // IE/Edge
          document.msExitFullscreen();
        }
      }
    },

    getGameImageStyle(game) {
      return {
        backgroundImage: `url(${game.imageUrl})`,
      };
    },

    getOverlayStyle(game) {
      const overlayColor = game.overlayColor || '#000000';
      return {
        '--overlay-color': overlayColor,
      };
    },
  },
  created() {
    this.fetchGamesData();
    
    // Check for close action in URL
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('action') === 'close') {
      this.closeGame();
      // Remove the query parameter from the URL
      this.$router.replace({ query: null });
    }
  },
  mounted() {
    document.addEventListener('click', this.closeProviderDropdown);
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeProviderDropdown);
    window.removeEventListener('resize', this.checkMobile);
  },
  watch: {
    storeSearchQuery(newQuery) {
      this.localSearchQuery = newQuery;
    },
    '$route.query': {
      handler(newQuery) {
        if (newQuery.gameUrl && newQuery.gameName) {
          this.currentGame = {
            url: newQuery.gameUrl,
            name: newQuery.gameName
          };
          // Scroll to the top of the page
          window.scrollTo(0, 0);
        }
      },
      immediate: true
    }
  },
};
</script>

<style scoped>
@import '../styles/gameCard.css'; 

.games-container {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  background-color: var(--background-secondary);
  color: white;
  padding: 30px 20px;
}

.search-and-filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;
}

.search-bar {
  position: relative;
  width: calc(100% - 232px);
  margin-right: 20px;
  background-color: var(--background-primary);
  border: 2px solid var(--background-secondary);
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding: 14px 17px;
  height: 53px; /* Explicitly set height */
}

.search-bar input {
  width: 100%;
  background-color: transparent;
  border: none;
  color: #ffffffbf;
  font-family: "Montserrat-Medium", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
  padding: 0; /* Remove default padding */
  height: 100%; /* Take full height of parent */
}

.search-bar input::placeholder {
  color: #ffffffbf;
}

.search-bar input:focus {
  outline: none;
}

.search-icon {
  width: 30px;
  height: 30px;
  flex-shrink: 0; /* Prevent icon from shrinking */
}

.provider-dropdown {
  position: relative;
  width: 212px;
}

.provider-selected {
  align-items: center;
  background-color: var(--background-primary);
  border: 2px solid var(--background-secondary);
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  padding: 14px 17px;
  cursor: pointer;
  height: 53px;
}

.provider-selected span {
  color: #ffffffbf;
  font-family: "Montserrat-Medium", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.right-arrow {
  height: 13px;
  width: 13px;
  transition: transform 0.3s ease;
}

.provider-dropdown.open .right-arrow {
  transform: rotate(180deg);
}

.provider-list {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: var(--background-primary);
  border: 2px solid var(--background-secondary);
  border-top: none;
  border-radius: 0 0 12px 12px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 20; /* Set a higher z-index to ensure it appears above the games */
}

.provider-item {
  padding: 10px 17px;
  color: #ffffffbf;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.provider-item:hover {
  background-color: #ffffff14;
}

.provider-item.selected {
  background-color: rgba(0, 117, 255, 0.2);
  color: var(--highlight-pink);
}

.game-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(187px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.loading, .error, .no-games {
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
}

.error {
  color: #e74c3c;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.pagination button {
  background-color: var(--highlight-pink);
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin: 0 10px;
}

.pagination button:hover {
  background-color: var(--highlight-pink);
}

.pagination button:disabled {
  background-color: #95a5a6;
  cursor: not-allowed;
  opacity: 0.7;
}

.pagination span {
  font-size: 16px;
}

.providers-section {
  margin-bottom: 20px;
}

.toggle-providers {
  background-color: var(--highlight-pink);
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
}

.providers-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 10px;
  margin-top: 10px;
}

.provider-card {
  background-color: #252a3d;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.provider-card.active {
  background-color: var(--highlight-pink);
}

.provider-card img {
  max-width: 100%;
  height: auto;
}

.provider-card span {
  color: white;
  font-size: 12px;
}

@media only screen and (max-width: 1024px) {
  .games-container {
    padding: 30px 15px;
  }

  .game-grid {
    grid-template-columns: repeat(auto-fill, minmax(108.5px, 1fr));
  }
}

@media only screen and (max-width: 768px) {
  .games-container {
    padding: 25px 10px;
  }

  .search-and-filters {
    flex-direction: column;
    align-items: stretch;
  }

  .search-bar {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .provider-dropdown {
    width: 100%;
  }

  .provider-selected {
    width: 100%;
  }
}

@media only screen and (max-width: 480px) {
  .games-container {
    padding: 20px 5px;
  }

  .game-categories button {
    padding: 8px 12px;
    font-size: 14px;
  }

  .game-info h3 {
    font-size: 12px;
  }

  .provider-name {
    font-size: 10px;
  }

  .search-bar input {
    padding: 10px 15px;
    font-size: 14px;
  }
}

.game-frame {
  width: 100%;
  position: relative;
  background-color: #161c2c;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
}

.game-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
}

.game-title {
  color: white;
  font-size: 18px;
  font-weight: bold;
}

.close-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 5px;
}

.close-button:hover {
  opacity: 0.8;
}

.game-iframe-container {
  position: relative;
  padding-top: 56.25%; /* 16:9 aspect ratio */
  border-radius: 10px;
  overflow: hidden;
  margin-top: 10px; /* Add some space between the header and the iframe */
}

.game-iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.random-games {
  margin-top: 20px;
}

@media (max-width: 768px) {
  .game-grid {
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  }

  .game-header {
    padding: 5px 10px;
  }

  .game-title {
    font-size: 16px;
  }

  .close-button svg {
    width: 20px;
    height: 20px;
  }
}

@media only screen and (max-width: 480px) {
  .game-grid {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }

  .game-header {
    padding: 5px 10px;
  }

  .game-title {
    font-size: 16px;
  }

  .close-button svg {
    width: 20px;
    height: 20px;
  }
}

.load-more {
  text-align: center;
  margin-top: 20px;
}

.load-more button {
  background-color: var(--highlight-pink);
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}

.load-more button:disabled {
  background-color: #bdc3c7;
  cursor: not-allowed;
}

.no-games, .error {
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
}

.no-games button, .error button {
  margin-top: 10px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: var(--highlight-pink);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.no-games button:hover, .error button:hover {
  background-color: #2980b9;
}

/* Remove these font-face declarations if they're not needed elsewhere */
/*
@font-face {
  font-family: 'FONTSPRING DEMO - All Round Gothic Bold';
  src: url('@/assets/fonts/FONTSPRINGDEMO-AllRoundGothicBoldRegular.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'FONTSPRING DEMO - All Round Gothic Book';
  src: url('@/assets/fonts/FONTSPRINGDEMO-AllRoundGothicBookRegular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
*/
</style>