<template>
  <BaseSlider :items="games" class="original-games-slider">
    <template v-slot:default>
      <div v-for="game in games" :key="game.id" class="game-card" :style="getCardStyle(game)">
        <div class="game" :style="getGameStyle(game)" @click="navigateToGame(game)">
          <div class="game-overlay" :style="{ backgroundImage: getOverlayGradient(game) }"></div>
          <img :src="game.imageUrl" :alt="game.name" class="game-image" />
          <div class="game-info">
            <div class="game-name" :style="getGameNameStyle(game.name)">{{ game.name.toUpperCase() }}</div>
          </div>
        </div>
      </div>
    </template>
  </BaseSlider>
</template>

<script>
import BaseSlider from '@/components/base/BaseSlider.vue'
import ColorThief from 'colorthief'

export default {
  name: 'OriginalGamesSlider',
  components: {
    BaseSlider
  },
  data() {
    return {
      games: [
        { id: 1, name: 'Mines', link: '/mines', imageUrl: require('@/assets/img/home/originalgames/Mines.png') },
        { id: 2, name: 'Crash', link: '/crash', imageUrl: require('@/assets/img/home/originalgames/Crash.png') },
        { id: 3, name: 'Upgrader', link: '/upgrader', imageUrl: require('@/assets/img/home/originalgames/Upgrader.png') },
        { id: 4, name: 'Blackjack', link: '/blackjack', imageUrl: require('@/assets/img/home/originalgames/Blackjack.png') },
        { id: 5, name: 'Cases', link: '/unbox', imageUrl: require('@/assets/img/home/originalgames/Case.png') },
        { id: 6, name: 'Towers', link: '/towers', imageUrl: require('@/assets/img/home/originalgames/Tower.png') },
        { id: 7, name: 'Roulette', link: '/roll', imageUrl: require('@/assets/img/home/originalgames/Roulette.png') },
        { id: 8, name: 'Case Battle', link: '/battles', imageUrl: require('@/assets/img/home/originalgames/Case_Battle.png') },
        { id: 9, name: 'Duels', link: '/duels', imageUrl: require('@/assets/img/home/originalgames/Dice_Duels.png') },
      ],
      gameColors: {},
      windowWidth: window.innerWidth,
    }
  },
  computed: {
    cardSize() {
      const baseWidth = 187;
      const baseHeight = 235;
      let width;

      if (this.windowWidth < 480) width = 140;
      else if (this.windowWidth < 768) width = 160;
      else if (this.windowWidth < 1024) width = 180;
      else width = Math.round((baseWidth / baseHeight) * 187); // Adjusted width to maintain aspect ratio

      const height = 187; // Fixed height
      return { width, height };
    }
  },
  mounted() {
    this.extractColors();
    window.addEventListener('resize', this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    extractColors() {
      const colorThief = new ColorThief();
      this.games.forEach(game => {
        const img = new Image();
        img.crossOrigin = 'Anonymous';
        img.src = game.imageUrl;
        img.onload = () => {
          const palette = colorThief.getPalette(img, 8);
          const vibrantColor = this.findVibrantColor(palette);
          this.$set(this.gameColors, game.id, vibrantColor);
        };
      });
    },
    findVibrantColor(palette) {
      return palette.reduce((mostVibrant, color) => {
        const [h, s, l] = this.rgbToHsl(...color);
        if (l < 0.3 || l > 0.7) return mostVibrant;
        const vibrancy = s * (1 - Math.abs(l - 0.5));
        const [mh, ms, ml] = this.rgbToHsl(...mostVibrant);
        const mostVibrancy = ms * (1 - Math.abs(ml - 0.5));
        return vibrancy > mostVibrancy ? color : mostVibrant;
      }, palette[0]);
    },
    rgbToHsl(r, g, b) {
      r /= 255, g /= 255, b /= 255;
      const max = Math.max(r, g, b), min = Math.min(r, g, b);
      let h, s, l = (max + min) / 2;

      if (max === min) {
        h = s = 0;
      } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
          case r: h = (g - b) / d + (g < b ? 6 : 0); break;
          case g: h = (b - r) / d + 2; break;
          case b: h = (r - g) / d + 4; break;
        }
        h /= 6;
      }
      return [h, s, l];
    },
    getCardStyle(game) {
      const color = this.getRGBString(this.gameColors[game.id]);
      return {
        borderColor: color,
        width: `${this.cardSize.width}px`,
        height: `${this.cardSize.height}px`,
      };
    },
    getGameStyle(game) {
      return {
        backgroundImage: `url(${game.imageUrl})`,
      };
    },
    getOverlayGradient(game) {
      const color = this.getRGBString(this.gameColors[game.id]);
      return `linear-gradient(to top, ${color} 0%, ${color} 50%, rgba(0,0,0,0) 100%)`;
    },
    getRGBString(color) {
      return color ? `rgba(${color[0]}, ${color[1]}, ${color[2]}, 0.7)` : 'transparent';
    },
    getGameNameStyle(name) {
      const length = name.length;
      let fontSize = 1.7;
      if (length > 7) fontSize = 1.2;
      if (length > 10) fontSize = 1;
      if (length > 15) fontSize = 0.9;
      return { fontSize: `${fontSize}rem` };
    },
    navigateToGame(game) {
      this.$router.push(game.link);
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  }
}
</script>

<style scoped>
.game-card {
  flex: 0 0 auto;
  margin-right: 1rem;
  position: relative;
  overflow: hidden;
  border-radius: 0.75rem;
  border: 3px solid;
  height: 187px; /* Set height to 187px */
  width: 149px; /* Adjusted width to maintain aspect ratio (187px * 0.797 ≈ 149px) */
  transition: ease 0.2s;
}

.game-card:hover{
  transform: scale(1.05);
}

.game {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  border-radius: 0.55rem;
  overflow: hidden;
}

.game-overlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 50%;
  border-radius: 0 0 0.55rem 0.55rem;
}

.game-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.55rem;
}

.game-info {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0.5rem;
  padding: 0 0.5rem;
  color: white;
  z-index: 2;
}

.game-name {
  font-family: 'Fontspring Gothic Bold', sans-serif;
  font-weight: bold;
  font-size: 40%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
</style>