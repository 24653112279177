<template>
  <div class="banner-rain">
    <div class="rain-text">
      <div class="text-header">
        THUNDERTIP!
      </div>
      <div class="text-info">JOIN THE CHAT!
        <span>EVERY 30 MINUTES</span>
        COINS ARE DISTRIBUTED AMONGST THE CHAT!
      </div>
      <div class="text-bottom">
        <div class="bottom-timer">
          <IconTimerGradient/>
          <div class="timer-value">
            <span>{{ rainTimer.split(':')[0] }}</span>
            m
            <span>{{ rainTimer.split(':')[1] }}</span>
            s
          </div>
        </div>
        <div class="bottom-amount">
          <img src="@/assets/img/icons/coin.svg" alt="icon"/>
          <div class="amount-value">
            <span>{{ rainFormatValue(generalRain.site.amount).split('.')[0] }}</span>
            .{{ rainFormatValue(generalRain.site.amount).split('.')[1] }}
          </div>
          <button v-on:click="rainSetDropdownTip(true); generalSetSidebarMobile('Chat')" class="button-tip">
            <span>TIP</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import IconRain from '@/components/icons/IconRain';
import IconTimerGradient from '@/components/icons/IconTimerGradient';

export default {
  name: 'BannerRain',
  components: {
    IconRain,
    IconTimerGradient
  },
  data() {
    return {
      rainTimerInterval: null,
      rainTimer: '00:00'
    };
  },
  methods: {
    ...mapActions(['rainSetDropdownTip', 'generalSetSidebarMobile']),
    helloTest() {
      console.log('hahahahahha');
    },
    rainFormatValue(value) {
      return parseFloat(Math.floor(value / 10) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    rainStartTimer() {
      const endingTime = new Date(this.generalRain.site.updatedAt).getTime() + (1000 * 60 * (this.generalRain.site.state === 'running' ? 2 : 30));
      let timeLeft = Math.floor((endingTime - (new Date().getTime() + this.generalTimeDiff)) / 1000);
      timeLeft = this.generalRain.site.state === 'pending' ? 60 * 2 : timeLeft <= 0 ? 0 : timeLeft;

      let timeMinutes = Math.floor((timeLeft % (60 * 60)) / 60);
      timeMinutes = timeMinutes < 10 ? '0' + timeMinutes : timeMinutes;
      let timeSeconds = Math.floor(timeLeft % 60);
      timeSeconds = timeSeconds < 10 ? '0' + timeSeconds : timeSeconds;

      this.rainTimer = timeMinutes.toString() + ':' + timeSeconds.toString();
    }
  },
  computed: {
    ...mapGetters(['generalTimeDiff', 'generalRain'])
  },
  beforeDestroy() {
    clearInterval(this.rainTimerInterval);
  },
  created() {
    this.rainTimerInterval = setInterval(() => {
      this.rainStartTimer();
    }, 500);
  }
};
</script>

<style scoped>
.banner-rain {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px 20px 25px;
  background: var(--blue-gradient-rain);
  overflow: hidden;
  z-index: 1;
  border-radius: 1rem;
}

.banner-rain::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url('~@/assets/img/banner-rain-bg.png');
  mix-blend-mode: luminosity;
  opacity: 0.5;
  z-index: -1;
  background-position: right;
}

.banner-rain .tip-rain-text {
  color: var(--highlight-white);
}

.banner-rain img {
  width: 115px;
}

.banner-rain .rain-text {
  width: calc(100%);
  padding-right: 40px;
}

.banner-rain .text-header {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  line-height: 48px;
  font-size: min(9vw, 50px);
  font-weight: 800;
  color: var(--highlight-white);
  gap: 1rem;
}

.banner-rain .text-header button.button-tip {
  width: 115px;
  height: 37px;
  position: relative;
  padding: 1px;
}

.banner-rain .text-header button.button-tip .button-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 800;
  background: var(--background-secondary);
  border-radius: 0.5rem;
}

.banner-rain .text-header button.button-tip .button-inner svg {
  width: 17px;
  margin-right: 8px;
}

.banner-rain .text-info {
  max-width: 18rem;
  margin-top: 10px;
  font-size: 12px;
  font-weight: 500;
  color: var(--highlight-white);
  /* font-family: 'Rubik'; */
}

.banner-rain .text-info span {
  color: var(--highlight-yellow);
}

.banner-rain .text-bottom {
  margin-top: 15px;
  display: flex;
}

.banner-rain .bottom-timer{
  width: 120px;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.banner-rain .bottom-amount{
  padding: 0 0.5rem;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.bottom-amount .button-tip {
  margin-left: 0.75rem;
  padding: 0.25rem 1rem;
  color: white;
  border-radius: 0.25rem;
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  background-color: var(--highlight-pink);
  transition: ease-in-out 0.1s;
}

.bottom-amount .button-tip:hover {
  padding: 0.25rem 1.25rem;
  background-color: white;
  color: var(--highlight-pink);
}

.banner-rain .bottom-timer {
  margin-right: 8px;
  background: var(--background-primary);
  border: 0.5px solid var(--background-secondary);
}

.banner-rain .bottom-timer svg {
  width: 16px;
  margin-right: 8px;
}

.banner-rain .bottom-amount {
  background: rgba(0, 0, 0, 0.25);
  border: 1px solid var(--highlight-pink);
}

.banner-rain .bottom-amount img {
  width: 1.5rem;
}

.banner-rain .timer-value,
.banner-rain .amount-value {
  font-size: 10px;
  font-weight: 600;
  color: #ffffff;
}

.banner-rain .timer-value span,
.banner-rain .amount-value span {
  font-size: 14px;
  font-weight: 800;
  color: #ffffff;
}

@media only screen and (max-width: 600px) {

  .bottom-amount .button-tip {
    margin-left: 0.5rem;
  }

  .banner-rain .bottom-amount{
    padding: 0 0.5rem 0 0.75rem;
  }

  .banner-rain .text-info {
    max-width: none;
  }

  .banner-rain img {
    display: none;
  }

  .banner-rain .rain-text {
    width: 100%;
    padding-right: 0;
  }
}
</style>
