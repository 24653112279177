import { render, staticRenderFns } from "./Coinflip.vue?vue&type=template&id=08394dff&scoped=true"
import script from "./Coinflip.vue?vue&type=script&lang=js"
export * from "./Coinflip.vue?vue&type=script&lang=js"
import style0 from "./Coinflip.vue?vue&type=style&index=0&id=08394dff&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08394dff",
  null
  
)

export default component.exports