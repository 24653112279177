<template>
  <div class="home-wrapper">
    <div class="home">
      <HomeBannerUserLarge 
        class="full-width-banner"
        :backgroundImage="require('@/assets/img/home/mainbanner/banner-castle.png')"
        :logo="require('@/assets/img/logo.png')"
        :character="require('@/assets/img/home/mainbanner/alex-romanov.png')"
      />
      <div class="home-content">
        <div class="home-section featured-games-container">
          <div class="featured-games-half desktop-only">
            <div class="title">
              <img src="@/assets/img/icons/featured-icon.png" alt="News"/>
              <span>Community</span>
            </div>
            <HomeBannerNews/>
          </div>
            <div class="featured-games-half"> 
              <div class="home-section">
                <div class="title">
                  <img src="@/assets/img/icons/featured-icon.png" alt="Original Games"/>
                  <span>Original Games</span>
                </div>
              <OriginalGamesSlider/>
            </div>
          </div>
        </div>

        

        <div class="home-section">
          <div class="title">
            <img src="@/assets/img/icons/featured-icon.png" alt="Live Games"/>
            <span>Live Games</span>
          </div>
          <LiveGamesSlider/>
        </div>

        <div class="home-section mobile-only">
          <div class="title">
            <img src="@/assets/img/icons/featured-icon.png" alt="News"/>
            <span>News</span>
          </div>
          <HomeBannerNews/>
        </div>

        <div class="home-section">
          <div class="title">
            <img src="@/assets/img/icons/featured-icon.png" alt="Provider of the Week"/>
            <span>Provider of the Week</span>
          </div>
          <ProviderOfWeekSlider/>
        </div>

        <div class="home-section">
          <div class="title">
            <img src="@/assets/img/icons/featured-icon.png" alt="More Featured Games"/>
            <span>More Featured Games</span>
          </div>
          <SecondFeatureGamesSlider/>
        </div>

        <div class="home-section bets-section">
          <div class="bets-container">
            <Bets/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomeBannerUserLarge from '@/components/home/HomeBannerUserSlider.vue';
import HomeBannerNews from '@/components/home/HomeBannerNews';
import HomeBannerUser from '@/components/home/HomeBannerUser';
import HomeGame from '@/components/home/HomeGame.vue';
import OriginalGamesSlider from '@/components/home/OriginalGamesSlider.vue';
import GameBannerSlider from '@/components/home/GameBannerSlider.vue';
import Bets from '@/components/bets/Bets.vue';
import LiveGamesSlider from '@/components/home/LiveGamesSlider.vue';
import ProviderOfWeekSlider from '@/components/home/ProviderOfWeekSlider.vue';
import SecondFeatureGamesSlider from '@/components/home/SecondFeatureGamesSlider.vue';
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'Home',
  metaInfo: {
    title: 'Candy Casino'
  },
  components: {
    HomeBannerUserLarge,
    HomeBannerNews,
    HomeBannerUser,
    HomeGame,
    OriginalGamesSlider,
    GameBannerSlider,
    Bets,
    LiveGamesSlider,
    ProviderOfWeekSlider,
    SecondFeatureGamesSlider
  },
  computed: {
    ...mapGetters([
      'unboxGetBoxes'
    ])
  },
  methods: {
    ...mapActions([
      'socketConnectUnbox',
      'socketDisconnectUnbox'
    ])
  },
  created() {
    this.socketConnectUnbox();
  },
  beforeDestroy() {
    this.socketDisconnectUnbox();
  }
};
</script>

<style scoped>
.home-wrapper {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 20px;
  padding-bottom: 60px; /* Add padding at the bottom to prevent overlap with footer */
}

.home {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.full-width-banner {
  width: 100%;
  margin-bottom: 30px;
}

.home-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
}

.home-section {
  margin-bottom: 10px;
}

.banner-user-news {
  display: flex;
  gap: 30px;
}

.banner-user-news > * {
  flex: 1;
  width: 50%;
}

.title {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.title img {
  height: 2rem;
  width: auto;
}

.title span {
  font-family: Montserrat, sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  color: white;
}

.content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  align-items: start;
}

.featured-games-container {
  display: flex;
  gap: 30px;
}

.featured-games-half {
  width: 50%;
  display: flex;
  flex-direction: column;
}

/* Adjust the game cards in OriginalGamesSlider */
.featured-games-half > :deep(.original-games-slider .game-card) {
  height: 170px;
  width: calc(170px * (11.5 / 14.5)); /* Maintain aspect ratio */
}

@media (max-width: 1024px) {
  .home {
    padding: 15px;
  }

  .content {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  }
}

@media (max-width: 900px) {
  .banner-user-news,
  .featured-games-container {
    flex-direction: column;
  }

  .banner-user-news > *,
  .featured-games-half {
    width: 100%;
  }

  .featured-games-half {
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .home-section {
    margin-bottom: 10px;
  }

  .title img {
    height: 1.5rem;
  }

  .title span {
    font-size: 1rem;
  }

  .content {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 0.75rem;
  }
}

@media (max-width: 480px) {
  .home {
    padding: 10px;
  }

  .content {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 0.5rem;
  }
}

.bets-section {
  display: flex;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  position: relative; /* Add this */
  z-index: 1; /* Add this */
}

.bets-container {
  width: 100%;
  max-width: 1050px; /* Adjust this value to match the width of your Bets component */
}

@media (max-width: 1100px) {
  .bets-container {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .bets-section {
    margin-bottom: 0; /* Remove bottom margin on mobile */
    position: static; /* Change to static on mobile */
  }

  .bets-container {
    overflow-x: auto; /* Allow horizontal scrolling on mobile if needed */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  }

  /* Target the Bets component to adjust its mobile layout */
  .bets-container :deep(.bets) {
    width: 100%;
    overflow: visible;
    position: static; /* Ensure it's not positioned absolutely */
  }

  /* Adjust the Bets component's inner elements for mobile */
  .bets-container :deep(.bets .bets-nav) {
    overflow-x: auto;
    white-space: nowrap;
    padding-bottom: 10px;
  }

  .bets-container :deep(.bets .bets-list) {
    overflow: visible;
    height: auto;
  }

  .bets-container :deep(.bets .list-content) {
    overflow: visible;
  }
}

/* Add this new style for the HomeBannerUserSlider */
.full-width-banner + .full-width-banner {
  margin-top: 30px;
}

.desktop-only {
  display: block;
}

.mobile-only {
  display: none;
}

@media (max-width: 768px) {
  .desktop-only {
    display: none;
  }

  .mobile-only {
    display: block;
  }

  .featured-games-container {
    flex-direction: column;
  }

  .featured-games-half {
    width: 100%;
  }

  .home-section {
    margin-bottom: 20px;
  }

  .title img {
    height: 1.5rem;
  }

  .title span {
    font-size: 1rem;
  }

  .content {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 0.75rem;
  }

  .bets-section {
    margin-bottom: 0;
    position: static;
  }

  .bets-container {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .bets-container :deep(.bets) {
    width: 100%;
    overflow: visible;
    position: static;
  }

  .bets-container :deep(.bets .bets-nav) {
    overflow-x: auto;
    white-space: nowrap;
    padding-bottom: 10px;
  }

  .bets-container :deep(.bets .bets-list) {
    overflow: visible;
    height: auto;
  }

  .bets-container :deep(.bets .list-content) {
    overflow: visible;
  }
}
</style>