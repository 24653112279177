<template>
  <div class="frame" ref="frame" @mousedown="startDrag" @mousemove="drag" @mouseup="endDrag" @mouseleave="endDrag" @touchstart="startDrag" @touchmove="drag" @touchend="endDrag">
    <div class="slider-container" :style="{ transform: `translateX(${translateX}px)` }">
      <div v-for="category in categories" :key="category" :class="['category-wrapper', { active: category === activeCategory }]" @click="selectCategory(category)">
        <div class="category-content">
          <img :src="getCategoryIcon(category)" :alt="category" class="category-icon" />
          <div class="category-name">{{ category }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import hot from '../../assets/icons/hot_16435354 1.svg';
import chip from '../../assets/icons/chip_612366 1.svg';
import moneyBag from '../../assets/icons/money_bag_2976814 1.svg';
import slotMachine from '../../assets/img/sidebar/casino-icon.svg';
import famous from '../../assets/icons/famous_8641360 1.svg';
import heart from '../../assets/icons/heart_9465449 1.svg';
import gameController from '../../assets/icons/game_controller_9356342 1.svg';
import roulette from '../../assets/icons/roulette_5597973 1.svg';
import star from '../../assets/icons/star_1.svg';

export default {
  name: "GameCategories",
  props: {
    categories: {
      type: Array,
      required: true
    },
    activeCategory: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      translateX: 0,
      isDragging: false,
      startX: 0,
      currentX: 0,
      maxTranslateX: 0,
      categoryIcons: {
        'All Games': slotMachine,
        'New Release': hot,
        'Originals': chip,
        'Bonus': moneyBag,
        'Live Casino': famous,
        'Casual Games': gameController,
        'Roulette': roulette,
        'Game Shows': star,
      },
      categoryWidth: 105, // Width of each category
      categoryGap: 28, // Gap between categories
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.calculateMaxTranslateX();
      window.addEventListener('resize', this.calculateMaxTranslateX);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.calculateMaxTranslateX);
  },
  methods: {
    calculateMaxTranslateX() {
      const frame = this.$refs.frame;
      if (frame) {
        const frameWidth = frame.offsetWidth;
        const totalWidth = this.categories.length * (this.categoryWidth + this.categoryGap);
        this.maxTranslateX = Math.max(frameWidth - totalWidth, 0);
      }
    },
    startDrag(e) {
      this.isDragging = true;
      this.startX = e.type.includes('mouse') ? e.clientX : e.touches[0].clientX;
      this.currentX = this.translateX;
    },
    drag(e) {
      if (!this.isDragging) return;
      const currentPosition = e.type.includes('mouse') ? e.clientX : e.touches[0].clientX;
      const diff = currentPosition - this.startX;
      this.translateX = Math.max(this.maxTranslateX, Math.min(0, this.currentX + diff));
    },
    endDrag() {
      this.isDragging = false;
      // Optional: Add snapping effect to the nearest category
      const frame = this.$refs.frame;
      const categoryWidthWithGap = this.categoryWidth + this.categoryGap;
      const index = Math.round(-this.translateX / categoryWidthWithGap);
      this.translateX = -index * categoryWidthWithGap;
    },
    selectCategory(categoryName) {
      if (!this.isDragging) {
        this.$emit('category-change', categoryName);
      }
    },
    getCategoryIcon(category) {
      return this.categoryIcons[category] || slotMachine; // Default to slot machine icon if not found
    }
  }
};
</script>

<style scoped>
.frame {
  position: relative;
  overflow: hidden;
  width: 100%;
  touch-action: pan-y;
}

.slider-container {
  display: flex;
  gap: 28px;
  transition: transform 0.3s ease;
}

.category-wrapper {
  flex: 0 0 auto;
  background: linear-gradient(180deg, var(--background-primary) 0%, rgba(33, 42, 67, 0) 100%);
  border-radius: 14px;
  box-shadow: inset 0px 4px 0px var(--background-secondary);
  height: 107px;
  width: 105px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.category-wrapper.active {
  border: 2px solid var(--highlight-pink);
  box-shadow: 0px 0px 6px var(--highlight-pink), 0px 0px 12px var(--highlight-pink);
}

.category-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 10px;
  padding: 0 14px;
}

.category-icon {
  height: 37px;
  width: 37px;
}

.category-name {
  color: #ffffff63;
  font-family: "Montserrat-Medium", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  transition: color 0.3s ease;
}

.category-wrapper.active .category-name {
  color: var(--highlight-pink);
  text-shadow: 0px 0px 6px var(--highlight-pink);
}

@media (max-width: 768px) {
  .frame {
    padding: 0 40px;
  }
}
</style>