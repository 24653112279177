<template>
  <div class="chat-room-dropdown" v-bind:class="{ 'dropdown-open': dropdownOpen === true }">
    <button v-on:click="chatToggleDropdown" class="button-toggle">
      <div class="button-inner">
        <img v-bind:src="require('@/assets/img/rooms/' + chatRoom + '.svg')" alt="room-icon"/>
        <!--{{ chatGetRoomName }}
        <span class="button-online">({{ chatOnline[chatRoom] }})</span>
        <IconDropdown/>-->
      </div>
    </button>
    <div class="dropdown-menu">
      <div class="menu-inner">
        <button v-on:click="chatRoomButton('en')" class="button-room">
          <img src="@/assets/img/rooms/en.svg" alt="room-icon"/>
          <!--ENGLISH
          <span class="button-online">({{ chatOnline.en }})</span>-->
        </button>
        <button v-on:click="chatRoomButton('tr')" class="button-room">
          <img src="@/assets/img/rooms/tr.svg" alt="room-icon"/>
          <!--TURKISH
          <span class="button-online">({{ chatOnline.tr }})</span>-->
        </button>
        <button v-on:click="chatRoomButton('de')" class="button-room">
          <img src="@/assets/img/rooms/de.svg" alt="room-icon"/>
          <!--GERMAN
          <span class="button-online">({{ chatOnline.de }})</span>-->
        </button>
        <button v-on:click="chatRoomButton('es')" class="button-room">
          <img src="@/assets/img/rooms/es.svg" alt="room-icon"/>
          <!--SPANISH
          <span class="button-online">({{ chatOnline.es }})</span>-->
        </button>
        <button v-on:click="chatRoomButton('beg')" class="button-room">
          <img src="@/assets/img/rooms/beg.svg" alt="room-icon"/>
          <!--BEGGING
          <span class="button-online">({{ chatOnline.beg }})</span>-->
        </button>
        <button v-on:click="chatRoomButton('whale')" class="button-room">
          <img src="@/assets/img/rooms/whale.svg" alt="room-icon"/>
          <!--WHALE LOUNGE
          <span class="button-online">({{ chatOnline.whale }})</span>-->
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import IconDropdown from '@/components/icons/IconDropdown';

export default {
  name: 'ChatRoomDropdown',
  components: {
    IconDropdown
  },
  data() {
    return {
      dropdownOpen: false
    };
  },
  methods: {
    ...mapActions(['chatSetRoom']),
    chatToggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    chatRoomButton(room) {
      this.chatSetRoom(room);
      this.chatToggleDropdown();
    }
  },
  computed: {
    ...mapGetters(['chatRoom', 'chatOnline']),
    chatGetRoomName() {
      let name = 'ENGLISH';

      if (this.chatRoom === 'tr') {
        name = 'TURKISH';
      } else if (this.chatRoom === 'de') {
        name = 'GERMAN';
      } else if (this.chatRoom === 'es') {
        name = 'SPANISH';
      } else if (this.chatRoom === 'beg') {
        name = 'BEGGING';
      } else if (this.chatRoom === 'whale') {
        name = 'WHALE LOUNGE';
      }

      return name;
    }
  },
  created() {
    let self = this;
    document.addEventListener('click', function (event) {
      if (!self.$el.contains(event.target) && self.dropdownOpen == true) {
        self.chatToggleDropdown();
      }
    });
  }
};
</script>

<style scoped>
.chat-room-dropdown {
  position: relative;
  z-index: 11;
}

.chat-room-dropdown button.button-toggle {
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 53px;
  position: relative;
}

.chat-room-dropdown button.button-toggle .button-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 800;
  color: white;
  transition: all .2s;
}

.chat-room-dropdown button.button-toggle svg {
  margin-left: 8px;
  fill: white;
  transition: all 0.3s ease;
}

.chat-room-dropdown.dropdown-open button.button-toggle svg {
  transform: rotate(180deg);
}

.chat-room-dropdown button.button-toggle span.button-online {
  margin-left: 5px;
  font-weight: 600;
  color: white;
}

.button-toggle:hover .chat-room-dropdown button.button-toggle span.button-online {
  color: white !important;
}

.chat-room-dropdown .dropdown-menu {
  width: 5rem;
  height: 0;
  position: absolute;
  top: 50px;
  left: -15px;
  right: -15px;
  padding: 0 15px;
  transition: height 0.2s ease;
  overflow: hidden;
  z-index: -1;
}

.chat-room-dropdown.dropdown-open .dropdown-menu {
  height: 361px;
  padding: 0 15px 25px 15px;
}

.chat-room-dropdown .menu-inner {
  width: 100%;
  border-radius: 1rem;
  background-color: var(--background-secondary);
}

.chat-room-dropdown button.button-room {
  width: 100%;
  height: 53px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 600;
  color: white;
}

.chat-room-dropdown button.button-room:last-of-type {
  border-bottom: none;
}

.chat-room-dropdown button.button-room span.button-online {
  margin-left: 5px;
  font-weight: 600;
  color: white;
}
</style>
