<template>
  <div class="menu-container" :class="{ 'collapsed': isCollapsed }">
    <div class="menu-scroll-container">

      <div class="buttons-container">
        <button @click="navigateTo('/apecandy')">
          <div class="meme-button">
            <span>MEME</span>
            <div class="container-lolypop">
              <img src="@/assets/img/sidebar/lolypop-5.png" alt="">
            </div>
          </div>
        </button>

        <div class="main-buttons">
          <button 
            class="casino-button" 
            :class="{ 'active': $route.path.includes('games') }"
            @click="navigateToGames"
          >
            <div class="button-data">
              <img src="@/assets/img/sidebar/casino-icon.svg" alt="Casino"/>
              <span>CASINO</span>
            </div>
          </button>

          <button 
            class="sport-button" 
            :class="{ 'active': $route.path.includes('sports') }"
            @click="navigateTo('/sports', 'sports')"
          >
            <div class="button-data">
              <img src="@/assets/img/sidebar/basketball-ball.svg" alt="Sport"/>
              <span>SPORT</span>
            </div>
          </button>
        </div>

        <div class="search-container">
          <img src="@/assets/img/icons/search.svg" alt="Search" class="search-icon"/>
          <input 
            type="text"
            v-model="searchTerm"
            placeholder="Search"
            class="search-input"
          />
        </div>
        


      <!-- Menu items -->
        <div v-for="item in filteredItems" :key="item.id">
          <hr v-if="item.id === 'hr'" class="custom-hr"/>
          <div v-else-if="item.id === 'casino'" class="casino-menu-item">
            <button @click="navigateToGames">
              <div class="button-data">
                <img :src="require('@/assets/img/sidebar/'+item.id+'-icon.svg')"/>
                <span v-if="!isCollapsed">{{ item.name }}</span>
              </div>
            </button>
            <img
                v-if="!isCollapsed"
                class="arrow"
                src="@/assets/img/icons/right-arrow.svg"
                @click="toggleCasinoSubmenu"
                :class="{ 'rotated': showCasinoSubmenu }"
            />
          </div>
          <div v-else-if="item.id === 'casino-submenu' && showCasinoSubmenu && !isCollapsed" class="casino-submenu">
            <button
                v-for="category in gameCategories"
                :key="category.name"
                @click="selectGameCategory(category.name)"
                class="submenu-item"
            >
              <img :src="category.icon" :alt="category.name"/>
              <span>{{ category.name }}</span>
            </button>
          </div>
          <div v-else-if="item.id === 'original-games'" class="original-games-menu-item">
            <button @click="navigateToOriginalGames">
              <div class="button-data">
                <img src="@/assets/icons/star_1.svg"/>
                <span v-if="!isCollapsed">{{ item.name }}</span>
              </div>
            </button>
            <img
                v-if="!isCollapsed"
                class="arrow"
                src="@/assets/img/icons/right-arrow.svg"
                @click="toggleOriginalGamesSubmenu"
                :class="{ 'rotated': showOriginalGamesSubmenu }"
            />
          </div>
          <div v-else-if="item.id === 'original-games-submenu' && showOriginalGamesSubmenu && !isCollapsed"
               class="original-games-submenu">
            <button
                v-for="game in originalGames"
                :key="game.id"
                @click.stop="navigateTo(game.link)"
                class="submenu-item"
            >
              <img src="@/assets/icons/star_1.svg" :alt="game.name"/>
              <span>{{ game.name }}</span>
            </button>
          </div>
          <button v-else-if="['leaderboard', 'affiliates', 'rewards'].includes(item.id)" @click="navigateTo(item.id)">
            <div class="button-data">
              <img :src="getIconForItem(item.id)" :alt="item.name"/>
              <span v-if="!isCollapsed">{{ item.name }}</span>
            </div>
            <img v-if="!isCollapsed" class="arrow" src="@/assets/img/icons/right-arrow.svg"/>
          </button>
          <button v-else-if="item.id === 'livesupport'" @click="showLiveSupport">
            <div class="button-data">
              <img :src="require('@/assets/img/sidebar/'+item.id+'-icon.svg')"/>
              <span v-if="!isCollapsed">{{ item.name }}</span>
            </div>
            <img v-if="!isCollapsed" class="arrow" src="@/assets/img/icons/right-arrow.svg"/>
          </button>
          <button v-else-if="item.id === 'apecandy'" @click="navigateTo(item.route)">
            <div class="button-data">
              <img src="@/assets/icons/hot_16435354 1.svg" alt="Apecandy Icon"/>
              <span v-if="!isCollapsed">{{ item.name }}</span>
            </div>
            <img v-if="!isCollapsed" class="arrow" src="@/assets/img/icons/right-arrow.svg"/>
          </button>
          <button v-else @click="navigateTo(item.route, item.id)">
            <div class="button-data">
              <img v-if="item.id !== 'casino-submenu'" :src="require('@/assets/img/sidebar/'+item.id+'-icon.svg')"/>
              <span v-if="!isCollapsed">{{ item.name }}</span>
            </div>
            <img v-if="!isCollapsed" class="arrow" src="@/assets/img/icons/right-arrow.svg"/>
          </button>
        </div>
      </div>
    </div>

    <!-- Toggle button at the bottom -->
    <button @click="toggleCollapse" class="toggle-button">
      <img :src="require('@/assets/icons/right-arrow_12857695 1.svg')" :class="{ 'rotated': !isCollapsed }"/>
    </button>
  </div>
</template>

<script>
import {mapMutations} from 'vuex';

export default {
  name: 'Menu',
  data() {
    return {
      searchTerm: '',
      showCasinoSubmenu: false,
      isCollapsed: false,
      showOriginalGamesSubmenu: false,
      items: [
        {id: "casino", name: "Casino", route: "/games"},
        {id: "original-games", name: "Original Games", route: "/original-games"},
        {id: "sports", name: "Sports", route: "/sports"},
        {id: "esports", name: "ESports", route: "/esports"},
        {id: "apecandy", name: "Apecandy", route: "/apecandy"},
        //{id: "racing", name: "Racing", route: "/racing"},
        //{id: "bonuswar", name: "Bonus War", route: "/bonuswar"},
        // {id: "cryptotrading", name: "Crypto Trading", route: "/cryptotrading"},
        //{id: "earn", name: "Earn", route: "/earn"},
        {id: "poker", name: "Poker", route: "/poker"},
        {id: "lottery", name: "Lottery", route: "/lottery"},
        {id: "lootbox", name: "Lootbox", route: "/unbox"},
        {id: "leaderboard", name: "Leaderboard", route: "/leaderboard"},
        {id: "affiliates", name: "Affiliates", route: "/affiliates"},
        {id: "rewards", name: "Rewards", route: "/rewards"},
        {id: "livesupport", name: "Live Support", route: "/support"},
        {id: "language", name: "Language", route: "/language"},
        

      ],
      gameCategories: [
        {name: 'All', icon: require('@/assets/img/sidebar/casino-icon.svg')},
        {name: 'New Release', icon: require('@/assets/icons/hot_16435354 1.svg')},
        {name: 'Originals', icon: require('@/assets/icons/chip_612366 1.svg')},
        {name: 'Bonus', icon: require('@/assets/icons/money_bag_2976814 1.svg')},
        {name: 'Live Casino', icon: require('@/assets/icons/famous_8641360 1.svg')},
        {name: 'Buy Bonus', icon: require('@/assets/icons/heart_9465449 1.svg')},
        {name: 'Casual Games', icon: require('@/assets/icons/game_controller_9356342 1.svg')},
        {name: 'Roulette', icon: require('@/assets/icons/roulette_5597973 1.svg')},
        {name: 'Game Shows', icon: require('@/assets/icons/star_1.svg')}
      ],
      originalGames: [
        {id: 1, name: 'Mines', link: '/mines'},
        {id: 2, name: 'Crash', link: '/crash'},
        {id: 3, name: 'Upgrader', link: '/upgrader'},
        {id: 4, name: 'Blackjack', link: '/blackjack'},
        {id: 5, name: 'Cases', link: '/unbox'},
        {id: 6, name: 'Towers', link: '/towers'},
        {id: 7, name: 'Roulette', link: '/roll'},
        {id: 8, name: 'Case Battle', link: '/battles'},
        {id: 9, name: 'Duels', link: '/duels'}
      ]
    };
  },
  computed: {
    filteredItems() {
      const term = this.searchTerm.toLowerCase();
      let filtered = this.items.filter(item => item.name.toLowerCase().includes(term));

      // Insert casino submenu after casino item if it's expanded
      const casinoIndex = filtered.findIndex(item => item.id === 'casino');
      if (casinoIndex !== -1 && this.showCasinoSubmenu) {
        filtered.splice(casinoIndex + 1, 0, {id: 'casino-submenu', name: 'Casino Submenu'});
      }

      const separatorIndex = filtered.findIndex(item => item.id === 'lottery');
      if (separatorIndex !== -1) {
        filtered.splice(separatorIndex + 1, 0, {id: 'hr', name: 'hr'});
      }

      const originalGamesIndex = filtered.findIndex(item => item.id === 'original-games');
      if (originalGamesIndex !== -1 && this.showOriginalGamesSubmenu) {
        filtered.splice(originalGamesIndex + 1, 0, {id: 'original-games-submenu', name: 'Original Games Submenu'});
      }

      return filtered;
    }
  },
  methods: {
    ...mapMutations('slotegrator', ['SET_ACTIVE_CATEGORY']),
    navigateToGames() {
      if (this.$route.path !== '/games') {
        this.$router.push('/games').catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            throw err;
          }
        });
      }
    },
    toggleCasinoSubmenu() {
      this.showCasinoSubmenu = !this.showCasinoSubmenu;
    },
    selectGameCategory(categoryName) {
      this.SET_ACTIVE_CATEGORY(categoryName);
      this.$router.push('/games');
    },
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
      // Emit an event when the sidebar is toggled
      this.$emit('sidebar-toggle', this.isCollapsed);
    },
    navigateTo(route, itemId) {
      if (itemId === 'sports') {
        this.isCollapsed = true;
        this.$emit('sidebar-toggle', true);
      }
      if (this.$route.path !== route) {
        this.$router.push(route).catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            throw err;
          }
        });
      }
    },
    toggleOriginalGamesSubmenu() {
      this.showOriginalGamesSubmenu = !this.showOriginalGamesSubmenu;
    },
    navigateToOriginalGames() {
      if (this.$route.path !== '/original-games') {
        this.$router.push('/original-games').catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            throw err;
          }
        });
      }
    },
    showLiveSupport() {
      // Show Intercom messenger
      if (window.Intercom) {
        window.Intercom('show');
      }
    },
    getIconForItem(itemId) {
      switch (itemId) {
        case 'leaderboard':
          return require('@/assets/img/sidebar/leaderboard-icon.svg');
        case 'affiliates':
          return require('@/assets/img/sidebar/affiliates-icon.svg');
        case 'rewards':
          return require('@/assets/img/sidebar/reward-icon.svg');
        case 'profile':
          return require('@/assets/img/sidebar/profile-icon.svg');
        default:
          return require('@/assets/img/sidebar/' + itemId + '-icon.svg');
      }
    }
  },
  mounted() {
    // Hide Intercom messenger by default
    if (window.Intercom) {
      window.Intercom('update', {"hide_default_launcher": true});
    }
  }
};
</script>

<style scoped>
.menu-container {
  display: flex;
  flex-direction: column;
  color: white;
  width: 300px;
  height: 100%;
  transition: all 0.3s ease;
  position: relative;
}

.menu-scroll-container {
  flex-grow: 1;
  overflow-y: auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.menu-container.collapsed {
  width: 60px;
}

.menu-container.collapsed .menu-scroll-container {
  max-height: 95%;
  padding: 1rem 0.5rem;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.menu-scroll-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.menu-scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.toggle-button {
  position: sticky;
  bottom: 20px;
  align-self: center;
  background: var(--highlight-pink);
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 10;
  transition: all 0.3s ease;
}

.toggle-button img {
  width: 15px;
  height: 15px;
  transition: transform 0.3s ease;
}

.toggle-button img.rotated {
  transform: rotate(180deg);
}

.buttons-container {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  padding: 0.5rem;
}

.collapsed .buttons-container {
  gap: 2rem;
  padding: 0;
}

.buttons-container button {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}


.buttons-container .button-data {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: rgba(255, 255, 255, 0.50);
  font-family: Montserrat, sans-serif;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.buttons-container .button-data img {
  height: 1.5rem;
}

.collapsed .buttons-container .button-data {
  justify-content: center;
}

.collapsed .buttons-container .button-data img {
  height: 1.75rem;
}

.bonus-container {
  width: 100%;
  display: flex;
  border-radius: 0.625rem;
  background: linear-gradient(90deg, rgba(var(--highlight-pink-rgb), 0.50) 0%, rgba(0, 0, 0, 0.00) 89.35%);
  padding: 0.8rem;
  justify-content: space-between;
  align-items: center;
  color: white;
}

.left-content {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.left-content img {
  height: 2.5rem;
  transform: scaleX(-1);
  background-color: rgba(255, 255, 255, 0.08);
  padding: 0.3rem;
  border-radius: 0.4rem;
}

.left-content span {
  font-family: Montserrat, sans-serif;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.right-content {
  display: flex;
  gap: 1rem;
}

.right-content img {
  opacity: 0.2;
}

.right-content div {
  display: flex;
  flex-direction: column;
}

.right-content div span:nth-child(1) {
  color: var(--highlight-aqua);
  font-family: Montserrat, sans-serif;
  font-size: 0.8rem;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
}

.right-content div span:nth-child(2) {
  color: #0075FF;
  font-family: Montserrat, sans-serif;
  font-size: 0.8rem;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
}

.collapsed .bonus-container {
  padding: 0.4rem;
  justify-content: center;
}

.collapsed .left-content img {
  height: 2rem;
}

/* Hide text elements when collapsed */
.collapsed .left-content span,
.collapsed .right-content,
.collapsed .category,
.collapsed .buttons-container span {
  display: none;
}

/* Adjust other styles to accommodate collapsed state */
.collapsed .buttons-container button {
  justify-content: center;
}

.collapsed .button-data {
  justify-content: center;
}

.collapsed .arrow {
  display: none;
}

.menu-container .category {
  font-family: Montserrat, sans-serif;
  font-size: 0.85rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 1rem;
}

.custom-hr {
  border: 0;
  border-top: 2px solid rgba(255, 255, 255, 0.08);
  margin: 0.5rem 0;
}

.buttons-container button .arrow {
  opacity: 0.4;
}

.search-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0.5rem 0.75rem;
  gap: 0.75rem;
  border-radius: 0.625rem;
  border: 2px solid rgba(255, 255, 255, 0.08);
}

.search-box img {
  height: 1.75rem;
}

.search-box input {
  width: 100%;
  height: 100%;
  background-color: transparent;
  outline: none;
  font-family: Montserrat, sans-serif;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: rgba(255, 255, 255, 0.39);
}

.casino-menu-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.casino-submenu {
  margin-left: 20px;
  width: 100%;
}

.casino-submenu .submenu-item {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 5px;
  width: 100%;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 5px 0;
  text-align: left;
  justify-content: flex-start; /* Align content to the left */
  
}

.casino-submenu .submenu-item img {
  width: 20px;
  height: 20px;
  flex-shrink: 0; /* Prevent icon from shrinking */
}

.casino-submenu .submenu-item span {
  color: rgba(255, 255, 255, 0.50);
  font-family: Montserrat, sans-serif;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left; /* Ensure text is left-aligned */
}

.arrow.rotated {
  transform: rotate(90deg);
}

/* Ensure all menu items are left-aligned */
.buttons-container button,
.casino-menu-item {
  justify-content: flex-start;
}

.buttons-container button .arrow,
.casino-menu-item .arrow {
  margin-left: auto; /* Push arrow to the right */
}

/* Adjust main menu items to match submenu layout */
.buttons-container .button-data {
  width: 100%;
  justify-content: flex-start;
}

.original-games-menu-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.original-games-submenu {
  margin-left: 20px;
  width: 100%;
}

.original-games-submenu .submenu-item {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 5px;
  width: 100%;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 5px 0;
  text-align: left;
  justify-content: flex-start;
}

.original-games-submenu .submenu-item img {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.original-games-submenu .submenu-item span {
  color: rgba(255, 255, 255, 0.50);
  font-family: Montserrat, sans-serif;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
}

@media (max-width: 1175px) {
  .menu-container {
    width: 60px;
    padding: 1rem 0.5rem;
  }

  .menu-container .search-box,
  .menu-container .bonus-container,
  .menu-container .category,
  .menu-container .buttons-container span,
  .menu-container .buttons-container .arrow {
    display: none;
  }

  .buttons-container {
    gap: 2rem;
    padding: 0;
  }

  .buttons-container button {
    justify-content: center;
  }

  .buttons-container .button-data {
    justify-content: center;
  }

  .buttons-container .button-data img {
    height: 1.75rem;
  }

  .toggle-button {
    position: relative;
    bottom: auto;
    margin-top: 20px;
  }
}

@media (max-width: 768px) {
  .menu-container {
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: space-around;
    padding: 0.5rem;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: var(--background-primary);
    z-index: 1000;
  }

  .menu-scroll-container {
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    padding: 0;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .buttons-container {
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    gap: 0;
  }

  .buttons-container button {
    flex: 1;
    max-width: 60px;
  }

  .buttons-container .button-data img {
    height: 1.5rem;
  }

  .toggle-button {
    display: none; /* Hide the toggle button on mobile */
  }
}


.meme-button {
    color: white;
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background: linear-gradient(90deg, #FC20AD 0%, #DF20FC 50%, #FC20AD 100%);
    position: relative;
    overflow: visible
  } 
  
  .meme-button span {
    font-size: 18px;
    line-height: 21.6px;
    font-weight: 500;
    position: relative;
  }

  .container-lolypop {
    display: flex;
    justify-content: center;
    width: 70px;
    height: 70px;
    position: absolute;
    overflow-y: hidden;
    bottom: 0;
    right: 0;
  }
  
  .meme-button img {
    object-fit: contain;
    position: absolute;
    width: 39.14px;
    height: 83.46px;
    top: 0;
    transform: rotate(-21.84deg);
  }

  .collapsed .meme-button {
    display: none;
  }

  .main-buttons {
          display: flex;
          gap: 8px;
          /* margin: 12px 0; */
        }

        .casino-button, .sport-button {
          flex: 1;
          height: 50px;
          border-radius: 8px;
          border: none;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          font-size: 16px;
          font-weight: 500;
          padding: 12px;
        }

        .casino-button {
          background: rgba(45, 50, 59, 0.5);
          border: 1px solid transparent;
        }

        .sport-button {
          background: rgba(45, 50, 59, 0.5);
          border: 1px solid transparent;
        }

        .casino-button.active {
          background: rgba(252, 32, 173, 0.1);
          border: 1px solid #FC20AD;
        }

        .sport-button.active {
          background: rgba(252, 32, 173, 0.1);
          border: 1px solid #FC20AD;
          color: white;
        }

        .casino-button.active .button-data span {
          color: white;
        }

        .sport-button.active .button-data span {
          color: white;
        }

        .button-data {
          display: flex;
          align-items: center;
          gap: 8px;
        }

        .button-data img {
          width: 20px;
          height: 20px;
        }

        .collapsed .main-buttons {
          display: none;
        }

        /* .main-buttons .button-data img {
          
        } */

        .search-container {
          display: flex;
          align-items: center;
          gap: 8px;
          margin: 12px 0;
          width: 100%;
          height: 40px;
          background: rgba(45, 50, 59, 0.5);
          border: none;
          border-radius: 8px;
          padding: 0 16px;
          color: white;
          font-size: 14px;
        }

        .collapsed .search-container {
          display: none;
        }
        .search-input {
          background: transparent;
          width: 100%;
          height: 100%;
          border: none;
          color: white;
          font-size: 14px;
        }

        .search-input::placeholder {
          color: rgba(255, 255, 255, 0.5);
        }

        .search-icon {
          right: 16px;
          top: 50%;
          height: 18px;
          width: 18px;
        }


</style>