import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

const sportsbookService = {
  async getJwtToken(userId) {
    try {
      const response = await axios.get(`${API_URL}/api/sportsbook/auth/get-jwt-token`, {
        params: { userId },
        withCredentials: true
      });
      console.log('JWT Token received:', response.data.token);
      return response.data.token;
    } catch (error) {
      console.error('Error getting JWT token:', error);
      throw error;
    }
  },
};

export default sportsbookService;
