<template>
  <div class="modal-login">
    <div class="login-sidebar">
      <img src="@/assets/img/candy-casino-logo.svg"/>
      <div class="sidebar-text">
        <span class="gradient-pink">WELCOME TO THE SWEETEST CASINO</span>
      </div>
      <img v-if="['login', 'register', 'roblox login'].includes(modalTab) === true"
           src="@/assets/img/candy/pink-lollipop.png"/>
    </div>
    <div class="login-content">
      <div class="content-title">
        <span class="gradient-pink">{{ modalTab === 'forgot' ? 'RESET PASSWORD' : modalTab.toUpperCase() }}</span>
      </div>
      <div v-if="['login', 'register', 'roblox login', 'roblox cookie'].includes(modalTab) === true"
           class="content-auth">
        <LoginRoblox v-if="['roblox login', 'roblox cookie'].includes(modalTab) === true" v-bind:tab="modalTab"/>
        <LoginCredentials v-else v-bind:tab="modalTab"/>

        <!--<div class="auth-seperator">OR SIGN IN USING</div>
        <button v-if="['roblox login', 'roblox cookie'].includes(modalTab) === true" v-on:click="modalSetTab('login')"
                class="button-credentials">
          <div class="button-inner">Sign in with Email</div>
        </button>
        <button v-else v-on:click="modalSetTab('roblox login')" class="button-roblox">
          <div class="button-inner">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M4 0L0 16L15.5 20L20 4L4 0ZM7.5 11L8.5 8L12 9L11 12L7.5 11Z"/>
            </svg>
            Sign in with Growtopia
          </div>
        </button>-->
      </div>
      <LoginForgot v-else/>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex';
import LoginCredentials from '@/components/login/LoginCredentials';
import LoginRoblox from '@/components/login/LoginRoblox';
import LoginForgot from '@/components/login/LoginForgot';

export default {
  name: 'ModalLogin',
  components: {
    LoginCredentials,
    LoginRoblox,
    LoginForgot
  },
  data() {
    return {
      modalTab: 'login'
    }
  },
  methods: {
    ...mapActions([
      'modalsSetShow'
    ]),
    modalSetTab(tab) {
      this.modalTab = tab;
    },
    modalTermsButton() {
      this.modalsSetShow(null);
      setTimeout(() => {
        this.modalsSetShow('Terms');
      }, 300);
    }
  }
}
</script>

<style scoped>
.modal-login {
  width: 1010px;
  border-radius: 15px;
  background: var(--background-secondary);
}

.modal-login .login-sidebar {
  width: 237px;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 0;
  left: 0;
  border-radius: 15px 0 0 15px;
  background: var(--background-primary);
}

.modal-login .login-sidebar img:nth-of-type(1) {
  width: 143px;
  margin-top: 45px;
}

.modal-login .login-sidebar img:nth-of-type(2) {
  width: 300px;
  clip-path: inset(0 0 6rem 0);
  transform: translateY(-2.3rem);
}

.modal-login .sidebar-text {
  width: 100%;
  position: relative;
  margin-top: 42px;
  padding: 15px 30px;
  text-align: center;
  background: var(--highlight-pink);
  color: white;
  font-family: Montserrat, sans-serif;
  font-weight: 800;
  font-size: 1.1rem;
}

.modal-login .login-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px 110px 48px 347px;
}

.modal-login .content-title {
  text-align: center;
  font-size: 32px;
  font-weight: 900;
  color: white;
}

.modal-login .content-auth {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
}

.modal-login .auth-seperator {
  width: 530px;
  position: relative;
  margin-top: 35px;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  color: var(--highlight-white);;
}

.modal-login .auth-seperator:before,
.modal-login .auth-seperator:after {
  content: '';
  width: calc(50% - 110px);
  height: 1px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  background: #6d1869;
  opacity: 0.5;
}

.modal-login .auth-seperator:before {
  left: 0;
}

.modal-login .auth-seperator:after {
  right: 0;
}

.modal-login button.button-credentials,
.modal-login button.button-roblox {
  width: 100%;
  height: 54px;
  margin-top: 35px;
  transition: all;
  transition-duration: 0.2s;
}

.button-credentials:hover, .button-roblox:hover {
  opacity: 0.8;
  scale: 0.99;
}

.button-credentials {

}

.modal-login button.button-credentials .button-inner,
.modal-login button.button-roblox .button-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  background: #631a5f;
  clip-path: polygon(11px 0, calc(100% - 11px) 0, 100% 25%, 100% 75%, calc(100% - 11px) 100%, 11px 100%, 0 75%, 0 25%);
}

.modal-login button.button-roblox .button-inner {
  background: #ca419f;
}

.modal-login button.button-roblox .button-inner svg {
  margin-right: 14px;
  fill: #ffffff;
}

@media only screen and (max-width: 1030px) {

  .modal-login {
    width: calc(100vw - 20px);
  }

}

@media only screen and (max-width: 950px) {

  .modal-login .login-sidebar {
    display: none;
  }

  .modal-login .login-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 85px 15px 48px 15px;
  }

}

@media only screen and (max-width: 560px) {

  .modal-login .auth-seperator {
    width: 100%;
  }

}
</style>
