<template>
  <button v-on:click="crashInfoButton" class="crash-history-element" v-bind:class="{
        'element-high': crashGetOutcome >= 10,
        'element-mid': crashGetOutcome < 10 && crashGetOutcome >= 2
    }">
    <div class="element-inner">
      <span>{{ crashGetOutcome }}x</span>
    </div>
  </button>
</template>

<script>
import {mapActions} from 'vuex';

export default {
  name: 'CrashHistoryElement',
  props: [
    'game'
  ],
  methods: {
    ...mapActions([
      'modalsSetData',
      'modalsSetShow'
    ]),
    crashInfoButton() {
      this.modalsSetData({game: this.game});
      this.modalsSetShow('FairGame');
    }
  },
  computed: {
    crashGetOutcome() {
      return parseFloat(this.game.outcome / 100).toFixed(2);
    }
  }
}
</script>

<style scoped>
button.crash-history-element {
  width: 70px;
  height: 35px;
  position: relative;
  flex-shrink: 0;
  margin-right: 4px;
  border-radius: 8px;
  background: var(--background-secondary);
  border: 2px solid rgba(255,255,255,0.08);
}

button.crash-history-element:last-of-type {
  margin-right: 0;
}

button.crash-history-element .element-inner {
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 1px;
  left: 1px;
  z-index: 1;
}

button.crash-history-element .element-inner span {
  font-size: 14px;
  font-weight: normal;
  color: var(--highlight-white);
}

button.crash-history-element.element-high .element-inner span {
  background: var(--highlight-yellow);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

button.crash-history-element.element-mid .element-inner span {
  background: var(--highlight-pink);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
</style>
