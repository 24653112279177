<template>
  <GameSlider
    sliderClass="provider-of-week"
    collection="provideroftheweeks"
    providerName="Provider of the Week"
  />
</template>

<script>
import GameSlider from '@/components/GameSlider.vue'

export default {
  name: 'ProviderOfWeekSlider',
  components: {
    GameSlider
  }
}
</script>

<style scoped>
@import '../../styles/gameCard.css';
</style>
