// src/services/sportsbookService.js
import axios from 'axios';

const sportsbookService = {
  async getIntegrationJwtToken() {
    try {
      const response = await axios.get(`${process.env.SERVER_BASE_URL}/api/v1/auth/get-token`, {
          headers: {
              'X-Brand': process.env.VUE_APP_SPORTSBOOK_BRAND,
              'X-Operator-Id': process.env.VUE_APP_SPORTSBOOK_OPERATOR_ID
          }
      });
      return response.data.token;
  } catch (error) {
      console.error('Error getting integration JWT token:', error);
      throw error;
  }
},

  async getWalletBalance(playerId, currencies) {
    try {
      const response = await axios.get(`/api/v2/sportsbook-iframe/wallet/${playerId}/balances`, {
        params: { currencies: currencies.join(',') },
        headers: {
          'X-Brand': process.env.VUE_APP_SPORTSBOOK_BRAND,
          'X-Operator-Id': process.env.VUE_APP_SPORTSBOOK_OPERATOR_ID
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error getting wallet balance:', error);
      throw error;
    }
  },

  async performWalletTransaction(playerId, transactionData) {
    try {
      const response = await axios.post(`/api/v2/sportsbook-iframe/wallet/${playerId}/transactions`, transactionData, {
        headers: {
          'X-Brand': process.env.VUE_APP_SPORTSBOOK_BRAND,
          'X-Operator-Id': process.env.VUE_APP_SPORTSBOOK_OPERATOR_ID
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error performing wallet transaction:', error);
      throw error;
    }
  },

  async getTransactionById(playerId, transactionId) {
    try {
      const response = await axios.get(`/api/v2/sportsbook-iframe/wallet/${playerId}/transactions/${transactionId}`, {
        headers: {
          'X-Brand': process.env.VUE_APP_SPORTSBOOK_BRAND,
          'X-Operator-Id': process.env.VUE_APP_SPORTSBOOK_OPERATOR_ID
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error getting transaction by ID:', error);
      throw error;
    }
  }
};

export default sportsbookService;