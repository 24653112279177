<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <AppLoader v-if="generalSettings === null || (authToken !== null && authUser.user === null)" key="loading"/>
      <div
          v-else-if="generalSettings.general.maintenance.enabled === false || (authUser.user !== null && authUser.user.rank === 'admin')"
          class="app-page" key="page">

        <button class="chat-toggle" v-on:click="generalSetSidebarMobile('Chat')">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 5L15 12L8 19" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
          </svg>
        </button>

        <div class="dark-background" v-on:click="generalSetSidebarMobile(null)"
             :class="{'enabled': generalSidebarMobile === 'Chat'}"/>

        <Chat/>
        <Navbar/>

        <div class="content-container" :class="{ 'sidebar-collapsed': isSidebarCollapsed || isSmallScreen }">
          <div class="side-menu" :class="{ 'collapsed': isSidebarCollapsed || isSmallScreen }">
            <Menu @sidebar-toggle="handleSidebarToggle"/>
          </div>
          <main ref="mainContainer" :class="{ 'expanded': isSidebarCollapsed || isSmallScreen }">
            <transition name="slide-fade" mode="out-in">
              <router-view/>
            </transition>
            <Footer/>
          </main>
        </div>

        <Modals/>
        <Notifications/>
      </div>
      <AppMaintenance v-else key="maintenance"/>
    </transition>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import AppLoader from '@/components/AppLoader';
import AppMaintenance from '@/components/AppMaintenance';
import Navbar from '@/components/navbar/Navbar';
import Chat from '@/components/chat/Chat';
import Footer from '@/components/footer/Footer';
import Modals from '@/components/modals/Modals';
import Notifications from '@/components/notifications/Notifications';
import Menu from '@/components/menu/Menu';

window.intercomSettings = {
  app_id: process.env.VUE_APP_INTERCOM_APP_ID
};
(function () {
  var w = window;
  var ic = w.Intercom;
  if (typeof ic === "function") {
    ic('reattach_activator');
    ic('update', w.intercomSettings);
  } else {
    var d = document;
    var i = function () {
      i.c(arguments);
    };
    i.q = [];
    i.c = function (args) {
      i.q.push(args);
    };
    w.Intercom = i;
    var l = function () {
      var s = d.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://widget.intercom.io/widget/process.env.VUE_APP_INTERCOM_APP_ID';
      var x = d.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
    };
    if (w.attachEvent) {
      w.attachEvent('onload', l);
    } else {
      w.addEventListener('load', l, false);
    }
  }
})();

export default {
  components: {
    AppLoader,
    AppMaintenance,
    Navbar,
    Chat,
    Footer,
    Modals,
    Notifications,
    Menu
  },
  data() {
    return {
      isSidebarCollapsed: false,
      isSmallScreen: false
    };
  },
  methods: {
    ...mapActions(['socketConnectGeneral', 'generalSetSidebarMobile']),
    handleSidebarToggle(isCollapsed) {
      this.isSidebarCollapsed = isCollapsed;
    },
    checkScreenSize() {
      this.isSmallScreen = window.innerWidth <= 1175;
    }
  },
  computed: {
    ...mapGetters(['socketGeneral', 'generalSettings', 'generalSidebarMobile', 'authToken', 'authUser']),
    appGetRouteName() {
      return this.$route.name;
    },
    appHasBackground() {
      let background = true;

      if (['ProfileTransactions', 'ProfileGames', 'ProfileSettings', 'UnboxOverview', 'UnboxBox', 'BattlesOverview', 'BattlesCreate', 'BattlesGame', 'CashierLimiteds'].includes(this.appGetRouteName) === true) {
        background = false;
      }

      return background;
    }
  },
  watch: {
    '$route': {
      handler(to, from) {
        if (this.$refs.mainContainer !== undefined) {
          this.$nextTick(() => {
            const mainContainer = this.$refs.mainContainer;
            mainContainer.scrollTo({top: 0, behavior: 'smooth'});
          });
        }
      }
    }
  },
  created() {
    this.socketConnectGeneral();
  },
  mounted() {
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
    // Hide Intercom messenger by default
    if (window.Intercom) {
      window.Intercom('update', {"hide_default_launcher": true});
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkScreenSize);
  }
};
</script>

<style>
.dark-background {
  height: 100vh;
  width: 100vw;
  position: fixed;
  background-color: transparent;
  transition: background-color ease-in-out 0.3s;
  pointer-events: none;
  z-index: 70;
}

.dark-background.enabled {
  pointer-events: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.chat-toggle {
  position: absolute;
  top: 50%;
  transform: translateY(50%);
  right: 0;
  z-index: 100;
  color: white;
  background-color: var(--highlight-pink);
  padding: 0.75rem;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.chat-toggle svg {
  transform: scaleX(-1);
}

body .app-loader.fade-leave-active {
  transition: opacity 0.5s ease;
}

body .app-loader.fade-leave-active {
  opacity: 0;
}

body .content-container {
  position: fixed;
  top: 125px;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(100% - 125px);
  transition: all 0.3s ease;
}

body .side-menu {
  width: 300px;
  height: calc(100vh - 125px);
  transition: all 0.3s ease;
}

body .side-menu.collapsed {
  width: 60px;
}

body .app-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

body .app-page.fade-enter-active,
body .app-maintenance.fade-enter-active {
  transition: opacity 0.5s ease;
}

body .app-page.fade-enter-from,
body .app-maintenance.fade-enter-from {
  opacity: 0;
}

body .app-page main {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  border-radius: 1.5rem 1.5rem 0 0;
  background-color: var(--background-secondary);
  transition: all 0.3s ease;
}

/*
body .app-page main.main-background {
    background-image: url('~@/assets/img/background.png');
    background-repeat: no-repeat;
    background-position: center 35px;
}
*/

body .app-page main::-webkit-scrollbar-track {
  background-color: transparent;
}

body .app-page main::-webkit-scrollbar-thumb {
  background-color: transparent;
}

body .app-page main::-webkit-scrollbar {
  width: 0;
  height: 0;
}

body .app-page main .slide-fade-enter-active {
  transition: all .3s ease-out;
}

body .app-page main .slide-fade-enter {
  opacity: 0;
}

@media (max-width: 1175px) {
  .chat-toggle {
    display: none;
  }

  .side-menu {
    display: none;
  }

  body .app-page main,
  body .app-page main.expanded {
    margin-left: 0;
    width: 100%;
  }
}

@media (max-width: 768px) {

  body .content-container{
    top: 100px;
  }

  body .side-menu {
    width: 100%;
    height: auto;
  }

  body .app-page main,
  body .app-page main.expanded {
    margin-left: 0;
    width: 100%;
  }
}
</style>