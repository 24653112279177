<template>
  <div class="crash-controls">
    <!-- <div class="bazed-logo">
      <img src="@/assets/icons/bazed-icon.svg" alt="Bazed Icon" />
      <span>BAZEDSPACE</span>
    </div> -->
    <div class="controls-mode">
      <button
        v-on:click="crashSetMode('manual')"
        v-bind:class="{ 'button-active': crashMode === 'manual' }"
      >
        <img src="@/assets/img/crash/tickets-icon.svg" alt="Ticckets Icon"/>
        <span>Manual</span>
      </button>
      <button
        v-on:click="crashSetMode('auto')"
        v-bind:class="{ 'button-active': crashMode === 'auto' }"
      >
        Auto
      </button>
    </div>
    <div style="height: 2px; background-color: #ffffff14;"></div>

    <div style="margin-top: 20px;">
      <span class="controls-amount-title">Number of tickets</span>
    </div>
      <div class="controls-amount-wrapper">
        <div class="dollar-icon-wrapper">
          <img src="@/assets/img/crash/crashControls/dollar-icon.svg" alt="Dollar Icon" class="dollar-icon"/>
        </div>
        
      <input 
        v-model="crashAmount"
        v-on:input="crashValidateInput"
        v-on:change="crashFormatInput"
        type="text"
        v-bind:disabled="crashAutoRunning === true"
      />
      <div class="cashout-buttons">
        <button
          v-on:click="crashSetInput('crashAmount', '1/2')"
          v-bind:disabled="crashAutoRunning === true"
        >
          <div class="button-inner">1/2x</div>
        </button>
        <button
          v-on:click="crashSetInput('crashAmount', '2x')"
          v-bind:disabled="crashAutoRunning === true"
        >
          <div class="button-inner">2x</div>
        </button>
        <button
          v-on:click="crashSetInput('crashAmount', 'max')"
          class="button-max"
          v-bind:disabled="crashAutoRunning === true"
        >
          <div class="button-inner">MAX</div>
        </button>
      </div>
    </div>
    <!-- <div class="controls-amount">
      <input
        v-model="crashAmount"
        v-on:input="crashValidateInput"
        v-on:change="crashFormatInput"
        type="text"
        placeholder="BET AMOUNT"
        v-bind:disabled="crashAutoRunning === true"
      />
      <div class="cashout-buttons">
        <button
          v-on:click="crashSetInput('crashAmount', '2x')"
          v-bind:disabled="crashAutoRunning === true"
        >
          <div class="button-inner">2x</div>
        </button>
        <button
          v-on:click="crashSetInput('crashAmount', 'max')"
          class="button-max"
          v-bind:disabled="crashAutoRunning === true"
        >
          <div class="button-inner">MAX</div>
        </button>
      </div>
    </div> -->
  <div style="margin-top: 20px;">
    <span class="controls-cashout-title">Auto cashout</span>
  </div>
    <div class="controls-cashout-wrapper">
      <div class="x-icon-wrapper">
           <span>X</span>
      </div>
        <input
        type="text"
        v-model="crashAutoCashout"
        v-bind:disabled="crashAutoRunning === true"
      />
      <div class="cashout-buttons">
        <button
          v-on:click="crashSetInput('crashAutoCashout', '10x')"
          v-bind:disabled="crashAutoRunning === true"
        >
          <div class="button-inner">1/2x</div>
        </button>
        <button
          v-on:click="crashSetInput('crashAutoCashout', '2x')"
          v-bind:disabled="crashAutoRunning === true"
        >
          <div class="button-inner">2x</div>
        </button>
        <button
          v-on:click="crashSetInput('crashAutoCashout', '10x')"
          v-bind:disabled="crashAutoRunning === true"
        >
          <div class="button-inner">10x</div>
        </button>
      </div>
    </div>
    <!-- <div class="controls-cashout">
      <input
        v-model="crashAutoCashout"
        type="text"
        placeholder="AUTO CASHOUT"
        v-bind:disabled="crashAutoRunning === true"
      />
      <div class="cashout-buttons">
        <button
          v-on:click="crashSetInput('crashAutoCashout', '2x')"
          v-bind:disabled="crashAutoRunning === true"
        >
          <div class="button-inner">2x</div>
        </button>
        <button
          v-on:click="crashSetInput('crashAutoCashout', '10x')"
          v-bind:disabled="crashAutoRunning === true"
        >
          <div class="button-inner">10x</div>
        </button>
      </div>
    </div> -->
    <div v-if="crashMode === 'manual'" class="controls-manual">
      <button
        v-if="
          authUser.user !== null &&
          crashGame !== null &&
          crashGame.state !== 'completed' &&
          crashBets.some(
            (element) =>
              element.user._id === authUser.user._id &&
              element.multiplier === undefined,
          ) === true
        "
        v-on:click="crashBetCashout"
        class="button-cashout"
        v-bind:disabled="crashGame.state !== 'rolling'"
      >
        <div class="button-inner">
          {{ crashGame.state !== "rolling" ? "STARTING..." : "CASHOUT" }}
        </div>
      </button>
      <button v-else v-on:click="crashBetButton" class="button-bet">
        <!-- <div class="button-inner"></div> -->
         <img src="@/assets/img/crash/crashControls/coins-01-icon.png" alt="Coin Icon">
        <span>PLACE BET</span>
      </button>


      
    </div>
    <div v-else class="controls-auto">
      <div class="auto-adjust">
        <div class="adjust-win">
          <input
            v-model="crashAutoPercentageWin"
            type="text"
            placeholder="% ON WIN"
            v-bind:disabled="crashAutoRunning === true"
          />
        </div>
        <div class="adjust-lose">
          <input
            v-model="crashAutoPercentageLoss"
            type="text"
            placeholder="% ON LOSS"
            v-bind:disabled="crashAutoRunning === true"
          />
        </div>
      </div>
      <div class="auto-stop">
        <div class="stop-profit">
          <input
            v-model="crashAutoStopProfit"
            type="text"
            placeholder="STOP PROFIT"
            v-bind:disabled="crashAutoRunning === true"
          />
          <img src="@/assets/img/icons/coin.svg" alt="icon" />
        </div>
        <div class="stop-lose">
          <input
            v-model="crashAutoStopLoss"
            type="text"
            placeholder="STOP LOSS"
            v-bind:disabled="crashAutoRunning === true"
          />
          <img src="@/assets/img/icons/coin.svg" alt="icon" />
        </div>
      </div>
      <div class="auto-count">
        <input
          v-model="crashAutoBetCount"
          type="text"
          placeholder="TOTAL BETS"
        />
      </div>
      <button
        v-if="crashAutoRunning === true"
        v-on:click="crashAutoStopButton"
        class="button-stop"
      >
        <div class="button-inner">STOP AUTO BETTING</div>
      </button>
      <button v-else v-on:click="crashAutoStartButton" class="button-auto">
        <div class="button-inner">START AUTO BETTING</div>
      </button>
    </div>

    <div class="candies-wrapper">
      <img src="@/assets/img/crash/crashControls/candie-orange.png" alt="Auto Bet Background" class="candies candie-orange">
      <img src="@/assets/img/crash/crashControls/candies.png" alt="Auto Bet Background" class="candies small-candies">
      <img src="@/assets/img/crash/crashControls/candie-blue.png" alt="Auto Bet Background" class="candies candie-blue">
      <img src="@/assets/img/crash/crashControls/small-candie-orange.png" alt="Auto Bet Background" class="candies small-candie-orange">
      <img src="@/assets/img/crash/crashControls/candies.png" alt="Auto Bet Background" class="candies small-candiesB">
    </div>

  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import IconUsers from "@/components/icons/IconUsers";
import CrashBetElement from "@/components/crash/CrashBetElement";

export default {
  name: "CrashControls",
  components: {
    IconUsers,
    CrashBetElement,
  },
  data() {
    return {
      crashMode: "manual",
      crashAutoRunning: false,
      crashAutoInfinite: false,
      crashAmount: null,
      crashAutoCashout: null,
      crashAutoPercentageWin: null,
      crashAutoPercentageLoss: null,
      crashAutoStopProfit: null,
      crashAutoStopLoss: null,
      crashAutoBetCount: null,
      crashAutoTotalBet: 0,
      crashAutoTotalWon: 0,
    };
  },
  methods: {
    ...mapActions([
      "notificationShow",
      "crashSendBetSocket",
      "crashSendCashoutSocket",
    ]),
    crashFormatValue(value) {
      return parseFloat(Math.floor(value / 10) / 100)
        .toFixed(2)
        .toString();
    },
    crashSetMode(mode) {
      if (mode === "manual") {
        this.crashAutoStopButton();
      }
      this.crashMode = mode;
    },
    crashValidateInput() {
      this.crashAmount = this.crashAmount
        .replace(",", ".")
        .replace(/[^\d.]/g, "");
      this.crashAmount =
        this.crashAmount.indexOf(".") >= 0
          ? this.crashAmount.substr(0, this.crashAmount.indexOf(".")) +
            "." +
            this.crashAmount
              .substr(this.crashAmount.indexOf(".") + 1, 2)
              .replace(".", "")
          : this.crashAmount;
    },
    crashFormatInput() {
      this.crashAmount = Number(this.crashAmount).toFixed(2);
    },
    crashSetInput(value, action) {
      let amount = Math.floor(this[value] * 1000);

      if (action === "2x") {
        amount = Math.floor(amount * 2);
      } else if (action === "10x") {
        amount = Math.floor(amount * 10);
      } else if (action === "max") {
        amount =
          this.authUser.user.balance <= 1000000
            ? this.authUser.user.balance
            : 1000000;
      }

      this[value] = parseFloat(Math.floor(amount / 10) / 100).toFixed(2);
    },
    crashAutoStartButton() {
      const percentageWin = Math.floor(this.crashAutoPercentageWin);
      const percentageLoss = Math.floor(this.crashAutoPercentageLoss);

      if (
        isNaN(percentageWin) === true ||
        percentageWin < 0 ||
        percentageWin > 100
      ) {
        this.notificationShow({
          type: "error",
          message: "Your entered auto bet win percentage is invalid.",
        });
        return;
      }

      if (
        isNaN(percentageLoss) === true ||
        percentageLoss < 0 ||
        percentageLoss > 100
      ) {
        this.notificationShow({
          type: "error",
          message: "Your entered auto bet loss percentage is invalid.",
        });
        return;
      }

      if (isNaN(Math.floor(this.crashAutoStopProfit)) === true) {
        this.notificationShow({
          type: "error",
          message: "Your entered auto bet profit stop is invalid.",
        });
        return;
      }

      if (isNaN(Math.floor(this.crashAutoStopLoss)) === true) {
        this.notificationShow({
          type: "error",
          message: "Your entered auto bet loss stop is invalid.",
        });
        return;
      }

      if (isNaN(Math.floor(this.crashAutoBetCount)) === true) {
        this.notificationShow({
          type: "error",
          message: "Your entered auto bet count is invalid.",
        });
        return;
      }

      if (Math.floor(this.crashAutoBetCount) === 0) {
        this.crashAutoInfinite = true;
      }

      this.crashAutoRunning = true;
      if (this.crashGame.state === "created") {
        this.crashBetButton();
      }
    },
    crashAutoStopButton() {
      this.crashAutoTotalBet = 0;
      this.crashAutoTotalWon = 0;
      this.crashAutoInfinite = false;
      this.crashAutoRunning = false;
    },
    crashBetButton() {
      if (this.socketSendLoading !== null) {
        return;
      }

      if (this.authUser.user === null) {
        this.notificationShow({
          type: "error",
          message: "Please sign in to perform this action.",
        });
        this.crashAutoStopButton();
        return;
      }

      const amount = Math.floor(this.crashAmount * 1000);
      const autoCashout =
        this.crashAutoCashout === null || this.crashAutoCashout.trim() === ""
          ? 0
          : Math.round(this.crashAutoCashout * 100);

      if (amount === null || isNaN(amount) === true || amount <= 0) {
        this.notificationShow({
          type: "error",
          message: "Your entered bet amount is invalid.",
        });
        this.crashAutoStopButton();
        return;
      }

      if (
        autoCashout === null ||
        isNaN(autoCashout) === true ||
        ((autoCashout !== 0 || this.crashMode === "auto") && autoCashout <= 100)
      ) {
        this.notificationShow({
          type: "error",
          message: "Your entered bet auto cashout is invalid.",
        });
        this.crashAutoStopButton();
        return;
      }

      const data = { amount: amount, autoCashout: autoCashout };
      this.crashSendBetSocket(data);
    },
    crashBetCashout() {
      if (this.socketSendLoading !== null) {
        return;
      }

      if (this.authUser.user === null) {
        this.notificationShow({
          type: "error",
          message: "Please sign in to perform this action.",
        });
        return;
      }

      const data = {};
      this.crashSendCashoutSocket(data);
    },
  },
  computed: {
    ...mapGetters(["socketSendLoading", "authUser", "crashGame", "crashBets"]),
    crashGetPlayerCount() {
      let players = [];

      for (const bet of this.crashBets) {
        if (players.includes(bet.user._id) === false) {
          players.push(bet.user._id);
        }
      }

      return players.length;
    },
    crashGetBetsAmount() {
      let amount = 0;

      for (const bet of this.crashBets) {
        amount = Math.floor(amount + bet.amount);
      }

      return amount;
    },
  },
  watch: {
    crashGame: {
      handler(data, dataOld) {
        if (data.state === "created" && this.crashAutoRunning === true) {
          const profit = Math.floor(
            this.crashAutoTotalWon - this.crashAutoTotalBet,
          );

          if (
            (this.crashAutoInfinite === true || this.crashAutoBetCount > 0) &&
            (Math.floor(this.crashAutoStopProfit) === 0 ||
              profit <= 0 ||
              profit < this.crashAutoStopProfit) &&
            (Math.floor(this.crashAutoStopLoss) === 0 ||
              profit >= 0 ||
              Math.abs(profit) < this.crashAutoStopLoss)
          ) {
            this.crashBetButton();
          } else {
            this.crashAutoStopButton();
          }
        }
      },
      deep: true,
    },
    crashBets: {
      deep: true,
      handler(data, dataOld) {
        const index = data.findIndex(
          (element) => element.user._id === this.authUser.user._id,
        );
        if (index !== -1 && this.crashMode === "auto") {
          const bet = data[index];

          if (bet.payout !== undefined) {
            this.crashAutoTotalWon = Math.floor(
              this.crashAutoTotalWon + bet.payout,
            );
          } else {
            if (Math.floor(this.crashAutoBetCount) > 0) {
              this.crashAutoBetCount = this.crashAutoBetCount - 1;
            }
            this.crashAutoTotalBet = Math.floor(
              this.crashAutoTotalBet + bet.amount,
            );
          }
        }
      },
    },
  },
};
</script>

<style scoped>
.crash-controls {
  width: 300px;
  height: 604px;
  border-radius: 15px;
  background-color: var(--background-primary);
  padding: 20px;
  position: relative;
}

.crash-controls .bazed-logo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-bottom: 16px;
}

.crash-controls .bazed-logo img {
  height: 2rem;
}

.crash-controls .bazed-logo span {
  font-family: "Fontspring Gothic Bold", sans-serif;
  font-size: 1.5rem;
  color: #272d3d;
}

.crash-controls .controls-mode {
  width: 100%;
  display: flex;
  background: #242837;
  padding: 4px;
  border-radius: 12px;
  margin-bottom: 20px;
}

.controls-mode button {
  flex: 1;
  padding: 12px;
  border: none;
  background: transparent;
  color: #6C7080;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.3s ease;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.controls-mode button .manual-icon {
  width: 16px;
  height: 16px;
  color: #FF3BFF;
}

.controls-mode button.button-active {
  background: #1A1D2B;
  border-radius: 8px;
  color: white;
}

.controls-mode button::before,
.controls-mode button .button-inner {
  display: none;
}

  /* .crash-controls .controls-amount,
  .crash-controls .controls-cashout {
    width: 100%;
    height: 51px;
    position: relative;
    margin-bottom: 25px;
    margin-top: 10px;
  } */

.controls-amount-title,
.controls-cashout-title {
  font-size: 12px;
  font-weight: 500;
  color: #6C7080;
  margin-top: 20px;
}

.controls-amount-wrapper,
.controls-cashout-wrapper {
  width: 100%;
  height: 51px;
  position: relative;
  border-radius: 8px;
  border: 2px solid rgba(255, 255, 255, 0.08);
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.dollar-icon-wrapper, 
.x-icon-wrapper {
  position: relative;
  height: 100%;
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.controls-amount-wrapper .dollar-icon-wrapper::after,
.controls-cashout-wrapper .x-icon-wrapper::after {
  content: " ";
  width: 2px;
  height: 60%;
  background: #6C7080;
  position: absolute;
  right: 0;
}

.controls-cashout-wrapper .x-icon-wrapper span {
  font-size: 16px; 
  font-weight: 900;

}

.controls-amount-wrapper input,
.controls-cashout-wrapper input {
  color: white;
  font-size: 16px;
  font-weight: medium;
  height: 100%;
  width: 60%;
  background-color: transparent;  
  padding-left: 10px;
   
}

.controls-amount-wrapper img,
.controls-cashout-wrapper span {
  height: 25px;
  position: relative;
}


/* .crash-controls .controls-amount::before,
.crash-controls .controls-cashout::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  z-index: 0;
  border-radius: 10px;
  background: linear-gradient(180deg, #cad9eb, #7e8da5);
} */

.crash-controls .controls-amount input,
.crash-controls .controls-cashout input {
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  position: absolute;
  top: 1px;
  left: 1px;
  padding: 0 90px 0 15px;
  font-size: 14px;
  font-weight: bold;
  color: white;
  background-color: var(--background-primary);
  border-radius: 8px;
  z-index: 1;
  border: 2px solid rgba(255, 255, 255, 0.08);
}

.crash-controls .controls-amount input::placeholder,
.crash-controls .controls-cashout input::placeholder {
  color: rgba(var(--highlight-pink-rgb), 0.5);
}

.crash-controls .amount-buttons,
.crash-controls .cashout-buttons {
  /* position: absolute; */
  display: flex;
  align-items: center;
  height: 100%;
  top: 50%;
  right: 15px;
  /* transform: translate(0, -50%); */
  z-index: 2;
  gap: 4px;
}

.crash-controls .amount-buttons button,
.crash-controls .cashout-buttons button {
  width: 40px;
  height: 32px;
  transition: all 0.2s;
}

.cashout-buttons button:hover {
  transition-duration: 0.1s;
  opacity: 0.8;
}

.cashout-buttons button:active {
  transition-duration: 0.2s;
  scale: 0.95;
}

.crash-controls .amount-buttons button .button-inner,
.crash-controls .cashout-buttons button .button-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 800;
  color: var(--highlight-pink);
  border: 2px solid var(--highlight-pink);
  border-radius: 6px;
}

.crash-controls .controls-amount img {
  width: 19px;
  height: 19px;
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translate(0, -50%);
}

.crash-controls .controls-manual {
  width: 100%;
  margin-top: 24px;
}

/* .crash-controls .controls-manual button.button-cashout::before,
.crash-controls .controls-manual button.button-bet::before {
  content: "";
  position: absolute;       
  top: -6px;
  left: -2px;
  right: -2px;
  bottom: -6px;
  z-index: 0;
  border-radius: 10px;
  background: linear-gradient(180deg, #cad9eb, #7e8da5);
} */

.crash-controls .controls-manual button.button-cashout,
.crash-controls .controls-manual button.button-bet {
  width: 100%;
  height: 47px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--highlight-white);
  background: rgb(252,32,173);
  background: linear-gradient(90deg, rgba(252,32,173,1) 0%, rgba(223,32,252,1) 35%, rgba(252,32,173,1) 100%);
  border-radius: 8px;
}

/* .button-bet:active .button-inner {
  transition-duration: 0.2s;
  scale: 0.95;
} */

.crash-controls .controls-manual button.button-cashout:disabled {
  cursor: not-allowed;
}


.crash-controls .manual-bets::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 12px;
  background: linear-gradient(180deg, #cad9eb, #7e8da5);
}

.crash-controls .manual-bets {
  width: 100%;
  position: relative;
  margin-top: 18px;
  padding: 6px;
  z-index: 0;
}

.crash-controls .manual-bets .manual-bets-wrapper{
  height: 100%;
  border-radius: 12px;
  padding: 12px;
  background: var(--background-primary);
}

.crash-controls .bets-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: var(--highlight-pink);
}

.crash-controls .header-player {
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: 700;
  color: var(--highlight-pink);
  z-index: 0;
}

.crash-controls .header-player svg {
  width: 14px;
  height: 12px;
  margin-right: 8px;
  fill: var(--highlight-pink);
}

.crash-controls .header-player svg path {
  fill: #ac7aa5;
}

.crash-controls .header-player span {
  margin-left: 5px;
  background: var(--highlight-pink);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.crash-controls .header-amount {
  display: flex;
  align-items: center;
  z-index: 0;
}

.crash-controls .header-amount img {
  width: 12px;
  height: 12px;
  margin-right: 8px;
}

.crash-controls .amount-value {
  font-size: 8px;
  font-weight: 600;
  color: #d0bbca;
}

.crash-controls .amount-value span {
  font-size: 12px;
  font-weight: 800;
  color: #ffffff;
}

.crash-controls .bets-content {
  width: 100%;
  height: 50px;
  position: relative;
  margin-top: 10px;
  z-index: 0;
}

.crash-controls .content-list {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scroll-behavior: smooth;
}

.crash-controls .content-list::-webkit-scrollbar-track {
  background-color: transparent;
}

.crash-controls .content-list::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.crash-controls .controls-auto {
  width: 100%;
  margin-top: 20px;
}

.crash-controls .auto-adjust,
.crash-controls .auto-stop {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.crash-controls .auto-stop {
  margin-top: 20px;
}

.crash-controls .adjust-win,
.crash-controls .adjust-lose,
.crash-controls .stop-profit,
.crash-controls .stop-lose,
.crash-controls .auto-count {
  width: calc(50% - 3px);
  height: 51px;
  position: relative;
}

.crash-controls .auto-count {
  width: 100%;
  margin-top: 20px;
}

.crash-controls .adjust-win::before,
.crash-controls .adjust-lose::before,
.crash-controls .stop-profit::before,
.crash-controls .stop-lose::before,
.crash-controls .auto-count::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: var(--background-secondary);
  clip-path: polygon(
    10px 0,
    calc(100% - 10px) 0,
    100% 25%,
    100% 75%,
    calc(100% - 10px) 100%,
    10px 100%,
    0 75%,
    0 25%
  );
}

.crash-controls .adjust-win input,
.crash-controls .adjust-lose input,
.crash-controls .stop-profit input,
.crash-controls .stop-lose input,
.crash-controls .auto-count input {
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  position: absolute;
  top: 1px;
  left: 1px;
  padding: 0 0 0 15px;
  font-size: 12px;
  font-weight: 600;
  color: var(--highlight-white);
  background-color: var(--background-primary);
  clip-path: polygon(
    10px 0,
    calc(100% - 10px) 0,
    100% 25%,
    100% 75%,
    calc(100% - 10px) 100%,
    10px 100%,
    0 75%,
    0 25%
  );
}

.crash-controls .stop-profit input,
.crash-controls .stop-lose input {
  padding: 0 0 0 26px;
}

.crash-controls .adjust-win input::placeholder,
.crash-controls .adjust-lose input::placeholder,
.crash-controls .stop-profit input::placeholder,
.crash-controls .stop-lose input::placeholder,
.crash-controls .auto-count input::placeholder {
  font-size: 11px;
  color: var(--highlight-white);
}

.crash-controls .stop-profit img,
.crash-controls .stop-lose img {
  width: 11px;
  height: 11px;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translate(0, -50%);
}

.crash-controls .controls-auto button.button-auto,
.crash-controls .controls-auto button.button-stop {
  width: 100%;
  height: 50px;
  margin-top: 26px;
  filter: drop-shadow(0px 4px 25px rgba(230, 1, 219, 0.15))
    drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.35));
}

.crash-controls .controls-auto button.button-stop {
  filter: drop-shadow(0px 4px 25px rgba(252, 163, 17, 0.15))
    drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.35));
}

.crash-controls .controls-auto button.button-auto .button-inner,
.crash-controls .controls-auto button.button-stop .button-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 800;
  color: var(--highlight-white);
  background: var(--highlight-pink);
  clip-path: polygon(
    7px 0,
    calc(100% - 7px) 0,
    100% 25%,
    100% 75%,
    calc(100% - 7px) 100%,
    7px 100%,
    0 75%,
    0 25%
  );
}

.crash-controls .controls-auto button.button-stop .button-inner {
  background: linear-gradient(255deg, #fca311 0%, #ffb703 100%);
}

.candies {
  position: absolute;
}

.candie-orange {
  bottom: 45px;
  left: 0;
  width: 100px;
}

.small-candies {
  bottom: 5px;
  left: 5px;
}

.candie-blue {
  bottom: 0;
  left: 85px;
}

.small-candie-orange {
  bottom: 20px;
  right: 0;
}

.small-candiesB {
  bottom: 45px;
  right: 15px;
  transform: rotate(270deg);
}

@media only screen and (max-width: 950px) {
  .crash-controls {
    width: 100%;
    height: auto;
    margin-top: 15px;
  }

  .candies-wrapper {
    display: none;
  }
}
</style>
