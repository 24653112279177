var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bets-element"},[_c('div',{staticClass:"element-game"},[(_vm.bet.method === 'crash')?_c('IconCrash'):(_vm.bet.method === 'roll')?_c('IconRoll'):(_vm.bet.method === 'blackjack')?_c('IconBlackjack'):(_vm.bet.method === 'duels')?_c('IconDuels'):(_vm.bet.method === 'mines')?_c('IconMines'):(_vm.bet.method === 'towers')?_c('IconTowers'):(_vm.bet.method === 'unbox')?_c('IconUnbox'):(_vm.bet.method === 'battles')?_c('IconBattles'):_vm._e(),_c('span',{class:{
            'gradient-yellow': _vm.bet.method === 'crash',
            'gradient-green': _vm.bet.method === 'roll',
            'gradient-blue-dark': _vm.bet.method === 'blackjack',
            'gradient-red': _vm.bet.method === 'duels',
            'gradient-purple': _vm.bet.method === 'mines',
            'gradient-blue': _vm.bet.method === 'towers',
            'gradient-pink': _vm.bet.method === 'unbox',
            'gradient-orange': _vm.bet.method === 'battles'
        }},[_vm._v(_vm._s(_vm.betsGetMethod))])],1),_c('div',{staticClass:"element-user",class:[
        _vm.bet.user === null ? 
            'user-hidden' :
            ['user-' + _vm.betsGetRank(_vm.bet.user), 'user-' + _vm.betsGetLevelColor(_vm.bet.user)]
    ]},[_c('div',{staticClass:"user-avatar"},[_c('AvatarImage',{attrs:{"image":_vm.bet.user === null ? null : _vm.bet.user.avatar}})],1),(_vm.bet.user !== null)?_c('div',{staticClass:"user-username",domProps:{"innerHTML":_vm._s(_vm.bet.user.username)}}):_c('div',[_vm._v("Anonymous")])]),_c('div',{staticClass:"element-time"},[_c('span',[_vm._v(_vm._s(_vm.betsGetDate[0])+",")]),_vm._v(_vm._s(_vm.betsGetDate[1])+" ")]),_c('div',{staticClass:"element-wager"},[_c('img',{attrs:{"src":require("@/assets/img/icons/coin.svg"),"alt":"icon"}}),_c('div',{staticClass:"wager-value"},[_c('span',[_vm._v(_vm._s(_vm.betsFormatValue(_vm.betsGetAmount).split('.')[0]))]),_vm._v("."+_vm._s(_vm.betsFormatValue(_vm.betsGetAmount).split('.')[1])+" ")])]),_c('div',{staticClass:"element-multiplier"},[_c('span',{class:{ 'gradient-yellow': _vm.betsGetMultiplier > 0 }},[_vm._v(_vm._s(parseFloat(_vm.betsGetMultiplier).toFixed(2))+"x")])]),_c('div',{staticClass:"element-payout",class:{ 'payout-positive': (_vm.bet.payout / _vm.betsGetAmount) > 0 }},[_c('img',{attrs:{"src":require("@/assets/img/icons/coin.svg"),"alt":"icon"}}),_c('div',{staticClass:"payout-value"},[_c('span',[_vm._v(_vm._s(_vm.betsFormatValue(_vm.bet.payout).split('.')[0]))]),_vm._v("."+_vm._s(_vm.betsFormatValue(_vm.bet.payout).split('.')[1])+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }