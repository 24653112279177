<template>
  <div class="box-level" v-bind:class="'level-'+color">
    <div class="level-inner">Lv. {{ level }}</div>
  </div>
</template>

<script>
export default {
  name: 'BoxLevel',
  props: ['level', 'color']
};
</script>

<style scoped>
.box-level {
  width: 38px;
  position: relative;
  margin-left: 12px;
}

.box-level.level-blue::before {
  background: linear-gradient(180deg, rgba(6, 36, 61, 0) 0%, #559ee4 100%);
}

.box-level.level-green::before {
  background: linear-gradient(180deg, rgba(6, 36, 61, 0) 0%, #b8e92d 100%);
}

.box-level.level-orange::before {
  background: linear-gradient(180deg, rgba(6, 36, 61, 0) 0%, #fca311 100%);
}

.box-level.level-red::before {
  background: linear-gradient(180deg, rgba(6, 36, 61, 0) 0%, #ff4e4e 100%);
}

.box-level.level-purple::before {
  background: linear-gradient(180deg, rgba(6, 36, 61, 0) 0%, #6953f1 100%);
}

.box-level .level-inner {
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 700;
  color: white;
  z-index: 1;
}

.box-level.level-blue .level-inner {
  color: #559ee4;
  background: rgba(85, 158, 228, 0.05);
}

.box-level.level-green .level-inner {
  color: #b8e92d;
  background: rgba(184, 233, 45, 0.05);
}

.box-level.level-orange .level-inner {
  color: #fca311;
  background: rgba(252, 163, 17, 0.05);
}

.box-level.level-red .level-inner {
  color: #ff4e4e;
  background: rgba(255, 78, 78, 0.05);
}

.box-level.level-purple .level-inner {
  color: #6953f1;
  background: rgba(105, 83, 241, 0.05);
}
</style>