<template>
  <div class="home-banner-news">
    <HomeNewsElement v-for="(banner, index) in homeBanners" v-bind:key="index" v-bind:banner="banner"
                     v-bind:direction="homeDirection" v-bind:position="homePosition" v-bind:index="index"/>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import HomeNewsElement from '@/components/home/HomeNewsElement';

export default {
  name: 'HomeBannerNews',
  components: {
    HomeNewsElement
  },
  data() {
    return {
      homeBanners: ['Rain'],
      homePosition: 0,
      homeDirection: null,
      homeInterval: null
    };
  },
  methods: {
    homeStartInterval() {
      clearInterval(this.homeInterval);
      this.homeInterval = setInterval(() => {
        this.homeNextButton();
      }, 6000);
    },
    homePreviousButton() {
      this.homeDirection = 'left';
      this.homePosition = this.homePosition === 0 ? this.homeBanners.length - 1 : this.homePosition - 1;
      this.homeStartInterval();
    },
    homeNextButton() {
      this.homeDirection = 'right';
      this.homePosition = this.homePosition >= this.homeBanners.length - 1 ? 0 : this.homePosition + 1;
      this.homeStartInterval();
    }
  },
  beforeUnmount() {
    clearInterval(this.homeInterval);
  },
  mounted() {
    this.homeStartInterval();
  }
};
</script>

<style scoped>
.home-banner-news {
  height: 75%;
  width: 100%;
  position: relative;
  padding: 0;
}

.home-banner-news .news-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  border-radius: 15px;
  overflow: hidden;
}

.home-banner-news .inner-content {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.home-banner-news .news-inner img {
  height: 100%;
}

.home-banner-news .news-inner button.button-previous,
.home-banner-news .news-inner button.button-next {
  width: 35px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #22001d;
  z-index: 1;
}

.home-banner-news .news-inner button.button-previous {
  border-radius: 15px 0 0 15px;
}

.home-banner-news .news-inner button.button-next {
  border-radius: 0 15px 15px 0;
}

.home-banner-news .news-inner button.button-previous svg,
.home-banner-news .news-inner button.button-next svg {
  fill: #ecb7e4;
  transition: fill 0.3s ease;
}

.home-banner-news .news-inner button.button-next svg {
  transform: rotate(180deg);
}

.home-banner-news .news-inner button.button-previous:hover svg,
.home-banner-news .news-inner button.button-next:hover svg {
  fill: #ffffff;
}
</style>
