// src/store/modules/sportsbook.js
import sportsbookService from '@/services/sportsbookService';

const state = {
  iframeUrl: null,
  walletBalance: null,
  error: null,
  loading: false,
};

const mutations = {
  SET_IFRAME_URL(state, url) {
    state.iframeUrl = url;
  },
  SET_WALLET_BALANCE(state, balance) {
    state.walletBalance = balance;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
};

const actions = {
  async initializeIframe({ commit }, { lang }) {
    commit('SET_LOADING', true);
    commit('SET_ERROR', null);
    try {
      const token = await sportsbookService.getIntegrationJwtToken();
      const iframeHost = process.env.VUE_APP_SPORTSBOOK_IFRAME_HOST;
      const url = `https://${iframeHost}/${lang}/?jwt=${token}`;
      commit('SET_IFRAME_URL', url);
    } catch (error) {
      commit('SET_ERROR', error.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getWalletBalance({ commit }, { playerId, currencies }) {
    commit('SET_LOADING', true);
    commit('SET_ERROR', null);
    try {
      const balance = await sportsbookService.getWalletBalance(playerId, currencies);
      commit('SET_WALLET_BALANCE', balance);
    } catch (error) {
      commit('SET_ERROR', error.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getTransactionById({ commit }, { playerId, transactionId }) {
    try {
      const result = await sportsbookService.getTransactionById(playerId, transactionId);
      return result;
    } catch (error) {
      commit('SET_ERROR', error.message);
      throw error;
    }
  },
  async performWalletTransaction({ commit, dispatch }, { playerId, transactionData }) {
    commit('SET_LOADING', true);
    commit('SET_ERROR', null);
    try {
      const result = await sportsbookService.performWalletTransaction(playerId, transactionData);
      await dispatch('getWalletBalance', { playerId, currencies: [transactionData.currency] });
      return result;
    } catch (error) {
      commit('SET_ERROR', error.message);
      throw error;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};

const getters = {
  iframeUrl: state => state.iframeUrl,
  walletBalance: state => state.walletBalance,
  error: state => state.error,
  loading: state => state.loading,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
