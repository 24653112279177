<template>
  <router-link v-bind:to="'/' + game">
    <div class="game">
      <span>{{ game.charAt(0).toUpperCase() + game.slice(1) }}</span>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "HomeGame",
  props: ["game"]
};
</script>

<style scoped>
.game {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 14.5rem;
  width: 11.5rem;
  padding: 1rem;
  border-radius: 0.75rem;
  background-image: url("https://via.placeholder.com/187x235");
  background-position: center;
  background-size: cover;
  position: relative;
  transition: ease-in-out 0.045s;
}

.game::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent);
  border-radius: 0.75rem;
}

.game:hover{
  transform: scale(1.05);
}

.game span {
  color: white;
  font-family: Montserrat, sans-serif;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  z-index: 1;
}
</style>