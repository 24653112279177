<template>
  <div class="base-slider">
    <div class="slider-container" ref="sliderContainer">
      <div class="slider-content" ref="sliderContent">
        <slot></slot>
      </div>
    </div>
    <div class="slider-controls">
      <button @click="scroll('left')" class="control-button left">◀</button>
      <button @click="scroll('right')" class="control-button right">▶</button>
    </div>
  </div>
</template>

<script>
import ColorThief from 'colorthief';

export default {
  name: 'BaseSlider',
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      vibrantColors: {}
    };
  },
  mounted() {
    this.extractColors();
  },
  methods: {
    scroll(direction) {
      const container = this.$refs.sliderContainer;
      const scrollAmount = container.offsetWidth / 2;
      if (direction === 'left') {
        container.scrollLeft -= scrollAmount;
      } else {
        container.scrollLeft += scrollAmount;
      }
    },
    extractColors() {
      const colorThief = new ColorThief();
      this.items.forEach(item => {
        const img = new Image();
        img.crossOrigin = 'Anonymous';
        img.src = item.imageUrl;
        img.onload = () => {
          const palette = colorThief.getPalette(img, 10);
          const vibrantColor = this.findMostVibrantColor(palette);
          this.$set(this.vibrantColors, item.id, vibrantColor);
        };
      });
    },
    findMostVibrantColor(palette) {
      return palette.reduce((mostVibrant, color) => {
        const [r, g, b] = color;
        const [h, s, l] = this.rgbToHsl(r, g, b);

        if (l < 0.2 || l > 0.8 || s < 0.5) {
          return mostVibrant;
        }

        const vibrancy = s * (1 - Math.abs(l - 0.5));

        const [mh, ms, ml] = this.rgbToHsl(...mostVibrant);
        const mostVibrancy = ms * (1 - Math.abs(ml - 0.5));

        return vibrancy > mostVibrancy ? color : mostVibrant;
      }, [255, 0, 0]);
    },
    rgbToHsl(r, g, b) {
      r /= 255, g /= 255, b /= 255;
      const max = Math.max(r, g, b), min = Math.min(r, g, b);
      let h, s, l = (max + min) / 2;

      if (max === min) {
        h = s = 0;
      } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
          case r:
            h = (g - b) / d + (g < b ? 6 : 0);
            break;
          case g:
            h = (b - r) / d + 2;
            break;
          case b:
            h = (r - g) / d + 4;
            break;
        }
        h /= 6;
      }
      return [h, s, l];
    },
    getRGBString(color) {
      return color ? `rgb(${color[0]}, ${color[1]}, ${color[2]})` : 'transparent';
    }
  }
};
</script>

<style scoped>
.base-slider {
  position: relative;
  width: 100%;
}

.slider-container {
  width: 100%;
  overflow-x: auto;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.slider-container::-webkit-scrollbar {
  display: none;
}

.slider-content {
  display: flex;
  gap: 1rem;
  padding: 0.3rem;
}

.slider-controls {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  pointer-events: none;
  z-index: 10;
}

.control-button {
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
  pointer-events: auto;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.control-button.left {
  left: 10px;
}

.control-button.right {
  right: 10px;
}

.control-button:hover {
  background-color: rgba(0, 0, 0, 1);
}
</style>