<template>
  <div class="navbar-cashier">
    <button v-on:click="modalsSetShow('Cashier')" class="button-cashier">
      <div class="button-inner">
        <img src="@/assets/img/icons/wallet.svg"/>
        <span>Deposit</span>
      </div>
    </button>
    <div class="cashier-balance">
      <button v-on:click="modalsSetShow('Claim')" class="button-free">
        <div class="button-inner">Free Coins</div>
      </button>
      <div class="balance-inner">
        <img src="@/assets/img/icons/coin.svg" alt="icon"/>
        <AmountNumber v-bind:amount="authUser.user.balance"/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import AmountNumber from '@/components/AmountNumber';
import IconCashier from '@/components/icons/IconCashier';

export default {
  name: 'NavbarCashier',
  components: {
    AmountNumber,
    IconCashier
  },
  methods: {
    ...mapActions([
      'modalsSetShow'
    ])
  },
  computed: {
    ...mapGetters([
      'authUser'
    ]),
    navbarGetBalance() {
      return parseFloat(Math.floor(this.authUser.user.balance / 10) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  }
};
</script>

<style scoped>
.navbar-cashier {
  display: flex;
  align-items: center;
}

.navbar-cashier button.button-cashier {
  height: 50px;
  position: relative;
  margin-right: 15px;
  padding: 1px;
  transition: all .2s;
}

.button-cashier:hover {
  transition-duration: .2s;
  opacity: .8;
}

.button-cashier:active {
  transition-duration: .2s;
  scale: .9;
}

.navbar-cashier button.button-cashier .button-inner {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0.7rem;
  justify-content: space-between;
  align-items: center;
  padding: 0.9rem;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 0.5rem;
  background-color: var(--highlight-yellow);
}

.navbar-cashier .cashier-balance {
  position: relative;
  padding: 1px;
}

.navbar-cashier .cashier-balance button.button-free {
  height: 21px;
  position: absolute;
  top: -11px;
  right: 0;
  z-index: 1;
}

.navbar-cashier .cashier-balance button.button-free .button-inner {
  display: inline-flex;
  padding: 0.3rem 0.5rem;
  align-items: center;
  gap: 0.5rem;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 0.7rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: var(--highlight-pink);
  border-radius: 0.3rem;
}

.navbar-cashier .balance-inner {
  width: fit-content;
  height: 100%;
  display: flex;
  gap: 18px;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px 0 0;
  border-radius: 0.625rem;
  border: 2px solid rgba(255, 255, 255, 0.09);
  background-color: var(--background-primary);
}

.navbar-cashier .balance-inner img {
  filter: drop-shadow(0px 0px 6px rgba(254, 255, 63, 0.20)) drop-shadow(0px 0px 12px rgba(254, 255, 63, 0.15));
}

.navbar-cashier .inner-value {
  font-size: 12px;
  font-weight: 600;
  color: #c1c1c1;
}

.navbar-cashier .inner-value span {
  font-size: 16px;
  font-weight: 800;
  color: #ffffff;
}

@media only screen and (max-width: 1175px) {

  .navbar-cashier button.button-cashier {
    height: 38px;
  }

  .navbar-cashier button.button-cashier .button-inner {
    padding: 0 13px;
  }

  .navbar-cashier button.button-cashier .button-inner svg {
    width: 14px;
  }


  .navbar-cashier button.button-cashier .button-inner svg {
    margin-right: 0;
  }

  .navbar-cashier button.button-cashier .button-inner span {
    display: none;
  }


  .navbar-cashier .cashier-balance {
    width: auto;
    height: 38px;
  }

  .navbar-cashier .cashier-balance button.button-free {
    height: 16px;
    top: -8px;
  }

  .navbar-cashier .cashier-balance button.button-free .button-inner {
    padding: 0 6px;
    font-size: 8px;
  }

  .navbar-cashier .balance-inner {
    padding: 0 12px 0 5px;
  }

  .navbar-cashier .balance-inner img {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }

}
</style>
