var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-wrapper"},[_c('div',{staticClass:"home"},[_c('HomeBannerUserLarge',{staticClass:"full-width-banner",attrs:{"backgroundImage":require('@/assets/img/home/mainbanner/banner-castle.png'),"logo":require('@/assets/img/logo.png'),"character":require('@/assets/img/home/mainbanner/alex-romanov.png')}}),_c('div',{staticClass:"home-content"},[_c('div',{staticClass:"home-section featured-games-container"},[_c('div',{staticClass:"featured-games-half desktop-only"},[_vm._m(0),_c('HomeBannerNews')],1),_c('div',{staticClass:"featured-games-half"},[_c('div',{staticClass:"home-section"},[_vm._m(1),_c('OriginalGamesSlider')],1)])]),_c('div',{staticClass:"home-section"},[_vm._m(2),_c('LiveGamesSlider')],1),_c('div',{staticClass:"home-section mobile-only"},[_vm._m(3),_c('HomeBannerNews')],1),_c('div',{staticClass:"home-section"},[_vm._m(4),_c('ProviderOfWeekSlider')],1),_c('div',{staticClass:"home-section"},[_vm._m(5),_c('SecondFeatureGamesSlider')],1),_c('div',{staticClass:"home-section bets-section"},[_c('div',{staticClass:"bets-container"},[_c('Bets')],1)])])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('img',{attrs:{"src":require("@/assets/img/icons/featured-icon.png"),"alt":"News"}}),_c('span',[_vm._v("Community")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('img',{attrs:{"src":require("@/assets/img/icons/featured-icon.png"),"alt":"Original Games"}}),_c('span',[_vm._v("Original Games")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('img',{attrs:{"src":require("@/assets/img/icons/featured-icon.png"),"alt":"Live Games"}}),_c('span',[_vm._v("Live Games")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('img',{attrs:{"src":require("@/assets/img/icons/featured-icon.png"),"alt":"News"}}),_c('span',[_vm._v("News")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('img',{attrs:{"src":require("@/assets/img/icons/featured-icon.png"),"alt":"Provider of the Week"}}),_c('span',[_vm._v("Provider of the Week")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('img',{attrs:{"src":require("@/assets/img/icons/featured-icon.png"),"alt":"More Featured Games"}}),_c('span',[_vm._v("More Featured Games")])])
}]

export { render, staticRenderFns }