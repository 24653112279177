var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-admin-user"},[_vm._m(0),_c('div',{staticClass:"user-nav"},[_c('button',{staticClass:"button-nav",class:{ 'button-active': _vm.modalTab === 'Profile' },on:{"click":function($event){return _vm.modalSetTab('Profile')}}},[_vm._m(1)]),_c('button',{staticClass:"button-nav",class:{ 'button-active': _vm.modalTab === 'Transactions' },on:{"click":function($event){return _vm.modalSetTab('Transactions')}}},[_vm._m(2)]),_c('button',{staticClass:"button-nav",class:{ 'button-active': _vm.modalTab === 'Games' },on:{"click":function($event){return _vm.modalSetTab('Games')}}},[_vm._m(3)]),_c('button',{staticClass:"button-nav",class:{ 'button-active': _vm.modalTab === 'Addresses' },on:{"click":function($event){return _vm.modalSetTab('Addresses')}}},[_vm._m(4)]),_c('button',{staticClass:"button-nav",class:{ 'button-active': _vm.modalTab === 'Settings' },on:{"click":function($event){return _vm.modalSetTab('Settings')}}},[_vm._m(5)])]),_c('div',{staticClass:"user-content"},[_c('AdminUser' + _vm.modalTab,{tag:"component"})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-title"},[_c('span',{staticClass:"gradient-green"},[_vm._v("ADMIN USER")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button-inner"},[_c('span',[_vm._v("PROFILE")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button-inner"},[_c('span',[_vm._v("TRANSACTIONS")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button-inner"},[_c('span',[_vm._v("GAMES")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button-inner"},[_c('span',[_vm._v("ADDRESSES")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button-inner"},[_c('span',[_vm._v("SETTINGS")])])
}]

export { render, staticRenderFns }