<template>
  <div class="game-banner-slider">
    <div v-for="(game, index) in games" :key="index" 
         class="slide" 
         :class="{ 'active': currentSlide === index }"
         @click="handleSlideClick(game)">
      <div class="background" :style="{ backgroundImage: `url(${game.backgroundImage})` }">
        <div class="content">
          <h2 class="title">{{ game.title }}</h2> <!-- Title -->
          <p class="subtitle">{{ game.subtitle }}</p> <!-- Subtitle -->
          <p class="subheading">{{ game.subheading }}</p> <!-- New Subheading -->
          <button class="play-button" @click.stop="handlePlayClick(game)">Play Now</button> <!-- New Button -->
        </div>
      </div>
    </div>
    <div class="dots">
      <span v-for="(game, index) in games" :key="index" 
            class="dot" :class="{ 'active': currentSlide === index }"
            @click.stop="setSlide(index)"></span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentSlide: 0,
      games: [
        {
          backgroundImage: require('@/assets/img/banner/wanted.png'),
          link: '/games/crash',
          subheading: 'Join the VIP experience!', // New Subheading
        },
        {
          backgroundImage: require('@/assets/img/banner/starlightprincess.png'),
          link: '/games/crash',
          subheading: 'Join the experience!', // New Subheading
        },
      ]
    };
  },
  mounted() {
    this.startSlideshow();
  },
  methods: {
    startSlideshow() {
      setInterval(() => {
        this.nextSlide();
      }, 5000); // Change slide every 5 seconds
    },
    nextSlide() {
      this.currentSlide = (this.currentSlide + 1) % this.games.length;
    },
    setSlide(index) {
      this.currentSlide = index;
    },
    handleSlideClick(game) {
      if (game.link) {
        this.$router.push(game.link).catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            throw err;
          }
        });
      }
    },
    handlePlayClick(game) {
      // Logic to handle play button click, e.g., navigate to game link
      if (game.link) {
        this.$router.push(game.link);
      }
    }
  }
};
</script>

<style scoped>
.game-banner-slider {
  position: relative;
  width: 100%;
  height: 187px;
  border-radius: 0.5rem;
  overflow: hidden;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  cursor: pointer;
}

.slide.active {
  opacity: 1;
}

.background {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  padding: 0 1rem;
}

.content {
  max-width: 60%;
  color: rgba(255, 255, 255, 0.9); /* General text color */
}

.title {
  font-family: "Montserrat", sans-serif;
  font-size: 1.8rem; /* Increased for better visibility */
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 0.5rem;
}

.subtitle {
  font-family: "Montserrat", sans-serif;
  font-size: 1rem; /* Increased for better visibility */
  font-weight: 500;
  margin-bottom: 0.5rem; /* Spacing */
}

.subheading {
  font-family: "Montserrat", sans-serif;
  font-size: 1.4rem; /* Increased for better visibility */
  font-weight: 600;
  margin: 0.5rem 0; /* Spacing */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7); /* Added text shadow for better contrast */
}

.play-button {
  background-color: rgba(255, 255, 255, 0.9); /* Slightly more opaque */
  color: #000;
  border: none;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease; /* Added transform transition */
  font-size: 1rem; /* Font size for button */
  font-weight: bold; /* Bold text for button */
}

.play-button:hover {
  background-color: white; /* Change on hover */
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.dots {
  position: absolute;
  bottom: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 0.25rem;
}

.dot {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dot.active {
  background-color: white;
}

@media (max-width: 768px) {
  .title {
    font-size: 1.5rem; /* Adjusted for smaller screens */
  }

  .subtitle {
    font-size: 0.9rem; /* Adjusted for smaller screens */
  }

  .subheading {
    font-size: 1.2rem; /* Adjusted for smaller screens */
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 1.2rem; /* Adjusted for smaller screens */
  }

  .subtitle {
    font-size: 0.8rem; /* Adjusted for smaller screens */
  }

  .subheading {
    font-size: 1rem; /* Adjusted for smaller screens */
  }
}

@media (max-width: 900px) {
  .game-banner-slider {
    margin-bottom: 15px; 
  }
}
</style>