<template>
  <BaseSlider :items="games" :class="sliderClass">
    <template v-slot:default>
      <div v-for="game in games" :key="game.uuid" class="game-card" :style="getCardStyle(game)">
        <div class="game" :style="getGameStyle(game)" @click="playGame(game)">
          <div class="game-overlay" :style="getOverlayStyle(game)"></div>
          <img
              :src="game.imageUrl"
              :alt="game.name"
              class="game-image"
              @load="handleImageLoad(game)"
              @error="handleImageError(game)"
              ref="gameImage"
              crossorigin="anonymous"
          />
          <div class="game-info" :style="getInfoStyle(game)">
            <div class="game-name" :style="getGameNameStyle(game.name)">{{ game.name.toUpperCase() }}</div>
            <div class="provider-name">{{ (game.provider || providerName).toUpperCase() }}</div>
          </div>
          <div class="dark-gradient"/>
        </div>
      </div>
    </template>
  </BaseSlider>
</template>

<script>
import BaseSlider from '@/components/base/BaseSlider.vue';
import {mapState, mapActions} from 'vuex';
import ColorThief from 'colorthief';
import axios from 'axios';

export default {
  name: 'GameSlider',
  components: {
    BaseSlider
  },
  props: {
    sliderClass: {
      type: String,
      required: true
    },
    collection: {
      type: String,
      required: true
    },
    providerName: {
      type: String,
      default: ''
    },
    customizeGame: {
      type: Function,
      default: game => game
    }
  },
  data() {
    return {
      games: [],
      gameColors: {},
      textHeights: {},
      windowWidth: window.innerWidth
    };
  },
  computed: {
    ...mapState({
      authUser: state => state.auth.authUser,
      currentUser: state => state.auth.authUser.user
    }),
    isAuthenticated() {
      return !!this.currentUser;
    },
    cardSize() {
      if (this.windowWidth < 480) return 140;
      if (this.windowWidth < 768) return 160;
      if (this.windowWidth < 1024) return 180;
      return 200;
    }
  },
  mounted() {
    this.fetchGames();
    window.addEventListener('resize', this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    ...mapActions('slotegrator', ['initGame', 'initDemoGame']),

    async fetchGames() {
      try {
        const response = await axios.get(`/api/home-design/${this.collection}`);
        console.log('API response:', response.data);
        this.games = response.data.slice(0, 20).map(this.customizeGame); // Limit to 20 games
      } catch (error) {
        console.error('Error fetching games:', error);
      }
    },
    getCardStyle(game) {
      return {
        width: `${this.cardSize}px`,
        height: `${this.cardSize * 1.25}px` // Maintain aspect ratio
      };
    },
    getGameStyle(game) {
      return {
        backgroundImage: `url(${game.imageUrl})`
      };
    },
    getOverlayStyle(game) {
      const color = game.overlayColor || 'rgba(0,0,0,0.5)';
      return {
        height: '70%',
        bottom: 0,
        position: 'absolute',
        left: 0,
        right: 0
      };
    },
    getInfoStyle(game) {
      const color = this.getGameColor(game);
      //const height = this.textHeights[game.uuid] || 80; // Default height if not calculated yet

      return {
        /*background: `linear-gradient(to top,
                     ${color} 0%, 
                     ${color} ${height * 0.6}%, 
                     ${this.adjustAlpha(color, 0.8)} ${height * 0.8}%,
                     ${this.adjustAlpha(color, 0.5)} ${height * 1.1}%,
                     ${this.adjustAlpha(color, 0)} ${height * 1.3}%)`,*/
        padding: '0.5rem',
        borderRadius: '0 0 0.55rem 0.55rem'
      };
    },
    getGameColor(game) {
      return game.overlayColor || this.gameColors[game.uuid] || 'rgba(0,0,0,0.5)';
    },
    getGameNameStyle(name) {
      const length = name.length;
      let fontSize = 1.8;
      if (length > 10) fontSize = 1.5;
      if (length > 15) fontSize = 1.2;
      if (length > 20) fontSize = 1;
      return {fontSize: `${fontSize}rem`};
    },
    handleImageLoad(game) {
      if (!game.overlayColor && !this.gameColors[game.uuid]) {
        this.$nextTick(() => {
          const img = this.$refs.gameImage.find(ref => ref.alt === game.name);
          if (img) {
            const colorThief = new ColorThief();
            const color = colorThief.getColor(img);
            this.$set(this.gameColors, game.uuid, `rgb(${color.join(',')})`);
          }
          // Calculate text height
          const infoElement = this.$el.querySelector(`.game-card[data-game-id="${game.uuid}"] .game-info`);
          const gameElement = this.$el.querySelector(`.game-card[data-game-id="${game.uuid}"] .game`);
          if (infoElement && gameElement) {
            const height = (infoElement.offsetHeight / gameElement.offsetHeight) * 100;
            this.$set(this.textHeights, game.uuid, height);
          }
        });
      }
    },
    handleImageError(game) {
      console.error(`Failed to load image for game: ${game.name}, provider: ${game.provider}, URL: ${game.imageUrl}`);
    },
    async playGame(game) {
      if (game.link) {
        this.$router.push(game.link);
      } else {
        console.log('playGame method called with game:', game);

        try {
          if (!this.isAuthenticated) {
            console.log('User not authenticated, redirecting to demo game');
            return this.playDemoGame(game);
          }

          const user = this.currentUser;
          if (!user) {
            throw new Error('User data not available');
          }

          const sessionId = Date.now().toString() + user._id;

          const gameData = {
            game_uuid: game.uuid,
            player_id: user._id,
            player_name: user.username,
            currency: user.currency || 'EUR',
            session_id: sessionId,
            return_url: `${window.location.origin}/games?action=close`,
            language: 'en'
          };

          console.log('Game data being sent to initGame:', gameData);
          const initData = await this.initGame(gameData);
          console.log('Game initialized with data:', initData);

          if (initData && initData.url) {
            this.$router.push({
              name: 'Games',
              params: {uuid: game.uuid},
              query: {
                gameUrl: initData.url,
                gameName: game.name
              }
            });
          } else {
            throw new Error('Game URL not received from the server');
          }
        } catch (error) {
          console.error('Error playing game:', error);
          this.$store.dispatch('notificationShow', error.message || 'Failed to start the game. Please try again.');
        }
      }
    },
    async playDemoGame(game) {
      try {
        const demoData = {
          game_uuid: game.uuid,
          return_url: `${window.location.origin}/games?action=close`,
          language: 'en'
        };

        console.log('Demo data being sent to initDemoGame:', demoData);
        const initData = await this.initDemoGame(demoData);
        console.log('Demo game initialized with data:', initData);

        if (initData && initData.url) {
          this.$router.push({
            name: 'Games',
            params: {uuid: game.uuid},
            query: {
              gameUrl: initData.url,
              gameName: game.name,
              demo: 'true'
            }
          });
        } else {
          throw new Error('Demo game URL not received from the server');
        }
      } catch (error) {
        console.error('Error playing demo game:', error);
        this.$store.dispatch('notificationShow', error.message || 'Failed to start the demo game. Please try again.');
      }
    },
    adjustAlpha(color, alpha) {
      if (color.startsWith('rgba')) {
        return color.replace(/[\d.]+\)$/g, `${alpha})`);
      }
      if (color.startsWith('rgb')) {
        return color.replace('rgb', 'rgba').replace(')', `, ${alpha})`);
      }
      if (color.startsWith('#')) {
        const r = parseInt(color.slice(1, 3), 16);
        const g = parseInt(color.slice(3, 5), 16);
        const b = parseInt(color.slice(5, 7), 16);
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
      }
      return color;
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    }
  }
};
</script>

<style scoped>
/* CSS copied from gameCard.css */
.game-card {
  flex: 0 0 auto;
  margin-right: 1rem;
  position: relative;
  overflow: hidden;
  border-radius: 0.75rem;
  transition: ease 0.2s;
}

.game-card:hover {
  transform: scale(1.05);
}

.game {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: cover;
  border-radius: 0.55rem;
  overflow: hidden;
}

.game-overlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  border-radius: 0.55rem;
  transition: opacity 0.3s ease;
}

.game:hover .game-overlay {
  opacity: 1; /* Full opacity on hover */
}

.game-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.55rem;
}

.game-info {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.5rem;
  color: white;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 0 0 0.55rem 0.55rem;
  transition: ease 0.3s;
}

.game:hover .game-info {
  bottom: 10px;
}

.dark-gradient {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 60%);
  position: absolute;
  height: 100%;
  width: 100%;
}

.game-name {
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  text-align: center;
  max-height: 4.8rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.provider-name {
  font-family: Montserrat, sans-serif;
  font-size: 0.75rem;
  font-weight: 500;
  text-align: center;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
}

.game-frame {
  width: 100%;
  position: relative;
  background-color: #161c2c;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
}

.game-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5);
}

.game-title {
  color: white;
  font-size: 18px;
  font-weight: bold;
}

.close-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

.game-iframe-container {
  position: relative;
  padding-top: 56.25%; /* 16:9 aspect ratio */
}

.game-iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.random-games {
  margin-top: 20px;
}

.random-games h2 {
  color: white;
  margin-bottom: 10px;
}

.game-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 15px;
}
</style>