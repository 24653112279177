<template>
    <div class="coin-container">
        <div class="coin" :class="{ 'flipping': isFlipping }">
            <img class="side heads" src="@/assets/img/coinflip/coin-heads.png" alt="Heads">
            <img class="side tails" src="@/assets/img/coinflip/coin-tails.png" alt="Tails">
        </div>
    </div>
</template>

<script>
export default {
    name: 'CoinflipCoin',
    data() {
        return {
            isFlipping: false
        }
    },
    methods: {
        startFlip() {
            this.isFlipping = true;
            setTimeout(() => {
                this.isFlipping = false;
            }, 9000);
        },
    }
}
</script>

<style scoped>
.coin-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.coin {
    width: 150px;
    height: 150px;
    position: relative;
    transform-style: preserve-3d;
}

.side {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    backface-visibility: hidden;
    object-fit: cover;
}

.heads {
    z-index: 2;
}

.tails {
    transform: rotateY(180deg);
}

.flipping {
    animation: flip 2s linear infinite;
}

@keyframes flip {
    0% {
        transform: rotateY(0);
    }

    50% {
        transform: rotateY(900deg);
    }

    100% {
        transform: rotateY(1800deg);
    }
}
</style>
