<template>
  <aside id="chat" v-bind:class="{
        'chat-open': generalSidebarMobile === 'Chat',
        'chat-rain': generalRain.active !== null
    }">
    <div class="chat-toggle">

    </div>
    <div class="chat-header">
      <div class="top-elements">
        <button v-on:click="generalSetSidebarMobile(null)" class="button-close">
          <img src="@/assets/img/icons/close.svg"/>
        </button>
        <div class="header-rooms">
          <div class="header-online">
            <img src="@/assets/img/icons/group.svg"/>
            <span>{{ chatGetOnline }} users</span>
          </div>
          <ChatRoomDropdown/>
        </div>
      </div>
      <RainTipDropdown/>
    </div>
    <div class="chat-content">
      <div v-on:scroll="chatHandleScroll()" class="content-messages" ref="chatMessages">
        <transition name="fade" mode="out-in">
          <div v-if="chatMessages.data === null || chatMessages.loading === true" class="messages-loading"
               key="loading">

            <div v-for="index in 4" v-bind:key="index" class="loading-placeholder">
              <div class="placeholder-user">
                <div class="user-avatar"></div>
                <div class="user-username"></div>
              </div>
              <div class="placeholder-text"></div>
            </div>

          </div>
          <div v-else class="messages-list" key="list">
            <ChatMessageElement v-for="message in chatMessages.data" v-bind:key="message._id" v-bind:message="message"/>
          </div>
        </transition>
      </div>
      <div class="content-lock">

      </div>
      <div class="content-rain">
        <transition name="fade-slide" mode="out-in">
          <RainJoin v-if="generalRain.active !== null"/>
        </transition>
      </div>
    </div>
    <div class="chat-footer">
      <div class="footer-input">
        <div class="left-side">
          <!--<button v-on:click="modalsSetShow('ChatRules')" class="button-rules">
            <IconRules/>
          </button>-->
          <input v-model="chatMessage" v-on:keyup.enter="chatMessageButton" type="text"
                 placeholder="Type a message..."/>
        </div>
        <div class="right-side">
          <!--<ChatEmojisDropdown/>-->
          <button v-on:click="chatMessageButton()" class="button-send" v-bind:disabled="socketSendLoading !== null">
            <img src="@/assets/img/icons/send.svg"/>
          </button>
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import IconChatGradient from '@/components/icons/IconChatGradient';
import IconClose from '@/components/icons/IconClose';
import IconUsers from '@/components/icons/IconUsers';
import IconMessage from '@/components/icons/IconMessage';
import IconRules from '@/components/icons/IconRules';
import ChatRoomDropdown from '@/components/chat/ChatRoomDropdown';
import ChatMessageElement from '@/components/chat/ChatMessageElement';
import ChatEmojisDropdown from '@/components/chat/ChatEmojisDropdown';
import RainTipDropdown from '@/components/rain/RainTipDropdown';
import RainJoin from '@/components/rain/RainJoin';

export default {
  name: 'Chat',
  components: {
    IconChatGradient,
    IconClose,
    IconUsers,
    IconMessage,
    IconRules,
    ChatRoomDropdown,
    ChatMessageElement,
    ChatEmojisDropdown,
    RainTipDropdown,
    RainJoin
  },
  data() {
    return {
      chatMessage: ''
    };
  },
  methods: {
    ...mapActions([
      'notificationShow',
      'modalsSetShow',
      'modalsSetData',
      'generalSetSidebarMobile',
      'chatSetScroll',
      'chatGetMessagesSocket',
      'chatSendMessageSocket',
      'chatSendClearSocket',
      'chatSendLockSocket',
      'generalSetUserInfoData',
      'generalGetUserInfoSocket',
      'generalSendRainCreateSocket'
    ]),
    chatScrollToBottom() {
      this.$nextTick(() => {
        let container = this.$refs.chatMessages;
        container.scrollTop = container.scrollHeight;
      });
    },
    chatAddEmoji(emoji) {
      this.chatMessage = this.chatMessage + emoji;
    },
    chatHandleScroll() {
      let container = this.$refs.chatMessages;
      this.chatSetScroll((container.scrollHeight - (container.scrollTop + container.clientHeight)) < 100);
    },
    chatHandleCommand(command) {
      this.generalSetUserInfoData(null);
      const args = command.split(' ');

      if (args[0] === '/tip' || (['/mute', '/ban'].includes(args[0]) === true && ['admin', 'mod'].includes(this.authUser.user.rank) === true)) {
        if (args[1] === undefined || args[1].trim() === '' || args[1].match(/^[0-9a-fA-F]{24}$/) === null) {
          this.notificationShow({type: 'error', message: 'You need to provide a valid user id.'});
          return;
        }

        const data = {userId: args[1]};
        this.generalGetUserInfoSocket(data);

        if (args[0] === '/tip') {
          this.modalsSetShow('Tip');
        } else if (args[0] === '/mute') {
          this.modalsSetShow('Mute');
        } else if (args[0] === '/ban') {
          this.modalsSetShow('Ban');
        }
      } else if (args[0] === '/rain') {
        const data = {amount: Math.floor(args[1] * 1000)};
        this.generalSendRainCreateSocket(data);
      } else if (args[0] === '/clear') {
        const data = {};
        this.chatSendClearSocket(data);
      } else if (args[0] === '/lock') {
        const data = {value: false};
        this.chatSendLockSocket(data);
      } else if (args[0] === '/unlock') {
        const data = {value: true};
        this.chatSendLockSocket(data);
      }
    },
    chatMessageButton() {
      if (this.authUser.user === null) {
        this.notificationShow({type: 'error', message: 'Please sign in to perform this action.'});
        return;
      }

      if (this.generalSettings.chat.enabled === false && this.authUser.user.rank !== 'admin') {
        this.notificationShow({
          type: 'error',
          message: 'You can not send a chat message because the action is not available at the moment.'
        });
        return;
      }

      if (this.generalSettings.chat.mode === 'normal' && this.authUser.user.rank !== 'admin' && (new Date().getTime() - this.chatLastMessage) < 3000) {
        this.notificationShow({type: 'error', message: 'You can only send 1 message every 3 seconds.'});
        return;
      }

      if (this.generalSettings.chat.mode === 'slow' && this.authUser.user.rank !== 'admin' && (new Date().getTime() - this.chatLastMessage) < 6000) {
        this.notificationShow({
          type: 'error',
          message: 'You can only send 1 message every 6 seconds because the chat is in slow mode.'
        });
        return;
      }

      const message = this.chatMessage.trim();

      if (message === '') {
        this.notificationShow({type: 'error', message: 'Please enter a message.'});
        return;
      }

      if (['/tip', '/rain'].includes(message.split(' ')[0]) === true) {
        this.chatHandleCommand(message);
      } else if (
          ['mod', 'admin'].includes(this.authUser.user.rank) === true &&
          ['/clear', '/lock', '/unlock', '/mute', '/ban'].includes(message.split(' ')[0]) === true
      ) {
        this.chatHandleCommand(message);
      } else {
        const data = {message: message};
        this.chatSendMessageSocket(data);
      }

      this.chatMessage = '';
    }
  },
  computed: {
    ...mapGetters([
      'socketSendLoading',
      'generalSidebarMobile',
      'generalSettings',
      'authUser',
      'chatScroll',
      'chatRoom',
      'chatOnline',
      'chatMessages',
      'chatLastMessage',
      'generalRain'
    ]),
    chatGetOnline() {
      let online = 0;

      for (const [key, value] of Object.entries(this.chatOnline)) {
        online = online + value;
      }

      return online;
    }
  },
  watch: {
    'chatMessages.data': {
      handler(state, oldState) {
        const message = this.chatMessages.data[this.chatMessages.data.length - 1];

        if (
            this.chatScroll === true ||
            this.chatMessages.data.length === 0 ||
            (this.authUser.user !== null && message !== undefined && message.type === 'user' && message.user._id === this.authUser.user._id)
        ) {
          setTimeout(() => {
            this.chatScrollToBottom();
          }, 200);
        }
      },
      deep: true
    },
    'generalRain.active': {
      handler(state, oldState) {
        setTimeout(() => {
          this.chatScrollToBottom();
        }, 125);
      },
      deep: true
    },
    'generalSidebarMobile': {
      handler(state, oldState) {
        if (this.generalSidebarMobile === 'Chat') {
          this.chatScrollToBottom();
        }
      }
    }
  },
  mounted() {
    this.chatScrollToBottom();
  }
};
</script>

<style scoped>
aside#chat {
  width: 375px;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  padding: 17px 0 0 0;
  background: var(--background-secondary);
  z-index: 100;
  transition: transform 0.3s ease;
  transform: translate(375px, 0);
}

aside#chat.chat-open {
  transform: translate(0, 0);
}

aside#chat .chat-toggle {
  position: absolute;
  display: none;
  bottom: 50px;
  left: -65px;
}

aside#chat .chat-toggle button {
  width: 50px;
  height: 50px;
}

aside#chat .chat-toggle button .button-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

aside#chat .chat-toggle button .button-inner svg {
  width: 19px;
  height: 19px;
  margin-top: 3px;
  fill: #ffffff;
}

aside#chat .chat-header {
  position: relative;
  padding: 0 15px;
}

aside#chat .chat-header button.button-close svg {
  fill: white;
}

aside#chat .chat-header .top-elements {
  display: flex;
  align-items: center;
  gap: 1rem;
}

aside#chat .chat-header .top-elements .button-close {
  height: fit-content;
  border-radius: 0.5rem;
  border: 2px solid rgba(255, 255, 255, 0.08);
  padding: 0.3rem;
  background: var(--background-secondary);
  display: flex;
  align-items: center;
}

aside#chat .chat-header .top-elements .button-close img {
  width: 1.15rem;
  opacity: 50%;
}

aside#chat .header-online {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.29);
  font-family: Montserrat, sans-serif;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  gap: 0.5rem;
}

aside#chat .header-online svg {
  margin-right: 9px;
}

aside#chat .header-rooms {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
}

aside#chat .chat-content {
  width: 100%;
  margin-top: 16px;
  padding-bottom: 10px;
  height: calc(100% - 221px);
  position: relative;
}

aside#chat .content-messages {
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scroll-behavior: smooth;
  transition: height 0.3s ease;
}

aside#chat.chat-rain .chat-content .content-messages {
  height: calc(100% - 145px);
}

aside#chat .content-messages::-webkit-scrollbar-track {
  background-color: transparent;
}

aside#chat .content-messages::-webkit-scrollbar {
  width: 0;
  height: 0;
}

aside#chat .messages-loading {
  width: 100%;
  padding: 0 15px;
}

aside#chat .messages-loading.fade-leave-active {
  transition: opacity 0.2s;
}

aside#chat .messages-loading.fade-leave-to {
  opacity: 0;
}

aside#chat .content-lock {
  position: absolute;
  bottom: 12px;
  left: 50%;
  transform: translate(-50%, 0);
}

aside#chat.chat-rain .content-lock {
  bottom: 157px;
  z-index: 100;
}

aside#chat .content-lock button.button-lock {
  height: 30px;
  position: relative;
  padding: 1px;
  z-index: 1;
}

aside#chat .content-lock button.button-lock .button-inner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 15px;
  font-size: 10px;
  font-weight: 700;
  color: #ffffff;
}

aside#chat .content-rain {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 15px 15px 0 15px;
  z-index: 1;
}

aside#chat .content-rain .fade-slide-enter-active,
aside#chat .content-rain .fade-slide-leave-active {
  transition: all 0.3s ease;
}

aside#chat .content-rain .fade-slide-enter,
aside#chat .content-rain .fade-slide-leave-to {
  transform: translateY(-30px);
  opacity: 0;
}

aside#chat .loading-placeholder {
  width: 100%;
  margin-top: 15px;
  padding-bottom: 15px;
}

aside#chat .loading-placeholder:first-of-type {
  margin-top: 0;
}

aside#chat .placeholder-user {
  display: flex;
  align-items: center;
}

aside#chat .user-avatar {
  width: 37px;
  height: 37px;
  position: relative;
  margin-right: 13px;
  border-radius: 50%;
  overflow: hidden;
}

aside#chat .user-username {
  width: 75px;
  height: 20px;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}

aside#chat .placeholder-text {
  width: 100%;
  height: 19px;
  position: relative;
  margin-top: 8px;
  border-radius: 5px;
  overflow: hidden;
}

aside#chat .messages-list {
  width: 100%;
  height: 100%;
  padding: 0 1rem 0 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

aside#chat .messages-list.fade-enter-active {
  transition: opacity 0.1s;
}

aside#chat .messages-list.fade-enter-from {
  opacity: 0;
}

aside#chat .chat-footer {
  background-color: var(--background-secondary);
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 6rem;
  border-top: 2px solid rgba(255, 255, 255, 0.05);
  padding: 0.5rem 1rem 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

aside#chat .footer-input {
  padding: 0 0.6rem 0 0.75rem;
  width: 100%;
  height: 75%;
  display: flex;
  align-items: center;
  border-radius: 0.75rem;
  border: 2px solid rgba(255, 255, 255, 0.09);
  background-color: var(--background-primary);
}

.footer-input .left-side {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  height: 100%;
}

.footer-input .right-side {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  height: 100%;
}

aside#chat .footer-input input {
  width: 95%;
  height: 100%;
  color: #ffffff;
  background-color: transparent;
  font-family: Montserrat, sans-serif;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

aside#chat .footer-input input::placeholder {
  color: rgba(255, 255, 255, 0.3);
}

aside#chat .footer-input button.button-send {
  display: flex;
  background-color: rgba(var(--highlight-pink-rgb), 0.25);
  border: 1px solid var(--highlight-pink);
  border-radius: 0.5rem;
  padding: 0.5rem;
  justify-content: center;
  align-items: center;
}

aside#chat .footer-input button.button-rules {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  opacity: 50%;
  transition: ease-in-out 0.2s;
}

aside#chat .footer-input button.button-rules:hover {
  opacity: 100%;
}

aside#chat .footer-actions {
  display: flex;
  align-items: center;
}

aside#chat .footer-actions button.button-rules {
  display: flex;
  align-items: center;
}

aside#chat .footer-actions button.button-rules {
  margin-right: 15px;
  color: white;
}

aside#chat .footer-actions button.button-rules svg {
  margin-right: 7px;
}

@keyframes loading_animation {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
}

@media only screen and (max-width: 1500px) {

  aside#chat {
    transform: translate(100%, 0);
    z-index: 100;
  }

}

@media only screen and (max-width: 1175px) {

  aside#chat .chat-toggle {
    display: none;
  }

}

@media only screen and (max-width: 500px) {

  aside#chat {
    width: 100%;
  }

}
</style>
