<template>
  <div class="sportsbook-page">
    <div v-if="error" class="error-message">
      {{ error }}
    </div>
    <div v-else class="sportsbook-container">
      <iframe
        v-if="sportsbookUrl"
        ref="sportsbookIframe"
        :src="sportsbookUrl"
        @load="onIframeLoaded"
        @error="onIframeError"
        class="sportsbook-iframe"
      ></iframe>
      <div v-else class="loading-message">
        Loading sportsbook...
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import sportsbookService from '@/services/sportsbookFrontendService';

export default {
  name: 'Sportsbook',
  data() {
    return {
      sportsbookUrl: '',
      defaultLanguage: 'en',
      error: null,
    }
  },
  computed: {
    ...mapState({
      isAuthenticated: state => state.auth.isAuthenticated,
      user: state => state.auth.user,
      token: state => state.auth.token,
    }),
    userLanguage() {
      return this.user?.language || this.defaultLanguage;
    }
  },
  methods: {
    ...mapActions(['modalsSetShow', 'updateUserBalance']),
    onIframeLoaded() {
      console.log('Sportsbook iframe loaded successfully');
      this.sendAuthState();
    },
    onIframeError(error) {
      console.error('Error loading iframe:', error);
      this.error = 'Failed to load sportsbook. Please try again later.';
    },
    async initializeSportsbook() {
      console.log('Initializing sportsbook');
      try {
        const lang = this.userLanguage;
        const baseUrl = process.env.VUE_APP_SPORTSBOOK_IFRAME_URL;
        if (!baseUrl) {
          throw new Error('Sportsbook URL is not configured');
        }
        
        let url = `${baseUrl}${lang}/`;
        
        if (this.isAuthenticated && this.user && this.user.id) {
          const token = await sportsbookService.getJwtToken(this.user.id);
          url += `?jwt=${encodeURIComponent(token)}`;
        }
        
        this.sportsbookUrl = url;
        console.log('Sportsbook URL:', this.sportsbookUrl);
      } catch (error) {
        console.error('Failed to initialize sportsbook:', error);
        this.error = 'Failed to initialize sportsbook. Please try again later.';
      }
    },
    sendAuthState() {
      const message = {
        type: 'auth.logged_in.change',
        loggedIn: this.isAuthenticated,
      };
      if (this.isAuthenticated && this.token) {
        message.token = this.token;
      }
      this.postMessageToIframe(message);
    },
    postMessageToIframe(message) {
      if (this.$refs.sportsbookIframe && this.$refs.sportsbookIframe.contentWindow) {
        this.$refs.sportsbookIframe.contentWindow.postMessage(message, '*');
      } else {
        console.error('Iframe or contentWindow not available');
      }
    },
    handleIframeMessage(event) {
      console.log('Received message from iframe:', event.data);
      switch (event.data.type) {
        case 'login.click':
          this.modalsSetShow('Login');
          break;
        case 'auth.fail':
          console.error('Authentication failed in iframe');
          this.error = 'Authentication failed. Please log in again.';
          // You might want to log out the user or refresh the token here
          break;
        case 'user.balance':
          this.updateUserBalance(event.data.balance);
          break;
        case 'error':
          this.error = 'An error occurred in the sportsbook. Please try again later.';
          break;
        default:
          console.log('Unhandled message type:', event.data.type);
      }
    },
  },
  async mounted() {
    console.log('Sportsbook component mounted');
    await this.initializeSportsbook();
    window.addEventListener('message', this.handleIframeMessage);
  },
  beforeDestroy() {
    window.removeEventListener('message', this.handleIframeMessage);
  },
  watch: {
    isAuthenticated: 'initializeSportsbook',
    token: 'initializeSportsbook'
  }
}
</script>

<style scoped>
.sportsbook-page {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px); /* Adjust this value based on your header height */
  overflow: hidden;
}

.sportsbook-container {
  flex: 1;
  position: relative;
  overflow: hidden;
}

.sportsbook-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.error-message, .loading-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 18px;
  color: #333;
  text-align: center;
  padding: 20px;
}

.error-message {
  color: #ff0000;
}
</style>