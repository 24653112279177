<template>
  <div class="crash">
    <div class="crash-container">
      <transition name="fade" mode="out-in">
        <div
          v-if="socketCrash.connected === false"
          class="container-loading"
          key="loading"
        >
          <div class="loading-element"></div>
          <div class="loading-element"></div>
        </div>
        <div v-else class="container-data" key="data">
            <CrashControls />
            <div class="data-left">
              <div class="game-wrapper">
                <CrashHistory />
                <CrashGame />
              </div>
            </div>
          </div>
      </transition>
    </div>

    <!--<Bets/>-->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Bets from "@/components/bets/Bets";
import CrashGame from "@/components/crash/CrashGame";
import CrashHistory from "@/components/crash/CrashHistory";
import CrashControls from "@/components/crash/CrashControls";

export default {
  name: "Crash",
  metaInfo: {
    title: "Crash - Candy Casino",
  },
  components: {
    Bets,
    CrashGame,
    CrashHistory,
    CrashControls,
  },
  methods: {
    ...mapActions(["socketConnectCrash", "socketDisconnectCrash"]),
  },
  computed: {
    ...mapGetters(["socketCrash"]),
  },
  created() {
    this.socketConnectCrash();
  },
  beforeRouteLeave(to, from, next) {
    this.socketDisconnectCrash();
    next();
  },
};
</script>

<style scoped>
.crash {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 45px 0px;
}

.crash .crash-container {
  width: 100%;
  border-radius: 25px;
  /* background: var(--crash-background); */
  /* box-shadow: 0 4px 0 0 rgba(255, 255, 255, 0.25) inset; */
  margin-bottom: 32px;
}

.crash .container-loading {
  display: flex;
  justify-content: space-between;
  padding: 15px;
}

.crash .loading-element {
  height: 510px;
  position: relative;
  border-radius: 15px;
  background: #33052b;
  overflow: hidden;
}

.crash .loading-element:nth-child(1) {
  width: calc(100% - 290px);
}

.crash .loading-element:nth-child(2) {
  width: 275px;
}

.crash .loading-element::after {
  width: 100%;
  height: 100%;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  animation-name: loading_animation;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
  background: linear-gradient(
    to right,
    #ffffff00 0%,
    rgba(255, 255, 255, 0.1) 50%,
    #ffffff00 100%
  );
}

.crash .container-loading.fade-leave-active {
  transition: opacity 0.5s;
}

.crash .container-loading.fade-leave-to {
  opacity: 0;
}

.crash .container-data {
  display: flex;
  width: 100%;
  justify-content: space-around;
  padding: 28px;
}

.crash .container-data.fade-enter-active {
  transition: opacity 0.5s;
}

.crash .container-data.fade-enter-from {
  opacity: 0;
}

.crash .data-left {
  /* width: calc(100% - 325px); */
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: var(--background-primary);
  border-radius: 16px;
  position: relative;
  z-index: 1;
}
/* 
.crash .data-left::before{
  content: "";
  position: absolute;
  top: -6px;
  left: -6px;
  right: -6px;
  bottom: -6px;
  z-index: 0;
  border-radius: 20px;
  background: linear-gradient(180deg, #cad9eb, #7e8da5);
} */

.crash .data-left .game-wrapper{
  height: 100%;
  position: relative;
  display: flex;
  padding: 16px;
  flex-direction: column;
  gap: 16px;
  border-radius: 16px;
  z-index: 2;
  background: var(--background-secondary);
}

@keyframes loading_animation {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
}

@media only screen and (max-width: 1140px) {
  .crash .crash-container {
    width: 100%;
  }
}

@media only screen and (max-width: 950px) {
  .crash {
    padding: 25px 10px 443px 10px;
  }

  .crash .container-loading,
  .crash .container-data {
    flex-direction: column-reverse;
    padding: 10px;
  }

  .crash .loading-element {
    width: 100% !important;
  }

  .crash .loading-element:nth-child(2) {
    height: 200px;
    margin-top: 15px;
  }

  .crash .data-left {
    width: 100%;
  }
}
</style>
