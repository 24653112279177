<template>
  <div
    class="towers-row"
    v-bind:class="{
      'row-active':
        towersGame !== null &&
        towersGame.state !== 'completed' &&
        towersGame.revealed.length === row,
      'row-revealed':
        towersGame !== null && towersGame.revealed[row] !== undefined,
    }"
  >
    <div
      v-for="tile in towersGetTilesCount"
      v-bind:key="tile"
      class="row-tile"
      :class="{
        'tile-lose':
          towersGame !== null &&
          ((towersGame.revealed[row] !== undefined &&
            towersGame.revealed[row].tile === tile - 1 &&
            towersGame.revealed[row].row[tile - 1] === 'lose') ||
            (towersGame.state === 'completed' &&
              towersGame.revealed.length <= row &&
              towersGame.deck[row][tile - 1] === 'lose')),
        'tile-safe':
          towersGame !== null &&
          ((towersGame.revealed[row] !== undefined &&
            towersGame.revealed[row].tile === tile - 1 &&
            towersGame.revealed[row].row[tile - 1] === 'coin') ||
            (towersGame.state === 'completed' &&
              towersGame.revealed.length <= row &&
              towersGame.deck[row][tile - 1] === 'coin')),
      }"
    >
      <transition name="fade" mode="out-in">
        <div
          v-if="
            towersGame !== null &&
            towersGame.revealed[row] !== undefined &&
            towersGame.revealed[row].tile === tile - 1 &&
            towersGame.revealed[row].row[tile - 1] === 'coin'
          "
          class="tile-coin"
        >
          <span>{{
            towersFormatValue(towersGetRowCashout).split(".")[0]
          }}</span>
          .{{ towersFormatValue(towersGetRowCashout).split(".")[1] }}
        </div>
        <div
          v-else-if="
            towersGame !== null &&
            ((towersGame.revealed[row] !== undefined &&
              towersGame.revealed[row].tile === tile - 1 &&
              towersGame.revealed[row].row[tile - 1] === 'lose') ||
              (towersGame.state === 'completed' &&
                towersGame.revealed.length <= row &&
                towersGame.deck[row][tile - 1] === 'lose'))
          "
          class="tile-lose"
        ></div>
        <button
          v-else
          v-on:click="towersRevealButton(tile - 1)"
          class="button-reveal"
          v-bind:disabled="
            socketSendLoading !== null ||
            towersGame === null ||
            towersGame.state === 'completed' ||
            towersGame.revealed.length !== row
          "
        >
          <div class="button-inner">
            <div class="inner-value">
              <span>{{
                towersFormatValue(towersGetRowCashout).split(".")[0]
              }}</span>
              .{{ towersFormatValue(towersGetRowCashout).split(".")[1] }}
            </div>
          </div>
        </button>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import IconSkull from "@/components/icons/IconSkull";

export default {
  name: "TowersRow",
  components: {
    IconSkull,
  },
  props: ["row"],
  methods: {
    ...mapActions(["towersSendRevealSocket"]),
    towersFormatValue(value) {
      return parseFloat(Math.floor(value / 10) / 100)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    towersRevealButton(tile) {
      const data = { tile: tile };
      this.towersSendRevealSocket(data);
    },
  },
  computed: {
    ...mapGetters(["socketSendLoading", "towersRisk", "towersGame"]),
    towersGetTilesCount() {
      let count = 3;

      if (
        (this.towersGame !== null && this.towersGame.state !== "completed"
          ? this.towersGame.risk
          : this.towersRisk) === "medium"
      ) {
        return 2;
      }

      return count;
    },
    towersGetRowCashout() {
      const multiplierPerRow =
        this.towersRisk === "easy"
          ? 1.425
          : this.towersRisk === "medium"
            ? 1.9
            : 2.85;
      const amount = this.towersGame !== null ? this.towersGame.amount : 0;

      return Math.floor(amount * Math.pow(multiplierPerRow, this.row + 1));
    },
  },
};
</script>

<style scoped>
.towers-row {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 0 min(4vw, 30px);
  opacity: 0.25;
}

.towers-row:last-child {
  margin-top: 0;
}

.towers-row.row-active::before,
.towers-row.row-active::after {
  content: "";
  width: 3px;
  height: 45px;
  position: absolute;
  top: 0;
  border-radius: 3px;
  background: linear-gradient(255deg, #00ffc2 -20%, #00aa6d 100%);
}

.towers-row.row-active::before {
  left: 0;
}

.towers-row.row-active::after {
  right: 0;
}

.row-active {
  opacity: 1;
}

.row-revealed {
  opacity: 1;
}

.towers-row .row-tile {
  width: min(26vw, 130px);
  height: min(7vw, 36px);
  background-image: url("../../assets/img/towers/tower-middle.png");
  background-size: cover;
  background-position: center;
}

.towers-row .row-tile.tile-lose {
  background-image: url("../../assets/img/towers/tower-middle-bomb.png");
}

.towers-row .row-tile.tile-safe {
  background-image: url("../../assets/img/towers/tower-middle-coin.png");
}

.towers-row button.button-reveal {
  width: 100%;
  height: 100%;
}

.towers-row.row-active button.button-reveal {
  transition: transform 0.3s ease;
}

.towers-row.row-active button.button-reveal:hover {
  transform: scale(1.05);
}

.towers-row button.button-reveal .button-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.towers-row button.button-reveal .button-inner img,
.towers-row .coin-inner img {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}

.towers-row button.button-reveal .button-inner .inner-value,
.towers-row .coin-inner .inner-value {
  font-size: 11px;
  font-weight: 600;
  color: #bbbfd0;
}

.towers-row button.button-reveal .button-inner .inner-value span,
.towers-row .coin-inner .inner-value span {
  font-size: 15px;
  font-weight: 800;
  color: #ffffff;
}

.tile-coin {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tile-coin,
.tile-coin span {
  opacity: 0;
}

@media only screen and (max-width: 550px) {
}

@media only screen and (max-width: 500px) {
  .towers-row .coin-inner {
    padding: 0;
  }

  .towers-row .coin-inner img.image-knight {
    display: none;
  }
}
</style>
