<template>
  <div class="modal-terms">
    <div class="terms-header">
      <span class="gradient-green">TERMS OF SERVICE</span>
      <p>Welcome to Candy Casino! By using our website and services, you agree to be bound by the following terms and
        conditions (the "Terms of Service"). Please read them carefully.
      </p>
    </div>
    <div class="terms-list">

      <div class="list-element">
        <div class="element-title">1. Acceptance of Terms of Service</div>
        <div class="element-text">
          <p>1.1 These Terms of Service constitute a legally binding agreement between you and Candy Casino
            (the "Owner and Operator" of Candy Casino). By successfully registering an account on our website
            and providing your Growtopia credentials or authentication, you agree to be bound by these terms
            and conditions.
          </p>
          <p>1.2 We may modify these Terms of Service from time to time, and will post the most current
            version on our website. By continuing to use our website and services after any changes have
            been made, you are agreeing to be bound by the modified terms.
          </p>
          <p>1.3 Candy Casino is a virtual gaming website that offers fun and immersive games based on the virtual
            currency known as Robux. Candy Casino provides free-to-play opportunities on the site. To participate for
            free, you must register an account on Candy Casino. By doing so, you can join our rain feature, which
            distributes free Robux to users who participate, allowing you to play on our site completely for free.
            With these Robux, you are able to play on the site, with the exact same odds as any player who chooses
            to deposit. You are also able to play Mines, Towers and Crash with 0 balance. It's important to note
            that, even when playing for free, you must agree to and comply with these Terms of Service.
          </p>
          <p>1.4 Robux</p>
          <p>a) Please note that Robux has no real-world value and is for entertainment purposes only. You are
            not allowed to transfer, attempt to transfer, or offer to transfer Robux won off Candy Casino for cash,
            cash equivalent, or any thing of value on a third-party platform.
          </p>
          <p>b) Candy Casino will give you Robux FREE OF CHARGE on:
            <ul>
              <li>Rain Distribution - Rains occur on the site which spread Robux to all users who participate
                in them. To claim rains, you simply need to register an account on Candy Casino and complete KYC to
                collect them.
              </li>
              <li>Promotional Giveaways - Promotional giveaways organized by Candy Casino on its social media accounts
                (Discord, Twitter, YouTube, Twitch, etc.).
              </li>
            </ul>
          </p>
          <p>c) You may also win more Robux when you play games on Candy Casino, and you may purchase more Robux on
            Candy Casino
            to play.
          </p>
          <p>1.5 You must be 18 years of age or the age of majority in your jurisdiction to use our website and
            services.
          </p>
          <p>1.6 The following jurisdictions are prohibited from using our services: Afghanistan; Algeria; Angola;
            Bangladesh;
            Bolivia; Burkina Faso; Burundi; Cambodia; Cameroon; Central African Republic; Chad; Colombia; Congo
            Democratic;
            Côte d’Ivoire; Cyprus; Egypt; Eritrea; Ethiopia; Gambia; Guinea; Guinea Bissau; Haiti; India; Indonesia;
            Iran; Iraq;
            Israel; Kenya; Laos; Lebanon; Lesotho; Liberia; Libya; Madagascar; Mali; Mozambique; Myanmar; Nepal;
            Nigeria; North Korea;
            Pakistan; Palestinian Territory; Panama; Paraguay; Philippines; Russia; Sao Tome and Principe; Saudi Arabia;
            Sierra Leone;
            Somalia; Sri Lanka; Sudan; Syria; Tajikistan; Tanzania; Tunisia; Turkey; Uganda; Ukraine; United Kingdom;
            Vanuatu; Venezuela;
            Yemen; Zambia; Zimbabwe. Additionally, our services are unavailable in the State of Washington in the United
            States of America.
            Residents of the State of Washington are not allowed to register or use our services.
          </p>
        </div>
      </div>

      <div class="list-element">
        <div class="element-title">2. Registration and Account Security</div>
        <div class="element-text">
          <p>2.1 In order to use our website and services, you must register an account by providing your
            Growtopia credentials or authentication. You are solely responsible for maintaining the security of
            your account and for all activities that occur under your account.
          </p>
          <p>2.2 You agree to immediately notify us of any unauthorized use of your account or any other
            breach of security.
          </p>
          <p>2.3 We reserve the right to ask for any KYC (know your customer) documentation we deem
            necessary to determine the identity and location of a user. We may restrict the service, payment
            or withdrawal until identity is sufficiently determined or for any other reason in our sole
            discretion.
          </p>
        </div>
      </div>

      <div class="list-element">
        <div class="element-title">3. User Conduct</div>
        <div class="element-text">
          <p>3.1 You agree to use our website and services only for lawful purposes and in accordance with
            these Terms of Service. You may not use our website or services in any way that infringes the
            intellectual property rights of others, violates any applicable laws or regulations, is fraudulent,
            obscene, or otherwise offensive, or is harmful to our website or services, or to other users of our
            website or services. Additionally, you are solely responsible for the content you post or transmit
            through our website and services, including any chat rooms or forums. You agree not to post or
            transmit any content that infringes the intellectual property rights of others, violates any applicable
            laws or regulations, is fraudulent, obscene, or otherwise offensive, or is harmful to our website or
            services, or to other users of our website or services.
          </p>
          <p>3.2 You must ensure that it is legal in your jurisdiction to use our website and services.</p>
          <p>3.3 In cases of fraudulent use of credit cards, we will notify the appropriate law enforcement agencies
            and will terminate your access to our website.
          </p>
          <p>3.4 We reserve the right to remove any content that violates these terms, or that we determine is otherwise
            inappropriate, at our sole discretion.
          </p>
        </div>
      </div>

      <div class="list-element">
        <div class="element-title">4. Participating in Sweepstakes</div>
        <div class="element-text">
          <p>4.1. Candy Casino is a free-to-play website. You can obtain free Candy Casino coins from various sources,
            including rain, promo codes, rewards, daily cases and giveaways. Candy Casino coins have no monetary value,
            and their purpose is solely for entertainment.
          </p>
          <p>4.2. You can exchange your limiteds or Robux for Candy Casino coins or purchase Candy Casino coins directly
            on our website using various payment methods, including cryptocurrencies.
          </p>
          <p>4.3. Additional payment methods, such as bank transfers, credit card processing, and gift cards may
            be accepted on our site. Users will be notified of their availability when attempting to exchange limiteds
            or purchase Candy Casino coins.
          </p>
          <p>4.4. To ensure security and compliance, Candy Casino reserves the right to perform identity verification
            (KYC)
            when using cryptocurrencies, cards, or other payment methods to acquire Candy Casino coins. Failure to
            provide
            requested documents may result in account termination.
          </p>
          <p>4.5. Candy Casino retains the right to adjust the coin rate.</p>
          <p>4.6. We provide data on digital assets used for purchasing Candy Casino coins, but we make no
            representations
            regarding their value, quality, suitability, or accuracy. These assets are used for entertainment purposes.
          </p>
          <p>4.7. Candy Casino coins are given out for free. Games like crash, mines, towers, and roll can be played
            with 0
            coins.
          </p>
          <p>4.8. Users who acquire coins for free have the same winning odds as users who purchase Candy Casino
            coins.
          </p>
        </div>
      </div>

      <div class="list-element">
        <div class="element-title">5. Real World Trading</div>
        <div class="element-text">
          <p>5.1. Candy Casino strictly prohibits real-world trading. Sweepstakes prizes are non-transferable.</p>
          <p>5.2. Candy Casino coins and limiteds hold no monetary value, are not private property, and cannot be sold,
            transferred, returned, or exchanged for real-world money or items of value.
          </p>
          <p>5.3. You agree not to sell, transfer, or aid others in the exchange of Candy Casino coins or limiteds. Any
            attempt at real-world trading will result in account termination.
          </p>
          <p>5.4. Candy Casino coins do not grant ownership rights, titles, or monetary value; they do not constitute
            real
            currency or possess monetary worth within your account.
          </p>
        </div>
      </div>

      <div class="list-element">
        <div class="element-title">6. Prize Claim & Redemption</div>
        <div class="element-text">
          <p>6.1. You can swap your Candy Casino tokens for limiteds or Robux using our marketplace feature.</p>
          <p>6.2. Due to factors beyond Candy Casino's control, some withdrawals may experience delays. While most
            withdrawals
            will be processed instantly, they may take up to 24 hours. If you have any concerns or questions about your
            withdrawal, please contact our support team.
          </p>
          <p>6.3. After a user purchases Candy Casino tokens, they must wager an amount equal to their purchase before
            they can
            withdraw.
          </p>
        </div>
      </div>

      <div class="list-element">
        <div class="element-title">7. Multiple Accounts</div>
        <div class="element-text">
          <p>7.1 You can register and use only one account on Candy Casino.</p>
          <p>7.2 Only one account can be used for each household, IP Address
            and computer or device. If two or more users share the same household,
            IP Address, computer or device, we must grant permission to the account
            holder in advance.
          </p>
          <p>7.3 If you attempt to register or sign up with more than one account,
            we may block or close any or all of your accounts at our discretion.
          </p>
          <p>7.4 If we block your IP Address, you agree not to implement any measure
            to obtain access to Candy Casino, such as using a VPN service or other masking
            effort.
          </p>
        </div>
      </div>

      <div class="list-element">
        <div class="element-title">8. Rain Distribution & Rewards</div>
        <div class="element-text">
          <p>8.1 Candy Casino offers a rewards program called "rain". A base of 250 coins
            plus a percentage of all coins spent on the site during the stated time period
            (30 minutes) is added to each rain pot. Each rain lasts for 28 minutes before
            giving a 2 minute time period allowing users to join. During the 2 minute time
            period which allows users to join, any user who is level 5 on our site and has 100
            Robux deposited within the last 2 weeks, or has completed KYC on their account can
            join and receive a share of the rain's total pot. Rain is a reward for Candy Casino
            users to collect while they are actively playing on the site as part of our free
            to play system. Abusing rain contrary to its intended purpose may result in
            termination of your account at Candy Casino's sole discretion.
          </p>
          <p>8.2 Candy Casino also deploys another rewards program called "daily cases''. Daily
            cases are allowed to be opened by users once per day depending on their correlated
            level on Candy Casino. Candy Casino provides a daily case that is unlocked once per day to
            users as part of our free-to-play system. To gain access, they must simply link their
            Discord account and join our discord server. Once doing so, the user is able to open
            the case once per day with the same odds as anyone else who uses the website. Candy Casino
            has the right to adjust the case contents and percentages of every daily case at its
            discretion.
          </p>
          <p>8.3 Please note that the Rain feature listed on Candy Casino has guidelines set in place to
            prevent users from abusing it. We are unable to list every method a user is able to abuse the
            rain. If a user is caught abusing the rain while using Candy Casino as a free-to-play method, their
            access to our service will be immediately terminated.
            <ul>
              <li>Users creating and using multiple accounts to claim rains at the same time are subject
                to account termination across every account ever used on Candy Casino.
              </li>
              <li>Users joining and tipping their claimed funds to other users without properly using
                said funds to play the games listed on our site are subject to account termination across
                every account ever used on Candy Casino.
              </li>
              <li>Users claiming multiple rains and allowing them to build up their balance without using
                the coins to genuinely participate on the games listed on our site are subject to account termination
                across every account ever used on Candy Casino.
              </li>
              <li>Users depositing the minimum amount to claim rains every 2 week period without using the site as a
                free-to-play feature are subject to account termination across every account ever used on Candy Casino.
              </li>
            </ul>
          </p>
          <p></p>
          <p></p>
        </div>
      </div>

      <div class="list-element">
        <div class="element-title">9. Purchase and Refunds</div>
        <div class="element-text">
          <p>9.1 Candy Casino does not issue refunds on digital products once the order is confirmed and the product is
            sent. If
            you have any issues with receiving or downloading the product, please contact us at support@Candy Casino.com
            and we will
            assist you.
          </p>
          <p>9.2 Refunds may be given at our discretion for technical issues determined by Candy Casino.</p>
          <p>9.3 We reserve the right to alter and change any fees, prices or billing methods, including the addition of
            administrative
            or supplemental charges for any feature, with or without prior notice to you.
          </p>
          <p>9.4 If you make a purchase on Candy Casino that results in a chargeback, we may terminate your account. You
            agree that you
            must contact Candy Casino to seek a resolution of any issue before initiating a chargeback. We reserve the
            right to withhold any
            Robux generated from transactions that result in a chargeback.
          </p>
          <p>9.5 You are responsible for any credit card chargebacks, dishonored checks, and any related fees that Candy
            Casino incurs with
            respect to your account, along with any additional fees or penalties imposed by our third-party billing
            agent.
          </p>
          <p>9.6 Candy Casino utilizes third-party payment processors and gateways, and we reserve the right to contract
            with additional
            third-party payment processors and gateways in our sole discretion to process any and all payments
            associated with Candy Casino.
            Third parties may impose additional terms and conditions governing payment processing. You are responsible
            for abiding by such
            terms. We disclaim any liability associated with your violation of such terms.
          </p>
        </div>
      </div>

      <div class="list-element">
        <div class="element-title">10. Disclaimer of Warranties</div>
        <div class="element-text">
          <p>10.1 Our website and services are provided on an "as is" and "as available" basis. We make no warranties,
            express or implied,
            including but not limited to warranties of merchantability, fitness for a particular purpose, and
            non-infringement.
          </p>
          <p>10.2 We do not warrant that our website or services will be uninterrupted, error-free, or free from viruses
            or other harmful
            components.
          </p>
          <p>10.3 We do not endorse, and are not responsible for, any content posted or transmitted by other users of
            our website or services.
          </p>
        </div>
      </div>

      <div class="list-element">
        <div class="element-title">11. Limitation of Liability</div>
        <div class="element-text">
          <p>11.1 In no event will we be liable for any direct, indirect, incidental, consequential, or punitive damages
            arising out of or in
            connection with your use of our website or services, or with any delay or interruption of such use.
          </p>
          <p>11.2 Our liability to you or any third party in any circumstance is limited to the greater of (a) the
            amount of fees, if any, you
            pay to us in the twelve months prior to the action giving rise to liability, and (b) $100.
          </p>
        </div>
      </div>

      <div class="list-element">
        <div class="element-title">12. Indemnification</div>
        <div class="element-text">
          <p>12.1 You agree to defend, indemnify, and hold us and our affiliates harmless from and against any claims,
            liabilities, damages, losses,
            and expenses, including but not limited to attorneys' fees, arising out of or in any way connected with your
            use of our website or services,
            or with any content you post or transmit through our website and services.
          </p>
        </div>
      </div>

      <div class="list-element">
        <div class="element-title">13. Privacy Policy</div>
        <div class="element-text">
          <p>13.1 By using our website and services, you acknowledge and consent to the possibility that we may disclose
            your information as appropriate
            to comply with the legal process or as otherwise permitted by the privacy policy of Candy Casino (the Owner
            and Operator of Candy Casino).
          </p>
          <p>13.2 Our privacy policy is available at https://www.Candy Casino.com/privacy.</p>
        </div>
      </div>

      <div class="list-element">
        <div class="element-title">14. Termination</div>
        <div class="element-text">
          <p>14.1 We reserve the right to terminate your access to our website and services at any time, with or without
            cause, and with or without notice.
          </p>
          <p>14.2 We may also terminate your access if you violate these Terms of Service or if you engage in any
            conduct that we, in our sole discretion, believe
            is offensive or harmful to us or to any other user of our website or services.
          </p>
        </div>
      </div>

      <div class="list-element">
        <div class="element-title">15. Governing Law and Dispute Resolution</div>
        <div class="element-text">
          <p>15.1 These Terms of Service and any disputes arising out of or in connection with them will be governed by
            the laws of the State of Delaware, without
            giving effect to any principles of conflicts of law.
          </p>
          <p>15.2 Any disputes arising out of or in connection with these Terms of Service will be resolved through
            binding arbitration in accordance with the commercial
            arbitration rules of the American Arbitration Association. The arbitration will be conducted in Delaware.
          </p>
        </div>
      </div>

      <div class="list-element">
        <div class="element-title">16. Miscellaneous</div>
        <div class="element-text">
          <p>16.1 These Terms of Service constitute the entire agreement between you and us with respect to your use of
            our website and services.
          </p>
          <p>16.2 If any provision of these Terms of Service is found to be unlawful, void, or for any reason
            unenforceable, that provision will be
            deemed severable from these terms and will not affect the validity and enforceability of any remaining
            provisions.
          </p>
          <p>16.3 Our failure to enforce any right or provision of these Terms of Service will not be deemed a waiver of
            such right or provision.
          </p>
          <p>16.4 These Terms of Service do not create any agency, partnership, joint venture, employment, or franchise
            relationship between you and us.
          </p>
          <p>16.5 Candy Casino reserves the right to adjust your balance at its sole discretion. Candy Casino is in no
            way affiliated with the Ubisoft Entertainment or
            its trademarks.
          </p>
        </div>
      </div>

      <div class="list-element">
        <div class="element-title">Contact Us</div>
        <div class="element-text">
          <p>If you have any questions about these Terms of Service, please contact us at
            support@Candy Casino.com.
          </p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalTerms'
}
</script>

<style scoped>
.modal-terms {
  width: 1000px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 30px 0 30px;
  border-radius: 15px;
  background: radial-gradient(100% 100% at 50% -30%, rgba(0, 255, 194, 0.2) 0%, rgba(0, 0, 0, 0) 100%),
  linear-gradient(255deg, #07263d 0%, #07243a 100%);
}

.modal-terms .terms-header {
  width: 100%;
  padding-bottom: 30px;
  border-bottom: 1px solid #335361;
}

.modal-terms .terms-header span {
  font-size: 32px;
  font-weight: 900;
}

.modal-terms .terms-header p {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #bbbfd0;
}

.modal-terms .terms-list {
  width: 100%;
  height: 600px;
  padding: 30px 20px 30px 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

.modal-terms .terms-list::-webkit-scrollbar {
  width: 5px;
  height: 0;
}

.modal-terms .terms-list::-webkit-scrollbar-track {
  margin: 30px 0;
  background-color: #062234;
}

.modal-terms .terms-list::-webkit-scrollbar-thumb {
  background-color: #1e5164;
}

.modal-terms .list-element {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
}

.modal-terms .list-element:first-child {
  margin-top: 0;
}

.modal-terms .element-title {
  font-size: 24px;
  font-weight: 800;
  color: #ffffff;
}

.modal-terms .element-text {
  width: 100%;
  margin-top: 15px;
  padding-left: 30px;
}

.modal-terms .element-text p {
  margin-top: 15px;
  font-size: 14px;
  font-weight: 600;
  color: #bbbfd0;
}

.modal-terms .element-text p:first-child {
  margin-top: 0;
}

.modal-terms .element-text ul {
  margin-top: 15px;
  padding-left: 50px;
}

@media only screen and (max-width: 1190px) {

  .modal-terms {
    width: calc(100vw - 20px);
  }

}

@media only screen and (max-width: 700px) {

  .modal-terms {
    padding: 80px 15px 0 15px;
  }

}
</style>
