<template>
  <div class="game-container">
    <!-- Game content -->
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Game',
  props: ['gameId'],
  data() {
    return {
      gameUrl: null,
    }
  },
  computed: {
    isDemo() {
      return this.$route.query.demo === 'true'
    }
  },
  methods: {
    ...mapActions('slotegrator', ['initGame', 'initDemoGame']),
    async startGame() {
      try {
        const initMethod = this.isDemo ? this.initDemoGame : this.initGame;
        const gameData = {
          game_uuid: this.gameId,
          // ... other necessary data
        };
        const initData = await initMethod(gameData);
        if (initData && initData.url) {
          this.gameUrl = initData.url;
        } else {
          throw new Error('Game URL not received from the server');
        }
      } catch (error) {
        console.error('Error starting game:', error);
        this.$store.dispatch('notificationShow', error.message || 'Failed to start the game. Please try again.');
      }
    }
  },
  mounted() {
    this.startGame();
  }
}
</script>