<template>
  <div class="modal-captcha">
    <div class="captcha-title">
      <span>Complete Captcha</span>
    </div>
    <div class="captcha-content">
      <VueHcaptcha
          ref="modalCaptcha"
          v-bind:sitekey="modalCaptchaKey"
          @verify="modalOnVerify"
          @expired="modalOnExpire"
          @challengeExpired="modalOnExpire"
      />
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';

export default {
  name: 'ModalTip',
  components: {
    VueHcaptcha
  },
  data() {
    return {
      modalCaptchaKey: process.env.VUE_APP_HCAPTCHA_KEY
    };
  },
  methods: {
    ...mapActions([
      'modalsSetShow',
      'authSendCredentialsLogin',
      'authSendCredentialsRegister',
      'authSendCredentialsRequest',
      'authSendCredentialsReset',
      'affiliatesSendClaimCodeSocket',
      'generalSendPromoClaimSocket',
      'cashierSendGiftRedeemSocket',
      'generalSendRainJoinSocket'
    ]),
    modalOnVerify(token, eKey) {
      const data = {...this.modalsData.data, captcha: token};

      if (this.modalsData.typeCaptcha === 'credentialsLogin') {
        this.authSendCredentialsLogin(data);
      } else if (this.modalsData.typeCaptcha === 'credentialsRegister') {
        this.authSendCredentialsRegister(data);
      } else if (this.modalsData.typeCaptcha === 'credentialsRequest') {
        this.authSendCredentialsRequest(data);
      } else if (this.modalsData.typeCaptcha === 'credentialsReset') {
        this.authSendCredentialsReset(data);
      } else if (this.modalsData.typeCaptcha === 'affiliatesClaim') {
        this.affiliatesSendClaimCodeSocket(data);
      } else if (this.modalsData.typeCaptcha === 'promoClaim') {
        this.generalSendPromoClaimSocket(data);
      } else if (this.modalsData.typeCaptcha === 'giftRedeem') {
        this.cashierSendGiftRedeemSocket(data);
      } else if (this.modalsData.typeCaptcha === 'rainJoin') {
        this.generalSendRainJoinSocket(data);
      }

      this.modalsSetShow(null);
    },
    modalOnExpire() {
      this.modalsSetShow(null);
    }
  },
  computed: {
    ...mapGetters([
      'modalsData'
    ])
  },
  mounted() {
    this.$refs.modalCaptcha.reset();
  }
};
</script>

<style scoped>
.modal-captcha {
  width: 773px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px 0 48px 0;
  border-radius: 15px;
  background: var(--background-secondary)
}

.modal-captcha .captcha-title {
  font-family: Montserrat, sans-serif;
  text-align: center;
  font-size: 32px;
  font-weight: 900;
  color: white;
}

.modal-captcha .captcha-content {
  width: 303px;
  height: 76px;
  margin-top: 35px;
  border-radius: 4px;
}

@media only screen and (max-width: 793px) {

  .modal-captcha {
    width: calc(100vw - 30px);
    padding: 85px 20px 48px 20px;
  }

}
</style>
