import axios from 'axios';

const state = {
  games: [],
  loading: false,
  error: null,
  activeCategory: 'All Games',
  selectedProvider: '',
  searchQuery: '',
  categories: ['All Games'],
  providers: [],
  hasMoreGames: true,
  currentOffset: 0
};

const getters = {
  isLoading: state => state.loading,
  hasError: state => !!state.error,
  errorMessage: state => state.error,
};

const actions = {
  async fetchGames({ commit, state }, { loadMore = false, limit = 30 }) {
    commit('SET_LOADING', true);
    const offset = loadMore ? state.currentOffset : 0;
    try {
      const response = await axios.get('/api/v1/slotegrator/games', {
        params: {
          offset,
          limit,
          category: state.activeCategory !== 'All Games' ? state.activeCategory : undefined,
          provider: state.selectedProvider,
          search: state.searchQuery
        }
      });
      const { games, totalGames, categories, providers } = response.data;
      if (loadMore) {
        commit('ADD_GAMES', games);
      } else {
        commit('SET_GAMES', games);
      }
      commit('SET_HAS_MORE_GAMES', state.games.length < totalGames);
      commit('SET_CURRENT_OFFSET', state.games.length);
      if (!loadMore) {
        commit('SET_CATEGORIES', categories);
        commit('SET_PROVIDERS', providers);
      }
    } catch (error) {
      commit('SET_ERROR', error.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async initGame({ commit }, gameData) {
    try {
      const response = await axios.post('/api/v1/slotegrator/init', gameData);
      return response.data;
    } catch (error) {
      commit('SET_ERROR', error.message);
      throw error;
    }
  },
  async initDemoGame({ commit }, gameData) {
    try {
      const response = await axios.post('/api/v1/slotegrator/init-demo', gameData);
      return response.data;
    } catch (error) {
      commit('SET_ERROR', error.message);
      throw error;
    }
  },
  setActiveCategory({ commit, dispatch }, category) {
    commit('SET_ACTIVE_CATEGORY', category);
    commit('SET_SELECTED_PROVIDER', ''); // Reset provider when changing category
    commit('RESET_GAMES');
    dispatch('fetchGames', { loadMore: false });
  },
  setSelectedProvider({ commit, dispatch }, provider) {
    commit('SET_SELECTED_PROVIDER', provider);
    commit('RESET_GAMES');
    dispatch('fetchGames', { loadMore: false });
  },
  setSearchQuery({ commit, dispatch }, query) {
    commit('SET_SEARCH_QUERY', query);
    commit('RESET_GAMES');
    dispatch('fetchGames', { loadMore: false });
  },
};

const mutations = {
  SET_GAMES(state, games) {
    state.games = games;
  },
  ADD_GAMES(state, games) {
    state.games = [...state.games, ...games];
  },
  SET_LOADING(state, isLoading) {
    state.loading = isLoading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_ACTIVE_CATEGORY(state, category) {
    state.activeCategory = category;
  },
  SET_SELECTED_PROVIDER(state, provider) {
    state.selectedProvider = provider;
  },
  SET_SEARCH_QUERY(state, query) {
    state.searchQuery = query;
  },
  SET_CATEGORIES(state, categories) {
    state.categories = categories;
  },
  SET_PROVIDERS(state, providers) {
    state.providers = providers;
  },
  SET_HAS_MORE_GAMES(state, hasMore) {
    state.hasMoreGames = hasMore;
  },
  SET_CURRENT_OFFSET(state, offset) {
    state.currentOffset = offset;
  },
  RESET_GAMES(state) {
    state.games = [];
    state.currentOffset = 0;
    state.hasMoreGames = true;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};