<template>
  <GameSlider
    sliderClass="featured-games"
    collection="featured2ndhomes"
    providerName="Featured Games"
  />
</template>

<script>
import GameSlider from '@/components/GameSlider.vue'

export default {
  name: 'SecondFeatureGamesSlider',
  components: {
    GameSlider
  }
}
</script>

<style scoped>
@import '../../styles/gameCard.css'; /* Ensure gameCard.css is imported */
</style>