<template>
  <div class="home-banner-user-large">
    <div class="user-inner">
      <div class="image-container">
        <img :src="backgroundImage" alt="Background" draggable="false"/>
      </div>

      <img :src="character" class="character" alt="Character"/>

      <div v-if="authUser.user === null" class="inner-guest">
        <span class="title">JOIN THE MOST BAZED CASINO!</span>
        <ul>
          <li class="subtitle">
            <img src="@/assets/img/crown.png" alt="Crown Image"/>
            <span>
              <strong>1 on 1 VIP service</strong>
              &
              <strong>Instant Rakeback!</strong>
            </span>
          </li>
          <li class="subtitle">
            <img src="@/assets/img/chest.png" alt="Chest Image"/>
            <span>
              <strong>Thunder Tip</strong>
              &
              <strong>Automated VIP program!</strong>
            </span>
          </li>
        </ul>
        <!--<span class="subtitle">Instant rakeback, Thunder Tip AND 1 on 1 personal VIP service</span>-->
        <AuthButton/>
      </div>
      <div v-else class="inner-auth" :class="['auth-' + homeGetLevelColor]">
        <span class="title">WELCOME BACK, {{ authUser.user.username }}</span>
        <!--<div class="auth-info">
          <div class="info-avatar">
            <AvatarImage :image="authUser.user.avatar"/>
          </div>
          <span v-html="authUser.user.username"></span>
        </div>-->
        <div class="auth-level">
          <div class="level-box">
            <div class="box-inner">{{ homeGetLevel >= 99 ? 99 : homeGetLevel }}</div>
          </div>
          <div class="level-progress">
            <div class="progress-bar"
                 :style="{ 'width': (100 / homeGetLevelBet) * homeGetLevelProgress + '%' }"></div>
            <div class="progress-text">
              <span>{{ parseFloat(homeGetLevelProgress).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
                /
              </span>
              <span class="text-target">
                {{ parseFloat(homeGetLevelBet).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
              </span>
            </div>
          </div>
          <div class="level-box">
            <div class="box-inner">{{ homeGetLevel >= 99 ? 100 : homeGetLevel + 1 }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import AuthButton from '@/components/AuthButton';
import AvatarImage from '@/components/AvatarImage';

export default {
  name: 'HomeBannerUserLarge',
  components: {
    AuthButton,
    AvatarImage
  },
  props: {
    backgroundImage: {
      type: String,
      required: true
    },
    logo: {
      type: String,
      default: require('@/assets/img/logo.png')
    },
    character: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters([
      'authUser'
    ]),
    homeGetLevel() {
      let level = Math.floor(Math.pow(this.authUser.user.xp / 1000 / 100, 1 / 3));
      return level >= 100 ? 100 : level;
    },
    homeGetLevelColor() {
      let color = '';
      if (this.homeGetLevel >= 2 && this.homeGetLevel < 26) {
        color = 'blue';
      } else if (this.homeGetLevel >= 26 && this.homeGetLevel < 51) {
        color = 'green';
      } else if (this.homeGetLevel >= 51 && this.homeGetLevel < 76) {
        color = 'orange';
      } else if (this.homeGetLevel >= 76 && this.homeGetLevel < 100) {
        color = 'red';
      } else if (this.homeGetLevel >= 100) {
        color = 'purple';
      }
      return color;
    },
    homeGetLevelBet() {
      return this.homeGetLevel >= 100 ? 2970100 : (Math.pow(this.homeGetLevel + 1, 3) - Math.pow(this.homeGetLevel, 3)) * 100;
    },
    homeGetLevelProgress() {
      return Math.floor((this.homeGetLevel >= 100 ? 2970100 * 1000 : this.authUser.user.xp - (Math.pow(this.homeGetLevel, 3) * 100 * 1000)) / 10) / 100;
    }
  },
  methods: {
    ...mapActions(['modalsSetShow']),
    openDepositModal() {
      this.modalsSetShow('Cashier');
    }
  }
};
</script>

<style scoped>
.home-banner-user-large {
  width: 100%;
  height: min(60vw, 25rem);
  position: relative;
  z-index: 1;
}

.user-inner {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 5%;
  border-radius: 15px;
  /* background: var(--blue-gradient-rain); */
  background-color: black;
  overflow: hidden;
  z-index: 1;
}

.image-container {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* object-position: right; */
  object-position: top right;
  user-select: none;
}
.title {
  max-width: 50%;
  font-size: min(7vw, 50px);
  font-weight: 800;
  text-shadow: 0 0 25px var(--highlight-pink);
  color: var(--highlight-white);
}

.subtitle {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.75rem;
  font-size: min(3vw, 17px);
  font-weight: 500;
  color: var(--highlight-white);
}

.subtitle img {
  width: 2rem;
}

.inner-guest,
.inner-auth {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  z-index: 3;
  position: relative;
  gap: 1vw;
}

.inner-guest ul {
  display: flex;
  flex-direction: column;
}

.inner-guest ul li {
  color: white;
  font-weight: 500;
  list-style-type: none;
}

.inner-guest button {
  margin-top: 1rem;
  width: 35%;
}

.auth-info {
  display: flex;
  align-items: center;
}

.auth-info span {
  font-size: 1.5vw;
  font-weight: 600;
  color: #ffffff;
}

.info-avatar {
  width: 3vw;
  height: 3vw;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-right: 10px;
  border-radius: 50%;
  border: 2px solid #9e9e9e;
  overflow: hidden;
}

.auth-level {
  bottom: 0;
  width: 40%;
  display: flex;
  align-items: center;
  z-index: 1;
  color: white;
  margin-top: 1rem;
}

.level-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  background-color: var(--highlight-pink, #333);
  border-radius: 50%;
  font-size: 1rem;
  font-weight: bold;
  color: var(--highlight-white);
}

.level-progress {
  flex: 1;
  height: 1rem;
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 0.5rem;
  margin: 0 1rem;
  position: relative;
}

.progress-bar {
  height: 100%;
  background-color: var(--highlight-pink);
  border-radius: 0.5rem;
}

.progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.7rem;
  font-weight: bold;
  color: var(--highlight-white);
}

.text-target {
  color: var(--highlight-yellow);
}

.character {
  position: absolute;
  bottom: -80px;
  right: 100px;
  height: 110%;
  object-fit: contain;
  z-index: 2;
  user-select: none;
 
}

@media only screen and (max-width: 1176px) {
  .auth-info span {
    font-size: 2.25vw;
  }

  .info-avatar {
    width: 4vw;
    height: 4vw;
  }

  .auth-level {
    width: 50%;
  }

  .title{
    max-width: 65%;
  }
}

@media only screen and (max-width: 768px) {
  .auth-info span {
    font-size: 3vw;
  }

  .info-avatar {
    width: 6vw;
    height: 6vw;
  }

  .auth-level {
    width: 100%;
  }

  .title {
    max-width: 90%;
  }
}

@media only screen and (max-width: 480px) {
  .inner-guest button {
    width: 100%;
  }

  .inner-auth {
    align-items: center;
  }

  .auth-info span {
    font-size: 4vw;
  }

  .info-avatar {
    width: 8vw;
    height: 8vw;
  }

  .image-container img {
    filter: brightness(0.5);
  }

  .inner-guest {
    align-items: center;
  }

  .inner-guest ul {
    align-items: center;
  }

  .auth-button {
    width: 100%;
  }

  .title {
    max-width: none;
    text-align: center;
  }

  .subtitle {
    text-align: center;
  }

  .subtitle img {
    display: none;
  }

  .character {
    display: none;
  }
}
</style>