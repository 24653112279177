<template>
  <div class="blackjack-overview">
    <div class="blackjack-overview-background">
      <img class="sign" src="@/assets/img/blackjack/blackjack-lobby-sign.png" />
      <div class="overview-standard">
        <div class="standard-header">
          <div class="header-title">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                d="M7.14016 0.607573C8.7971 0.63085 10.1568 1.97094 10.1568 3.58702C10.1568 5.21973 8.77319 6.56647 7.09575 6.56647C6.59695 6.56647 6.12208 6.44676 5.70186 6.23394C5.063 5.91139 4.55054 5.3727 4.2704 4.72094C4.2499 4.67439 4.2294 4.62451 4.21232 4.57796C4.09616 4.26538 4.03467 3.93285 4.03467 3.5837C4.03467 1.88115 5.4183 0.604248 7.09575 0.604248C7.10941 0.607573 7.12308 0.607573 7.14016 0.607573Z"
                fill="#fff"
              />
              <path
                d="M4.76701 8.06971C3.44487 8.59178 2.35163 9.54281 1.67177 10.7499C1.30963 11.395 1.06707 12.1132 0.974824 12.8781C0.90308 13.3037 1.19347 13.5863 1.55903 13.5863H12.6384C13.0039 13.5863 13.2943 13.2305 13.2226 12.8781C12.857 9.89861 10.2332 7.55762 7.1004 7.55762C6.59136 7.55762 6.0789 7.62745 5.6416 7.77043C5.57327 7.78706 5.5152 7.81034 5.46395 7.84027C5.22139 7.90677 4.99249 7.97993 4.76701 8.06971Z"
                fill="#fff"
              />
            </svg>
            <span>STANDARD TABLES</span>
            <div class="title-limits">
              (
              <img src="@/assets/img/icons/coin.svg" alt="icon" />
              <div class="limits-value">
                <span>0</span>.10 <span>- 1,000</span>.00
              </div>
              )
            </div>
          </div>
        </div>
        <div class="standard-content">
          <transition name="fade" mode="out-in">
            <div
              v-if="socketBlackjack.connected === false"
              class="content-loading"
              key="loading"
            >
              Loading...
            </div>
            <div v-else class="content-list" key="data">
              <BlackjackTablesElement
                v-for="table of blackjackGetTables.standard"
                v-bind:key="table.table"
                v-bind:table="table"
              />
            </div>
          </transition>
        </div>
      </div>
      <div class="overview-whale">
        <div class="whale-header">
          <div class="header-title">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="14"
              viewBox="0 0 16 14"
              fill="none"
            >
              <path
                d="M1.51138 0C2.21594 0 2.83147 0.386703 3.15494 0.957972C3.47837 0.386703 4.09391 0 4.7985 0H6.30986V1.82442C6.30986 2.85874 5.46427 3.70022 4.42487 3.70022H4.36219V4.55078C4.36219 5.23886 4.92469 5.79862 5.61612 5.79862H13.9486C15.0797 5.79862 16 6.71444 16 7.84008V10.0363C16 11.162 15.0797 12.0778 13.9486 12.0778H9.19391C9.07341 13.1575 8.15081 14 7.03416 14H6.79276V12.0778H6.34056C3.91831 12.0778 1.94769 10.1167 1.94769 7.70624V3.70022H1.885C0.845593 3.70022 0 2.85874 0 1.82442V0H1.51138ZM9.40626 10.2964H10.3438V9.36343H9.40626V10.2964Z"
                fill="var(--blackjack-secondary)"
              />
              <path
                d="M1.51138 0C2.21594 0 2.83147 0.386703 3.15494 0.957972C3.47837 0.386703 4.09391 0 4.7985 0H6.30986V1.82442C6.30986 2.85874 5.46427 3.70022 4.42487 3.70022H4.36219V4.55078C4.36219 5.23886 4.92469 5.79862 5.61612 5.79862H13.9486C15.0797 5.79862 16 6.71444 16 7.84008V10.0363C16 11.162 15.0797 12.0778 13.9486 12.0778H9.19391C9.07341 13.1575 8.15081 14 7.03416 14H6.79276V12.0778H6.34056C3.91831 12.0778 1.94769 10.1167 1.94769 7.70624V3.70022H1.885C0.845593 3.70022 0 2.85874 0 1.82442V0H1.51138ZM9.40626 10.2964H10.3438V9.36343H9.40626V10.2964Z"
                fill="var(--blackjack-secondary)"
                fill-opacity="0.7"
              />
            </svg>
            <span class="whale-title">WHALE TABLES</span>
            <div class="title-limits">
              (
              <img src="@/assets/img/icons/coin.svg" alt="icon" />
              <div class="limits-value">
                <span>50</span>.00 <span>- 1,000</span>.00
              </div>
              )
            </div>
          </div>
        </div>
        <div class="whale-content">
          <transition name="fade" mode="out-in">
            <div
              v-if="socketBlackjack.connected === false"
              class="content-loading"
              key="loading"
            >
              Loading...
            </div>
            <div v-else class="content-list" key="data">
              <BlackjackTablesElement
                v-for="table of blackjackGetTables.whale"
                v-bind:key="table.table"
                v-bind:table="table"
              />
            </div>
          </transition>
        </div>
      </div>
    </div>
    <!--<Bets />-->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Bets from "@/components/bets/Bets";
import IconTables from "@/components/icons/IconTables";
import IconUserGradient from "@/components/icons/IconUserGradient";
import IconWhaleGradient from "@/components/icons/IconWhaleGradient";
import BlackjackTablesElement from "@/components/blackjack/BlackjackTablesElement";

export default {
  name: "BlackjackOverview",
  components: {
    Bets,
    IconTables,
    IconUserGradient,
    IconWhaleGradient,
    BlackjackTablesElement,
  },
  computed: {
    ...mapGetters(["socketBlackjack", "blackjackTables"]),
    blackjackGetTables() {
      let tables = { standard: [], whale: [] };

      for (const table of this.blackjackTables) {
        if (table.game.type === "standard") {
          tables.standard.push(table);
        } else {
          tables.whale.push(table);
        }
      }

      return tables;
    },
  },
};
</script>

<style scoped>
.blackjack-overview {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  padding: 120px min(3vw, 60px) 0 min(3vw, 60px);
}

.blackjack-overview-background {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 80px min(5%, 60px) 40px min(5%, 60px);
  gap: 24px;
  border-radius: 64px 64px 0 0;
  border: 18px solid #281f1e;
  background-image: url("../../assets/img/blackjack/blackjack-lobby-table.png");
  background-size: cover;
  background-position: center;
  filter: drop-shadow(0px 0px 30px rgba(252, 163, 17, 0.48));
}

.blackjack-overview-background .sign {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translate(-50%, -50%);
  height: min(40vw, 175px);
}

.blackjack-overview .overview-standard,
.blackjack-overview .overview-whale {
  width: 1300px;
}

.blackjack-overview .standard-header,
.blackjack-overview .whale-header {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--blackjack-primary);
  padding: 6px 24px;
  border-radius: 8px;
  border: 3px solid var(--blackjack-secondary);
}

.blackjack-overview .whale-header .header-title .whale-title {
  color: var(--blackjack-secondary);
}

.blackjack-overview .header-title {
  display: flex;
  align-items: center;
}

.blackjack-overview .header-title svg {
  margin-right: 12px;
}

.blackjack-overview .header-title span {
  font-size: 14px;
  font-weight: 900;
  color: white;
}

.blackjack-overview .title-limits {
  display: flex;
  align-items: center;
  margin-left: 15px;
  /* font-family: 'Rubik', sans-serif; */
  font-weight: 400;
  font-size: 28px;
  color: #c1c1c1;
}

.blackjack-overview .limits-value {
  /* font-family: 'Open Sans', sans-serif; */
  font-size: 12px;
  font-weight: 600;
  color: #c1c1c1;
}

.blackjack-overview .limits-value span {
  font-size: 16px;
  font-weight: 800;
  color: #ffffff;
}

.blackjack-overview .title-limits img {
  width: 28px;
  margin-right: 2px;
}

.blackjack-overview .standard-header a.link-all {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #607c92;
}

.blackjack-overview .standard-header a.link-all svg {
  margin-right: 10px;
  display: flex;
  align-items: center;
  fill: #607c92;
}

.blackjack-overview .standard-content,
.blackjack-overview .whale-content {
  margin: 25px -25px 0 -25px;
  background-color: rgba(0, 0, 0, 0.6);
  padding: min(5%, 50px);
  border-radius: min(2%, 16px);
}

.blackjack-overview .content-loading {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 16px;
  font-weight: 600;
}

.blackjack-overview .content-loading.fade-leave-active {
  transition: opacity 0.5s;
}

.blackjack-overview .content-loading.fade-leave-to {
  opacity: 0;
}

.blackjack-overview .loading-placeholder {
  width: calc(20% - 8px);
  height: 150px;
  position: relative;
  margin-top: 10px;
  margin-right: 10px;
  overflow: hidden;
  background: radial-gradient(285% 150% at 50% 50%, #001323 0%, #000e1a 100%);
  clip-path: polygon(
    25px 0,
    calc(100% - 25px) 0,
    100% 25%,
    100% 75%,
    calc(100% - 25px) 100%,
    25px 100%,
    0 75%,
    0 25%
  );
}

.blackjack-overview .loading-placeholder:nth-child(1),
.blackjack-overview .loading-placeholder:nth-child(2),
.blackjack-overview .loading-placeholder:nth-child(3),
.blackjack-overview .loading-placeholder:nth-child(4),
.blackjack-overview .loading-placeholder:nth-child(5) {
  margin-top: 0;
}

.blackjack-overview .loading-placeholder:nth-child(5n) {
  margin-right: 0;
}

.blackjack-overview .loading-placeholder::after {
  width: 100%;
  height: 100%;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  animation-name: loading_animation;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
  background: linear-gradient(
    to right,
    #ffffff00 0%,
    rgba(255, 255, 255, 0.1) 50%,
    #ffffff00 100%
  );
}

.blackjack-overview .content-list {
  width: 100%;
  height: 400px;
  overflow: scroll;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: min(3%, 24px);
}

.blackjack-overview .content-list.fade-enter-active {
  transition: opacity 0.5s;
}

.blackjack-overview .content-list.fade-enter-from {
  opacity: 0;
}

@keyframes loading_animation {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
}

@media only screen and (max-width: 1700px) {
  .blackjack-overview .overview-standard,
  .blackjack-overview .overview-whale {
    width: 100%;
  }

  .blackjack-overview .standard-content,
  .blackjack-overview .whale-content {
    margin: 25px 0 0 0;
  }
}

@media only screen and (max-width: 1050px) {
  .blackjack-overview .loading-placeholder {
    width: calc(25% - 7.5px);
  }

  .blackjack-overview .loading-placeholder:nth-child(5) {
    margin-top: 10px;
  }

  .blackjack-overview .loading-placeholder:nth-child(5n) {
    margin-right: 10px;
  }

  .blackjack-overview .loading-placeholder:nth-child(4n) {
    margin-right: 0;
  }
}

@media only screen and (max-width: 900px) {
  .blackjack-overview .loading-placeholder {
    width: calc(33.33% - 6.66px);
  }

  .blackjack-overview .loading-placeholder:nth-child(4) {
    margin-top: 10px;
  }

  .blackjack-overview .loading-placeholder:nth-child(4n) {
    margin-right: 10px;
  }

  .blackjack-overview .loading-placeholder:nth-child(3n) {
    margin-right: 0;
  }
}

@media only screen and (max-width: 850px) {
  .blackjack-overview .blackjack-overview-background {
    flex-direction: column;
  }

  .blackjack-overview .standard-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .blackjack-overview .standard-header a.link-all {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 700px) {
  .blackjack-overview .loading-placeholder {
    width: calc(50% - 5px);
  }

  .blackjack-overview .loading-placeholder:nth-child(3) {
    margin-top: 10px;
  }

  .blackjack-overview .loading-placeholder:nth-child(3n) {
    margin-right: 10px;
  }

  .blackjack-overview .loading-placeholder:nth-child(2n) {
    margin-right: 0;
  }
}
</style>
